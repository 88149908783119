import axios from "axios";
import apiEndPoints from "./PMApiEndPoints";
import { store } from "../PMRedux/PMStore";
import actions from "../PMRedux/PMActions";

const defaultOptions = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

const axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(
  async (config) => {
    store.dispatch({
      type: actions.SHOW_LOADER,
    });
    const preLoginAPIs = [
      apiEndPoints.PHYSICIAN_LIST,
      apiEndPoints.SIGNIN,
      apiEndPoints.SIGNUP,
      apiEndPoints.REGISTER_BABY,
    ];
    config.headers.Access = 1;
    if (preLoginAPIs.indexOf(config.url) > -1) {
    } else {
      if (typeof sessionStorage !== "undefined") {
        const accessToken = sessionStorage.getItem("accessToken");
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch({
      type: actions.HIDE_LOADER,
    });
    return response;
  },
  (error) => {
    store.dispatch({
      type: actions.HIDE_LOADER,
    });
    if (error.response && error.response.status === 401) {
      sessionStorage.clear();
      store.dispatch({
        type: actions.REDIRECT_USER,
      });
      store.dispatch({
        type: actions.RESET_LOGIN,
      });
    } else if (error.response && error.response.status === 404) {
      // redirect to 404 page
    } else if (error.response && error.response.status === 500) {
      // handle server error
    }
    return Promise.reject(error.response);
  }
);

export default axiosInstance;
