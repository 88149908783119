import { combineReducers } from "redux";
import { appReducer } from "./PMAppActionReducer";
import { loginReducer } from "./PMLoginActionReducer";
import { dashboardReducer } from "./PMDashboardActionReducer";
import { configurationReducer } from "./PMConfigurationActionReducer";
import { userActivityReducer } from "./PMUserActivityActionReducer";
import { chatReducer } from "./PMChatActionReducer";
import { securityCodeReducer } from "./PMSecurityCodeActionReducer";

const rootReducer = combineReducers({
  appReducer,
  loginReducer,
  dashboardReducer,
  configurationReducer,
  userActivityReducer,
  chatReducer,
  securityCodeReducer,
});

export default rootReducer;
