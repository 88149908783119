import actions from "./PMActions";
import axiosInstance from "../PMCommon/PMApiHandler";
import apiEndPoints from "../PMCommon/PMApiEndPoints";

const initialState = {
  isLoading: false,
  isEmailVerified: false,
  isDisplayUploadDialog: false,
  isShowNotification: false,
  notificationVariant: "success",
  notificationMessage: "",
  isUserDataUpdated: false,
  isUserDataError: false,
  isSignoutSuccess: false,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REDIRECT_USER:
      return {
        ...state,
        accessToken: "",
        isLoading: false,
        isEmailVerified: false,
        isDisplayUploadDialog: false,
        isShowNotification: false,
        notificationVariant: "success",
        notificationMessage: "",
        isUserDataUpdated: false,
        isUserDataError: false,
        userData: null,
        userDataError: null,
        isSignoutSuccess: false,
      };
    case actions.SHOW_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.HIDE_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    case actions.EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        isEmailVerified: true,
      };
    case actions.EMAIL_VERIFICATION_ERROR:
      return {
        ...state,
        isEmailVerified: false,
        emailVerifyError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.DISPLAY_UPLOAD_DIALOG:
      return {
        ...state,
        isDisplayUploadDialog: action.data,
      };
    case actions.DISPLAY_NOTIFICATION:
      return {
        ...state,
        isShowNotification: action.data.isDisplay || false,
        notificationVariant: action.data.notificationVariant,
        notificationMessage: action.data.notificationMessage,
      };
    case actions.USER_DETAILS_SUCCESS:
      return {
        ...state,
        isUserDataUpdated: true,
        isUserDataError: false,
        userData:
          action.data && action.data.data ? action.data.data.results : null,
      };
    case actions.USER_DETAILS_ERROR:
      return {
        ...state,
        isUserDataUpdated: false,
        isUserDataError: true,
        userDataError: action.data && action.data.data ? action.data.data : {},
      };
    case actions.FORGOT_PASSWORD_RESET:
      return {
        ...state,
        isForgotPasswordSuccess: false,
        forgotPasswordError: null,
      };
    case actions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotPasswordSuccess: true,
        forgotPasswordError: null,
      };
    case actions.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isForgotPasswordSuccess: false,
        forgotPasswordError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.RESET_PASSWORD_RESET:
      return {
        ...state,
        isResetPasswordSuccess: false,
        resetPasswordError: null,
        resetPasswordData: null,
      };
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordSuccess: true,
        resetPasswordError: null,
        resetPasswordData:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.RESET_PASSWORD_ERROR:
      return {
        ...state,
        isResetPasswordSuccess: false,
        resetPasswordError:
          action.data && action.data.data ? action.data.data : null,
        resetPasswordData: null,
      };
    case actions.SIGNOUT_SUCCESS:
      return {
        ...state,
        isSignoutSuccess: true,
      };
    case actions.SIGNOUT_ERROR:
      return {
        ...state,
        isSignoutSuccess: false,
      };
    default:
      return state;
  }
};

const displayUploadDialog = (isDisplay) => {
  return (dispatch) => {
    dispatch({
      type: actions.DISPLAY_UPLOAD_DIALOG,
      data: isDisplay,
    });
  };
};

const signOut = () => {
  return (dispatch) => {
    dispatch({
      type: actions.REDIRECT_USER,
    });
  };
};

const verifyEmail = (requestUrl) => {
  return (dispatch) => {
    axiosInstance
      .get(apiEndPoints.VERIFY_EMAIL + requestUrl)
      .then((data) => {
        dispatch({
          type: actions.EMAIL_VERIFICATION_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.EMAIL_VERIFICATION_ERROR,
          data: error,
        });
      });
  };
};

const setDisplayNotification = (
  isDisplay,
  notificationVariant,
  notificationMessage
) => {
  return (dispatch) => {
    dispatch({
      type: actions.DISPLAY_NOTIFICATION,
      data: { isDisplay, notificationVariant, notificationMessage },
    });
  };
};

const resetForgotPassword = () => {
  return (dispatch) => {
    dispatch({
      type: actions.FORGOT_PASSWORD_RESET,
    });
  };
};

const handleForgotPassword = (dataObj) => {
  return (dispatch) => {
    axiosInstance
      .post(apiEndPoints.FORGOT_PASSWORD, dataObj)
      .then((data) => {
        dispatch({
          type: actions.FORGOT_PASSWORD_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.FORGOT_PASSWORD_ERROR,
          data: error,
        });
      });
  };
};

const handleResetPassword = (dataObj) => {
  return (dispatch) => {
    axiosInstance
      .post(apiEndPoints.RESET_PASSWORD, dataObj)
      .then((data) => {
        dispatch({
          type: actions.RESET_PASSWORD_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.RESET_PASSWORD_ERROR,
          data: error,
        });
      });
  };
};

const resetResetPassword = () => {
  return (dispatch) => {
    dispatch({
      type: actions.RESET_PASSWORD_RESET,
    });
  };
};

const getUserDetails = () => {
  return (dispatch) => {
    axiosInstance
      .get(apiEndPoints.GET_USER_DETAILS)
      .then((data) => {
        dispatch({
          type: actions.USER_DETAILS_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.USER_DETAILS_ERROR,
          data: error,
        });
      });
  };
};

const handleSignoutCall = () => {
  return (dispatch) => {
    axiosInstance
      .get(apiEndPoints.SIGN_OUT)
      .then((data) => {
        dispatch({
          type: actions.SIGNOUT_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.SIGNOUT_ERROR,
          data: error,
        });
      });
  };
};

// list all actions here
export const appActions = {
  verifyEmail,
  displayUploadDialog,
  getUserDetails,
  setDisplayNotification,
  signOut,
  handleForgotPassword,
  resetForgotPassword,
  handleResetPassword,
  resetResetPassword,
  handleSignoutCall,
};
