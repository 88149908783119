const fieldName = {
  FIRSTNAME: "first_name",
  LASTNAME: "last_name",
  EMAIL: "email",
  PASSWORD: "password",
  CONFIRM_PASSWORD: "confirm_password",
  MOBILE_NUMBER: "mobile_number",
  DOCTOR_NAME: "doctor_name",
  CHILD_NAME: "child_name",
  CHILD_DOB: "child_dob",
  RELATIONSHIP: "relationship",
  PHYSICIAN_ID: "physician_identifier",
  SECURITY_CODE: "security_code",
  PRACTICE_NAME: "practice_name",
  PRACTICE_POC_NAME: "practice_poc_name",
  BASE_URL: "base_url",
  PRACTICE_TYPE: "practice_type",
  PHONE: "phone",

};

export default fieldName;
