import axiosInstance from "../PMCommon/PMApiHandler";
import apiEndPoints from "../PMCommon/PMApiEndPoints";
import actions from "./PMActions";

const initialState = {
  newMessageCount: 0,
  unseenMessageArray: [],
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_NEW_MESSAGE_COUNT:
      return {
        ...state,
        newMessageCount: action.data || null,
      };
    default:
      return state;
  }
};

const setNewMessageCount = (data) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_NEW_MESSAGE_COUNT,
      data,
    });
  };
};

const emailUnseenMessages = (dataObj) => {
  return (dispatch) => {
    axiosInstance
      .post(apiEndPoints.EMAIL_CHAT_MESSAGES, dataObj)
      .then((data) => {
        dispatch({
          type: actions.EMAIL_CHAT_MESSAGES_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.EMAIL_CHAT_MESSAGES_ERROR,
          data: error,
        });
      });
  };
};

export const chatActions = {
  setNewMessageCount,
  emailUnseenMessages,
};
