import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PMPhysicianHeader from "./PMPhysicianHeader";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";

import Notification from "../PMNotification/PMNotification";

const roleValues = {
  parent: "parent",
  physician: "physician",
};

const getUserRole = () => {
  let role = sessionStorage.getItem("userRole") || "";

  return roleValues[role];
};

const Header = (props) => {
  const pathValue = props.location ? props.location.pathname || "" : "";
  const pathArr = pathValue.split("/");
  const pathName = pathArr.length > 0 ? pathArr[1] : "";
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => {
    return state.appReducer.isLoading || false;
  });
  const showNotification = useSelector((state) => {
    return state.appReducer.isShowNotification || false;
  });
  const notificationVariant = useSelector((state) => {
    return state.appReducer.notificationVariant || "success";
  });
  const notificationMessage = useSelector((state) => {
    return state.appReducer.notificationMessage || "";
  });
  const isUserDataUpdated = useSelector((state) => {
    return state.appReducer.isUserDataUpdated || "";
  });
  const isUserDataError = useSelector((state) => {
    return state.appReducer.isUserDataError || false;
  });
  const userData = useSelector((state) => {
    return state.appReducer.userData || null;
  });
  const handleHideNotification = () => {
    dispatch(appActions.setDisplayNotification(false));
  };

  useEffect(() => {
    if (!userData) {
      dispatch(appActions.getUserDetails());
    }
  }, []);

  useEffect(() => {
    if (isUserDataUpdated && userData) {
      const babyId =
        userData.babyList && userData.babyList[0]
          ? userData.babyList[0].identifier
          : "";
      sessionStorage.setItem("userStatus", userData.status);
      sessionStorage.setItem("userName", userData.first_name);
      sessionStorage.setItem("userRole", userData.type);
    }
    // if (isUserDataError) {
    //   dispatch(
    //     appActions.setDisplayNotification(
    //       true,
    //       "error",
    //       "User data not found. Please try reloading the page"
    //     )
    //   );
    // }
  }, [isUserDataUpdated, isUserDataError]);

  const userRole = getUserRole()
    ? getUserRole()
    : userData && userData.type
    ? userData.type
    : "";
  return (
    <>
      <Notification
        show={showNotification}
        handleClose={handleHideNotification}
        additionalClass="notification-wrapper"
        variant={notificationVariant}
        message={notificationMessage}
      />
      <PMPhysicianHeader activePath={pathName} {...props} />
    </>
  );
};

export default Header;
