import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import "./PMTextbox.scss";

const Textbox = ({
  name = "",
  placeholder = "",
  onChange = () => {},
  value = "",
  onBlur = () => {},
  additionalClass = "",
  error = "",
  label = "",
  icon = null,
  multiline = false,
  extendedInputProps = null,
}) => {
  return (
    <>
      <TextField
        name={name}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        className={`text-field ${additionalClass}`}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={
          extendedInputProps
            ? { ...extendedInputProps }
            : {
                startAdornment: icon ? (
                  <InputAdornment position="start">
                    <img src={icon} alt="" className="textbox_icon" />
                  </InputAdornment>
                ) : null,
              }
        }
        variant="outlined"
        label={label}
        multiline={!!multiline}
        rows={10}
        error={!!error}
        helperText={error}
      />
    </>
  );
};

export default Textbox;

Textbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  additionalClass: PropTypes.string,
  multiline: PropTypes.bool,
};
