import React from "react";
import PropTypes from "prop-types";
import MatCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./PMCheckbox.scss";

const Checkbox = ({
  name = "",
  onChange = () => {},
  value = "",
  label = "",
  additionalClass = "",
  error = "",
}) => {
  return (
    <>
      <FormControlLabel
        control={
          <MatCheckbox
            name={name}
            color="default"
            className={additionalClass}
            onChange={onChange}
            checked={value}
            icon={<RadioButtonUncheckedOutlinedIcon />}
            checkedIcon={<CheckCircleIcon />}
          />
        }
        label={label}
        labelPlacement="end"
        className="checkboxLabel"
      />
      {error && <div className="error">{error}</div>}
    </>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  additionalClass: PropTypes.string,
};
