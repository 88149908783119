import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import getLanguageModule from "../../PMLocalisation/index";
import moment from "moment";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";
import Textbox from "../../PMComponents/PMTextbox/PMTextbox";
import { InputAdornment } from "@material-ui/core";
import { userActivityActions } from "../../PMShared/PMRedux/PMUserActivityActionReducer";

const headCells = [
  {
    id: "email",
    align: "left",
    label: "Email",
    disablePadding: true,
  },
  {
    id: "activityDate",
    align: "center",
    label: "Date/Time",
  },
  {
    id: "ipAddress",
    align: "center",
    label: "IP Address",
  },
  {
    id: "userAgent",
    align: "center",
    label: "User Agent",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : null}
          >
            <Typography color="inherit" variant="subtitle1" component="div">
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    setSearchVal,
    searchVal,
    isLoading,
    setPage,
    fetchUserList,
    rowsPerPage,
  } = props;

  return (
    <>
      <Toolbar className="toolbar">
        <Typography variant="h6" id="tableTitle" component="div">
          Unsuccessful Signing Attempts
        </Typography>
        <Textbox
          name="search"
          placeholder="Search"
          value={searchVal}
          onChange={(e) => {
            if (e && e.target) {
              const value = e.target.value ? e.target.value : "";

              setSearchVal(value);
              if (!value || (value && value.trim().length > 2 && !isLoading)) {
                fetchUserList(0, value, rowsPerPage);
                setPage(0);
              }
            }
          }}
          additionalClass="searchResult"
          extendedInputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
    </>
  );
};

const SigningAttempts = () => {
  const languageData = getLanguageModule("dashboard");

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => {
    return state.userActivityReducer.isUserActivityLoading;
  });
  const rows = useSelector((state) => {
    return state.userActivityReducer.userActivityList
      ? state.userActivityReducer.userActivityList.data || []
      : [];
  });
  const userData = useSelector((state) => {
    return state.userActivityReducer.userActivityList || null;
  });
  const isUserActivitySuccess = useSelector((state) => {
    return state.userActivityReducer.isUserActivitySuccess;
  });
  const userActivityError = useSelector((state) => {
    return state.userActivityReducer.userActivityError || null;
  });

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("childName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchVal, setSearchVal] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    return array;
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, email) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const fetchUserList = (pageNo, searchTerm, perPage) => {
    const requestObject = {
      per_page: perPage,
      search: searchTerm ? searchTerm.trim() : "",
    };
    const queryParam = `page=${pageNo + 1}`;
    dispatch(
      userActivityActions.getUserLoginActivity(requestObject, queryParam)
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchUserList(newPage, searchVal, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = parseInt(event.target.value, 10);
    setRowsPerPage(perPage);
    setPage(0);
    fetchUserList(0, searchVal, perPage);
  };

  const isSelected = (email) => selected.indexOf(email) !== -1;
  const emptyRows = rowsPerPage - rows.length;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  useEffect(() => {
    fetchUserList(page, searchVal, rowsPerPage);
  }, []);

  const prevError = usePrevious(userActivityError);
  useEffect(() => {
    if (userActivityError && prevError !== userActivityError) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "error",
          userActivityError.message || "Some error occured"
        )
      );
      dispatch(userActivityActions.resetUserActivity());
    }
  }, [userActivityError]);

  return (
    <div className="content-wrapper">
      <div className="admin-dashboard">
        {isLoading && (
          <div className="backdrop-loader custom-backdrop">
            <CircularProgress color="inherit" />
          </div>
        )}
        <div className="admin-container">
          <EnhancedTableToolbar
            numSelected={selected.length}
            setSearchVal={setSearchVal}
            searchVal={searchVal}
            isLoading={isLoading}
            setPage={setPage}
            fetchUserList={fetchUserList}
            rowsPerPage={rowsPerPage}
          />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                // orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows &&
                  rows.length > 0 &&
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.email);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover tabIndex={-1} key={row.email + index}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.email}
                        </TableCell>
                        <TableCell align="center">
                          {row.attempt_datetime
                            ? moment(row.attempt_datetime).format(
                                "MMM DD YYYY hh:mm:ss"
                              )
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          {row.ip_address || ""}
                        </TableCell>
                        <TableCell align="center">
                          {row.user_agent || ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={10} className="empty-row">
                      {rows && rows.length < 1 && <span>No Result Found</span>}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={userData ? userData.total : rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SigningAttempts;
