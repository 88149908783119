const en = {
  login: {
    WELCOME: "Welcome To",
    USERNAME: "Username",
    PASSWORD: "Password",
    REMEMBER_ME: "Remember me",
    DONT_HAVE_ACCOUNT: "Don't have an account?",
    RACE_OPTION1: "American Indian or Alaska Native",
    RACE_OPTION2: "Asian",
    RACE_OPTION3: "Black or African American",
    RACE_OPTION4: "Native Hawaiian or other Pacific Islander",
    RACE_OPTION5: "White",
    CHILD_GENDER_TEXT: "Child's Gender",
    CHILD_GENDER_SUBTITLE: "Different Genders need different health approaches",
    PRE_TERM_WEEK: "Pre-term Weeks",
    PRE_TERM_WEEK_SUBTITLE:
      "How early was your child born (compared to 40 week birth)",
    RACE_TEXT: "Race",
    RACE_SUBTITLE: "Please choose from below options",
    PREVIOUS_CONDITION: "Previous conditions",
    PREVIOUS_CONDITION_SUBTITLE:
      "Example diagnosed plagiocephaly, craniosynostosis, torticollis, NICU Stay",
    ADD_COMMENTS_PLACEHOLDER: "Add your comments here",
    ACCEPT_DISCLAIMER: "Please accept the terms of Disclaimer",
    PASSWORD_EXPIRED: "Your password has expired, please ",
    RESET_PASSWORD: " to reset your password.",
    RESET_PASSWORD_MESSAGE: "Your password has expired.",
  },
  disclaimer: {
    disclaimerPoint1:
      "1: All your information will be kept completely confidential. The studies and reports that are made public as part of publications or promotional materials will never divulge any information that will make it possible to identify you or your child or patients. Moreover, all of the data will be kept in a secure location; and only affiliated people will have access to them. By posting, uploading, inputting, providing or submitting materials including photos and videos to our website (Collectively called Submission), you are granting PediaMetrix, its affiliated companies, and necessary sub-licensees permission to use your Submission in connection with the operation of their businesses including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission as long as you or your child or your patients are not easily identifiable.",
    disclaimerPoint2:
      "2: Please be aware that we do not intend to make a diagnosis, or propose a treatment. In addition, our measurements may be different from measurements done by other companies such as helmet clinics as we use our own proprietary methods (numbers provided by two different clinics do not necessarily match either). What is important is the consistency of measurements and quantitative progress compared to the starting point.",
    disclaimerPoint3:
      "3: The results and consultation provided to you as part of this service is for your own information only. It does not replace doctor's advice. We recommend you consult your child or patient's clinician for any concerns.",
  },
  forgotPassword: {
    WELCOME: "Welcome To",
    EMAIL: "Email",
    MOVE_TO_LOGIN: "Move to",
  },
  resetPassword: {
    WELCOME: "Welcome To",
    EMAIL: "Email",
    MOVE_TO_LOGIN: "Move to",
  },
  signup: {
    HEADER: "Sign Up",
    HEADER_SUBTITLE: "Please enter below information to get in",
    TEXT_IAM: "I'm a...",
    TEXT_PHYSICIAN: "Physician",
    TEXT_PARENT: "Parent",
    TEXT_OTHER: "Other",
    ERROR_ACCEPT_TERMS:
      "Please accept the terms, conditions and privacy policy",
    CHILD_HEADER: "Child Information",
    CHILD_SUBTITLE: "Let's get you started",
  },
  dashboard: {
    ACCEPT_DISCLAIMER: "Please accept the terms of Disclaimer",
    WELCOME_USER:
      "Please upload pictures of your infant's head every month. Make sure to ask your physician about age-appropriate at-home exercises every month. They not only prevent head deformities but also promote your child's motor and sensor development.",
    WELCOME_PHYSICIAN:
      "According to AAP guidelines, prevention is the key for management of head deformity related conditions. We recommend every infants (0-7 months) to be scanned by SoftSpot every month.",
  },
  emailVerification: {
    WELCOME: "Welcome To",
  },
};

export default en;
