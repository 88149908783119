import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import { useSelector, useDispatch } from "react-redux";
import { MenuItem, Menu } from "@material-ui/core";

import "./PMHeader.scss";
import {
  IMG_PMLOGO,
  IMG_PM_BELL,
  IMG_PM_DOWN_ARROW_GREY,
} from "../../PMConstants/PMImageConstants";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";
import { loginActions } from "../../PMShared/PMRedux/PMLoginActionReducer";
import { dashboardActions } from "../../PMShared/PMRedux/PMDashboardActionReducer";

function PMPhysicianHeader(props) {
  const dispatch = useDispatch();
  const menuRef = useRef(null);
  const profileOptions = ["Sign Out"];

  const [activeLink, setActiveLink] = useState(
    props && props.activePath ? props.activePath : "dashboard"
  );
  const [showProfileOptions, setShowProfileOptions] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const userData = useSelector((state) => {
    return state.appReducer.userData || null;
  });
  const isSignoutSuccess = useSelector((state) => {
    return state.appReducer.isSignoutSuccess || false;
  });

  const handleShowProfileOptions = (event) => {
    setAnchorEl(menuRef ? menuRef.current : event.target);
    setShowProfileOptions(!showProfileOptions);
  };
  const handleCloseProfileOptions = () => {
    setAnchorEl(null);
    setShowProfileOptions(false);
  };

  const userName = sessionStorage.getItem("userName")
    ? sessionStorage.getItem("userName")
    : userData && userData.first_name
    ? userData.first_name
    : "";
  const userInitial = userName[0] || "";
  const userImagePath = "";

  const handleSignout = () => {
    dispatch(appActions.handleSignoutCall());
  };

  useEffect(() => {
    if (isSignoutSuccess) {
      dispatch(appActions.signOut());
      dispatch(loginActions.resetLogin());
      dispatch(dashboardActions.resetDashboard());

      sessionStorage.clear();
      props.history.push("/");
      setShowProfileOptions(false);
    }
  }, [isSignoutSuccess]);

  return (
    <>
      <Grid item xs={12} className="header-container">
        <div className="header-wrapper">
          <div>
            <img className="logo" src={IMG_PMLOGO} title="" alt="" />
          </div>
          <div>
            <div className="header-nav hide">
              <Link
                to="/users-parents"
                className={`${activeLink === "dashboard" ? "active" : ""}`}
                onClick={() => setActiveLink("dashboard")}
              >
                Home
              </Link>
              <Link
                to="/workflow"
                className={`${activeLink === "workflow" ? "active" : ""}`}
                onClick={() => setActiveLink("workflow")}
              >
                Workflow
              </Link>
              <Link
                to="/results"
                className={`${activeLink === "results" ? "active" : ""}`}
                onClick={() => setActiveLink("results")}
              >
                Results
              </Link>
              <Link
                to="/my-patients"
                className={`${activeLink === "my-patients" ? "active" : ""}`}
                onClick={() => setActiveLink("my-patients")}
              >
                My Patients
              </Link>
              <Link
                to="/help-center"
                className={`${activeLink === "help-center" ? "active" : ""}`}
                onClick={() => setActiveLink("help-center")}
              >
                Help Center
              </Link>
            </div>
            <div className="header-notification hide">
              <Badge badgeContent={4} color="primary">
                <img src={IMG_PM_BELL} alt="" />
              </Badge>
            </div>
            <div
              className="profile cursor_pointer"
              onClick={() => handleSignout}
            >
              {userImagePath && (
                <Avatar alt="" src={userImagePath} className="userAvatar" />
              )}
              {!userImagePath && (
                <Avatar alt="" className="userAvatar">
                  {userInitial}
                </Avatar>
              )}
              {/* <div className="profile-img">S</div> */}
              <img
                src={IMG_PM_DOWN_ARROW_GREY}
                alt=""
                className="profile-arrow"
                onClick={handleShowProfileOptions}
              />
              <div className="profile-ref" ref={menuRef}></div>
              <Menu
                keepMounted
                open={showProfileOptions}
                onClose={handleCloseProfileOptions}
                anchorEl={anchorEl}
              >
                <MenuItem onClick={handleSignout}>{profileOptions[0]}</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
}

export default PMPhysicianHeader;
