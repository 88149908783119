import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Textbox from "../../PMComponents/PMTextbox/PMTextbox";
import Button from "../../PMComponents/PMButton/PMButton";
import {
  validateField,
  validateAllFields,
} from "../../PMShared/PMCommon/PMUtility";
import {
  IMG_PMLOGIN,
  IMG_PMUSER,
  IMG_PMLOGO,
} from "../../PMConstants/PMImageConstants";
import Link from "../../PMComponents/PMLink/PMLink";
import getLanguageModule from "../../PMLocalisation/index";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";
import Notification from "../../PMComponents/PMNotification/PMNotification";
import { securityCodeActions } from "../../PMShared/PMRedux/PMSecurityCodeActionReducer";

export const VerifySecurityCode = (props) => {
  const languageData = getLanguageModule("forgotPassword");
  const dispatch = useDispatch();

  const [user, setUser] = useState({ security_code: "" });
  const [errors, setErrors] = useState({ security_code: "" });
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const isLoading = useSelector((state) => {
    return (
      state.securityCodeReducer.isSecurityCodeVerificationLoading ||
      state.securityCodeReducer.isResendVerificationCodeLoading
    );
  });
  const isSecurityCodeVerificationSuccess = useSelector((state) => {
    return state.securityCodeReducer.isSecurityCodeVerificationSuccess;
  });
  const securityCodeVerificationError = useSelector((state) => {
    return state.securityCodeReducer.securityCodeVerificationError
      ? state.securityCodeReducer.securityCodeVerificationError.message || ""
      : "";
  });
  const isResendVerificationCodeSuccess = useSelector((state) => {
    return state.securityCodeReducer.isResendVerificationCodeSuccess;
  });
  const resendVerificationCodeError = useSelector((state) => {
    return state.securityCodeReducer.resendVerificationCodeError
      ? state.securityCodeReducer.resendVerificationCodeError.message || ""
      : "";
  });
  const securityCodeSuccessMessage = useSelector((state) => {
    return state.securityCodeReducer.securityCodeSuccessMessage
      ? state.securityCodeReducer.securityCodeSuccessMessage.message || ""
      : "";
  });
  const resendSecurityCodeSuccessMessage = useSelector((state) => {
    return state.securityCodeReducer.resendSecurityCodeSuccessMessage
      ? state.securityCodeReducer.resendSecurityCodeSuccessMessage.message || ""
      : "";
  });
  const handleHideNotification = () => {
    setShowNotification(false);
    dispatch(appActions.resetForgotPassword());
  };

  const onHandleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let userData = { ...user };
    userData[name] = value;
    setUser(userData);
    // setErrors(validateField(name, errors, userData));
  };

  useEffect(() => {
    if (isSecurityCodeVerificationSuccess || isResendVerificationCodeSuccess) {
      setNotificationMessage(
        securityCodeSuccessMessage || resendSecurityCodeSuccessMessage
      );
      setShowNotification(true);
      setUser({ security_code: "" });
      dispatch(securityCodeActions.resetVerifySecurityCode());
    }
  }, [isSecurityCodeVerificationSuccess, isResendVerificationCodeSuccess]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (!isLoading) {
      dispatch(securityCodeActions.resetVerifySecurityCode());

      const requestObject = {
        email: "admin@admin.com",
        security_code: user.security_code.trim(),
      };
      const errorObj = validateAllFields(requestObject);
      setErrors(errorObj);
      if (!errorObj.security_code) {
        requestObject.code = user.security_code.trim();
        dispatch(securityCodeActions.verifySecurityCode(requestObject));
      }
    }
  };

  const resendVerificationCode = () => {
    if (!isLoading) {
      const requestObject = {
        email: "admin@admin.com",
      };

      dispatch(securityCodeActions.resetVerifySecurityCode());
      dispatch(securityCodeActions.resendSecurityCode(requestObject));
    }
  };

  return (
    <div className="page-container">
      <Notification
        show={showNotification}
        handleClose={handleHideNotification}
        additionalClass="notification-wrapper"
        variant="success"
        message={notificationMessage}
      />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div>
            <img className="header-logo" src={IMG_PMLOGO} title="" alt="" />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="text-center">
            <div className="login_img">
              <img src={IMG_PMLOGIN} title="" alt="" />
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="login-container">
            <div className="">
              <Grid item xs className="login_form">
                <div className="welcome_text">{languageData.WELCOME}</div>
                <div className="logo_text">SoftSpot</div>
              </Grid>
              <Grid item xs>
                <Backdrop open={isLoading} className="backdrop-loader">
                  <CircularProgress color="inherit" />
                </Backdrop>
                <form onSubmit={onSubmitHandler}>
                  <div className="text-center">
                    <div className="">
                      <Textbox
                        name="security_code"
                        placeholder="Security Code"
                        error={errors.security_code}
                        value={user.security_code}
                        onChange={(e) => onHandleChange(e)}
                        additionalClass="login"
                      />
                    </div>
                  </div>
                  <div className="text-center mt10">
                    <div className="security-code-container">
                      <Link
                        label="Resend Security Code"
                        href="javascript:void"
                        onClickHandler={resendVerificationCode}
                      />
                    </div>
                  </div>
                  <div className="login-button-container">
                    <Button
                      type="submit"
                      text="Verify Security Code"
                      name="login_submit"
                      onClick={onSubmitHandler}
                      additionalClass="signin"
                    />
                  </div>
                  <div className="error-container">
                    <div className="mb20">
                      {securityCodeVerificationError && (
                        <span className="error">
                          {securityCodeVerificationError}
                        </span>
                      )}
                    </div>
                    <div className="mb20">
                      {resendVerificationCodeError && (
                        <span className="error">
                          {resendVerificationCodeError}
                        </span>
                      )}
                    </div>
                    <div>
                      <span>{languageData.MOVE_TO_LOGIN} </span>
                      <Link label="Sign In" href="/" />
                    </div>
                  </div>
                </form>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default VerifySecurityCode;
