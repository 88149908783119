import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { usePubNub } from "pubnub-react";
import moment from "moment";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CreateIcon from "@material-ui/icons/Create";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CircularProgress from "@material-ui/core/CircularProgress";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Typography from "@material-ui/core/Typography";
import { Grid, Avatar, TextField } from "@material-ui/core";
import Textbox from "../../PMComponents/PMTextbox/PMTextbox";
import Button from "../../PMComponents/PMButton/PMButton";
import Notification from "../../PMComponents/PMNotification/PMNotification";
import Dialog from "../../PMComponents/PMDialog/PMDialog";
import { createLink } from "../../PMShared/PMCommon/PMUtility";
import { chatActions } from "../../PMShared/PMRedux/PMChatActionReducer";
import { templateArray, templateHtmlArray } from "./PMTemplateText";

const isLogin = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  return accessToken && true;
};

let timer = null;
const timeoutValue = 20000;

const SupportChat = (props) => {
  const dispatch = useDispatch();
  const pubnub = usePubNub();
  const globalChannel = "pediametrix-support";
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(-1);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([
    {
      id: "",
      author: "Support Agent",
      content: "Select a conversation from the user list to start chatting.",
      timetoken: +moment(),
      name: "Support Agent",
      email: "",
    },
  ]);

  const isUserListLoading = useSelector((state) => {
    return state.chatReducer.isUserListLoading;
  });

  /*const isLoading = useSelector((state) => {
    return (
      state.appReducer.isLoading && (isUserListLoading)
    );
  });*/

  const [isLoading, setIsLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [searchedUser, setSearchedUser] = useState(null);
  const [activeUsers, setActiveUsers] = useState([]);
  const [activeChannel, setActiveChannel] = useState("");
  const [presenceOccupancy, setPresenceOccupancy] = useState(0);
  const [presence, setPresence] = useState(true);
  const [presenceLastUpdated, setPresenceLastUpdated] = useState(0);
  const [history, setHistory] = useState(true);
  const [historyMax, setHistoryMax] = useState(100);
  const [maxMessagesInList, setMaxMessagesInList] = useState(200);
  const [selfName, setSelfName] = useState("Support Agent");
  const [selfAvatar, setSelfAvatar] = useState("");
  const [channel, setChannel] = useState(globalChannel);
  const [memberList, setMemberList] = useState([]);
  const [
    lastReceivedMessageTimetoken,
    setLastReceivedMessageTimetoken,
  ] = useState(null);
  const [lastSentMessageTimetoken, setLastSentMessageTimetoken] = useState(
    null
  );
  const [showNotification, setShowNotification] = useState(false);
  const [notificationVariant, setNotificationVariant] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [timetokenForLastActionSent, setTimetokenForLastActionSent] = useState(
    ""
  );
  const [scrollRefHeight, setScrollRefHeight] = useState(0);
  const [firstMessageTimestamp, setFirstMessageTimetamp] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const [unseenMessages, setUnseenMessages] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  const selfUUID = "support_agent";
  let pubNubListener = null;
  const chatContainerRef = useRef(null);

  const newMessageCountObj = useSelector((state) => {
    return state.chatReducer.newMessageCount || null;
  });

  const sendEmailForUnseenMessages = (userEmail, messagesArray) => {
    if (messagesArray && messagesArray.length > 0) {
      const requestObject = {
        admin: false,
        author: "Softspot Admin",
        email: userEmail,
        messages: messagesArray.map((obj) => {
          return {
            message: obj.content,
            timestamp: obj.timetoken,
          };
        }),
      };
      console.log(requestObject);

      dispatch(chatActions.emailUnseenMessages(requestObject));

      clearTimeout(timer);
      setUnseenMessages([]);
    }
  };

  const handleHideNotification = () => {
    setShowNotification(false);
  };

  const displayNotification = (type, message) => {
    setNotificationVariant(type);
    setNotificationMessage(message);
    setShowNotification(true);
  };

  const adjustScrollHeight = (messagesArray) => {
    const messageContainer = document.getElementById("chat-wrapper");
    if (messageContainer && messagesArray.length > 0) {
      const containerHeight = messageContainer.offsetHeight;
      const messageArray = document.getElementsByClassName("chat-message");
      const messageHeight =
        messageArray && messageArray.length > 0
          ? messagesArray.length * (messageArray[0].offsetHeight + 20)
          : 0;
      const newScrollRefHeight = containerHeight - messageHeight;
      setScrollRefHeight(newScrollRefHeight);
    }
  };

  const sendMessageReceipt = (
    channelName,
    messageStatus,
    messageTimetoken,
    successCallback,
    errorCallback
  ) => {
    pubnub.addMessageAction(
      {
        channel: channelName,
        messageTimetoken,
        action: {
          type: "receipt",
          value: messageStatus,
        },
      },
      (status, response) => {
        if (response && response.status && response.status === 200) {
          if (successCallback) {
            successCallback();
          }
        } else if (errorCallback) {
          errorCallback();
        }
      }
    );
  };

  const setNewMessageCount = () => {
    if (activeUsers && activeUsers.length > 0) {
      const userArray = [...activeUsers];
      const userMessages = {};
      let totalMessageCount = 0;

      for (let index = 0; index < userArray.length; index++) {
        const element = userArray[index];

        userMessages[element.uuid] = element.newMessageCount;
        totalMessageCount += element.newMessageCount;
      }
      userMessages["totalMessageCount"] = totalMessageCount;
      dispatch(chatActions.setNewMessageCount(userMessages));
    }
  };

  const removeSelfUserFromUserList = (newActiveUsers) => {
    const index = newActiveUsers.findIndex(
      (obj, indexVal) => obj.uuid === selfUUID
    );
    if (index !== -1) {
      newActiveUsers.splice(index, 1);
    }
    setActiveUsers(newActiveUsers);
  };

  const incrementMessageCount = (messageEvent) => {
    const userArray = [...activeUsers];
    for (let index = 0; index < userArray.length; index++) {
      const element = userArray[index];

      if (element.uuid === messageEvent.publisher) {
        element.newMessageCount += 1;
        break;
      }
    }
    console.log('before splicing');
    console.log(userArray)

    userArray.forEach(function (item, i) {
      if (item.uuid === messageEvent.publisher) {
        userArray.splice(i, 1);
        userArray.unshift(item);
      }
    });
    // setNewMessageCount();
    console.log('after splicing');
    console.log(userArray)
    setActiveUsers(userArray);
  };

  const handleScrollInView = () => {
    if (chatContainerRef && chatContainerRef.current) {
      chatContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    setNewMessageCount();
  }, [activeUsers]);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevActiveChannel = usePrevious(activeChannel);

  useEffect(() => {

    const newActiveUsers = Array.from(activeUsers);

    try {
      pubnub.removeAllListeners();
      pubNubListener = {
        message: (messageEvent) => {
          console.log(messageEvent);

          const messageWithLink = createLink(
            DOMPurify.sanitize(messageEvent.message.content)
          );
          messageEvent.message.content = messageWithLink;
          if (messageEvent.channel === activeChannel) {
            if (messages.length > maxMessagesInList) {
              messages.shift();
            }
            const newMessagesArr = Array.from(messages);
            newMessagesArr.push(messageEvent.message);
            setMessages(newMessagesArr);
            adjustScrollHeight(newMessagesArr);

            if (messageEvent.publisher !== selfUUID) {
              setLastSentMessageTimetoken(messageEvent.message.timetoken);
              sendMessageReceipt(
                activeChannel,
                "message_delivered",
                messageEvent.message.timetoken,
                () => handleScrollInView(),
                () => handleScrollInView()
              );
            } else {
              const newArray = [...unseenMessages, messageEvent.message];

              clearTimeout(timer);
              timer = setTimeout(() => {
                sendEmailForUnseenMessages(currentUser, newArray);
              }, timeoutValue);
              setUnseenMessages(newArray);
              setLastSentMessageTimetoken("");
              handleScrollInView();
            }
          } else {
            incrementMessageCount(messageEvent);
          }
        },
        presence: function (p) {
          if (p.action == "join") {
            const existingUser = newActiveUsers.filter(
              (obj) => obj.uuid === p.uuid
            );
            if (!existingUser.length) {
              pubnub.objects.getUUIDMetadata(
                {
                  uuid: p.uuid,
                },
                (status, response) => {
                  console.log('useEffect 1');
                  console.log(response);
                  if (status && status.statusCode === 200) {
                    const dataObj = response.data;
                    const addedUser = newActiveUsers.filter(
                      (obj) => obj.uuid === p.uuid
                    );
                    if (!addedUser.length) {
                      newActiveUsers.push({
                        uuid: p.uuid,
                        newMessageCount: 0,
                        ...dataObj,
                      });
                    }
                  }
                  newActiveUsers.sort();
                  removeSelfUserFromUserList(newActiveUsers);
                  setPresenceOccupancy(newActiveUsers.length);
                }
              );
            }
          }
          if (p.action == "timeout" || p.action == "leave") {
            const index = newActiveUsers.findIndex(
              (obj, indexVal) => obj.uuid === p.uuid
            );
            // const index = newActiveUsers.indexOf(p.uuid);
            if (index !== -1) {
              newActiveUsers.splice(index, 1);
              removeSelfUserFromUserList(newActiveUsers);
              setPresenceOccupancy(newActiveUsers.length);
            }
          }
        },
        file: function (event) {
          console.log('useEffect 2');

          console.log(event);

          const fileObj = {
            message: event.message,
            file: event.file,
          };

          if (event.channel === activeChannel) {
            if (messages.length > maxMessagesInList) {
              messages.shift();
            }
            const newMessagesArr = Array.from(messages);
            newMessagesArr.push(fileObj);
            setMessages(newMessagesArr);
            adjustScrollHeight(newMessagesArr);

            if (event.publisher !== selfUUID) {
              setLastSentMessageTimetoken(event.message.timetoken);
              sendMessageReceipt(
                activeChannel,
                "message_delivered",
                event.message.timetoken,
                () => handleScrollInView(),
                () => handleScrollInView()
              );
            } else {
              const newArray = [...unseenMessages, event.message];

              clearTimeout(timer);
              timer = setTimeout(() => {
                sendEmailForUnseenMessages(currentUser, newArray);
              }, timeoutValue);

              setUnseenMessages(newArray);
              setLastSentMessageTimetoken("");
              handleScrollInView();
            }
          } else {
            incrementMessageCount(event);
          }
        },
        messageAction: (action) => {
          console.log(action);
          if (
            action.publisher !== selfUUID &&
            action.data.value === "message_read"
          ) {
            clearTimeout(timer);
            setUnseenMessages([]);
            setLastReceivedMessageTimetoken(action.data.messageTimetoken);
          }
        },
      };
      pubnub.addListener(pubNubListener);
    } catch (e) {
      console.log(`error ${e.message}`);
    }
  }, [activeChannel, messages, activeUsers, unseenMessages]);

  useEffect(() => {
    setIsLoading(true);
    const newActiveUsers = Array.from(activeUsers);
    pubnub.objects.getAllUUIDMetadata({
      customFields: true,
      sort: { updated: 'desc' },
      limit: 25
    }).then((response => {
      console.log('In getAllUUIDMetadata');
      console.log(response);
      //removeSelfUserFromUserList(response.data);
      //setPresenceOccupancy(response.data.length);
      console.log(response.data.length);
      response.data.forEach(async function (entry) {
        const dataObj = await pubnub.objects.getUUIDMetadata({
          uuid: entry.id,
        });
        //console.log(dataObj);
        /*if (dataObj.data.custom !== null) {
          console.log(dataObj.data.id);
          console.log(dataObj.data.custom.updatedyet);
          if ((dataObj.data.custom.updatedyet !== undefined) && dataObj.data.custom.updatedyet.length != 24) {
            console.log('dataObj.data.custom.updatedyet');
            console.log(dataObj.data.custom.updatedyet);
            console.log('updating the value : ');

            pubnub.objects.setUUIDMetadata(
              {
                uuid: entry.id,
                data: {
                  custom: {
                    updatedat: dataObj.data.updated.substring(0, 23) + 'Z',
                  }
                },
              });
          }

        } else {
          console.log('dataObj.data.custom.updatedyet is NULL');
          pubnub.objects.setUUIDMetadata(
            {
              uuid: entry.id,
              data: {
                custom: {
                  updatedat: dataObj.data.updated.substring(0, 23) + 'Z',
                }
              },
            });

        }

         if (!dataObj.data.custom.updatedyet) {
 
         }*/
        if (dataObj && dataObj.status !== 200) {
          displayNotification("error", "Unable to fetch user list");
        }
        const addedUser = newActiveUsers.filter(
          (obj) => obj.uuid === entry.id
        );
        if (!addedUser.length) {
          newActiveUsers.push({
            uuid: entry.id,
            newMessageCount: newMessageCountObj
              ? newMessageCountObj[entry.id] || 0
              : 0,
            ...dataObj.data,
          });
        }
      });

    }
    )

    );

    try {
      if (presence) {
        pubnub.hereNow(
          {
            /*channels: [channel, 'pediametrix-support.provider13@pediametrix_com',
              'pediametrix-support.provider15@pediametrix_com', 'pediametrix-support.parent71@pediametrix_com',
              'pediametrix-support.parent61@pediametrix_com'],*/
            channels: [channel],
            includeUUIDs: true, // In this demo we're using the uuid as the user's name. You could also use presence state to provide a username and more. In this app all we need is the UUID of online users.
            includeState: true,
          },
          async (status, response) => {
            if (
              response &&
              response.channels &&
              response.channels[channel].occupancy > 0
            ) {
              for (let i = 0; i < response.channels[channel].occupancy; i++) {
                const existingUser = activeUsers.filter(
                  (obj) =>
                    obj.uuid === response.channels[channel].occupants[i].uuid
                );
                if (
                  !existingUser.length &&
                  response.channels[channel].occupants[i].uuid !== selfUUID
                ) {
                  const userUUID = response.channels[channel].occupants[i].uuid;
                  const dataObj = await pubnub.objects.getUUIDMetadata({
                    uuid: userUUID,
                  });
                  if (dataObj && dataObj.status !== 200) {
                    displayNotification("error", "Unable to fetch user list");
                  }
                  /*newActiveUsers.push({
                    uuid: userUUID,
                    newMessageCount: newMessageCountObj
                      ? newMessageCountObj[userUUID] || 0
                      : 0,
                    ...dataObj.data,
                  });*/
                }
              }
              setIsLoading(false);
              newActiveUsers.sort();
              removeSelfUserFromUserList(newActiveUsers);
              setPresenceOccupancy(newActiveUsers.length);
            }
          }
        );
      }
      pubnub.subscribe({
        channels: [channel, channel + ".*"], // Subscribe to global channel for presence events from chat clients and to the wildcard for all sub channels for chat messages.
        withPresence: presence,
      });
    } catch (e) {
      displayNotification(
        "error",
        "Unable to connect to users. Please try reloading the page"
      );
    }
  }, []);

  useEffect(() => {
    if (searchVal && searchVal.trim().length > 2) {
      pubnub.objects
        .getAllUUIDMetadata({ filter: "name LIKE '" + searchVal.trim() + "*'" })
        .then((response) => {
          const memberArray = response.data || [];
          setMemberList(memberArray);
        })
        .catch((err) => {
          displayNotification("error", "Error in searching user data");
        });
    }
  }, [searchVal]);

  const sendMessage = useCallback(
    async (message) => {
      if (activeChannel) {
        await pubnub.publish(
          {
            channel: activeChannel,
            message: {
              id: selfUUID,
              author: selfName,
              content: DOMPurify.sanitize(message),
              timetoken: +moment(),
              name: selfName,
              email: "",
            },
          },
          (status, response) => {
          }
        );
      } else {
        displayNotification(
          "error",
          "Select a conversation from the user list first to send messages."
        );
      }
      setInput("");
    },
    [pubnub, setInput, activeChannel]
  );

  let isSent = false,
    timeout = null;

  const handleScroll = (e) => {
    clearTimeout(timeout);

    timeout = setTimeout(function () {
      if (lastSentMessageTimetoken !== timetokenForLastActionSent && !isSent) {
        isSent = true;
        sendMessageReceipt(
          activeChannel,
          "message_read",
          lastSentMessageTimetoken
        );
        setTimetokenForLastActionSent(lastSentMessageTimetoken);
      }
    }, 50);
  };

  const handleChangeChannel = (userEmail, userData) => {
    const activeUser = userEmail.replace(/\./g, "_");
    const newChannel = globalChannel + "." + activeUser;

    if (activeChannel !== newChannel) {
      const historyMessages = [];
      let lastMessageAction = null;

      sendEmailForUnseenMessages(currentUser, unseenMessages);
      setCurrentUser(userEmail);

      pubnub.history(
        {
          channel: channel + "." + activeUser,
          count: historyMax, // Limit of 100 messages.
        },
        (status, response) => {
          if (response && response.messages && response.messages.length > 0) {
            for (let i = 0; i <= response.messages.length; i++) {
              const messageObject = response.messages[i];
              if (typeof messageObject !== "undefined") {
                const messageWithLink = createLink(
                  DOMPurify.sanitize(messageObject.entry.content)
                );
                messageObject.entry.content = messageWithLink;
                historyMessages.push(messageObject.entry);
                const senderName = messageObject.entry.file
                  ? messageObject.entry.message.name
                  : messageObject.entry.name;
                if (senderName !== selfName) {
                  lastMessageAction = messageObject.entry.file
                    ? {
                      file: messageObject.entry.file,
                      ...messageObject.entry.message,
                    }
                    : messageObject.entry;
                }
              }
            }
            setFirstMessageTimetamp(response.messages[0].timetoken);
          } else {
            historyMessages.push({
              id: selfUUID,
              author: selfName,
              content: DOMPurify.sanitize(
                "Send a message to " +
                activeUser +
                " to start the conversation."
              ),
              timetoken: +moment(),
              name: selfName,
              email: "",
            });
          }

          pubnub.getMessageActions(
            {
              channel: newChannel,
              limit: 25,
            },
            (status, response) => {
              if (response.data) {
                let lastReadMessageBySelf = "",
                  lastReadMessageByOther = "";
                const dataArray = response.data;

                dataArray.forEach((obj) => {
                  if (obj.value === "message_read") {
                    if (obj.uuid === selfUUID) {
                      lastReadMessageBySelf = obj.messageTimetoken;
                    } else {
                      lastReadMessageByOther = obj.messageTimetoken;
                    }
                  }
                });
                setLastReceivedMessageTimetoken(lastReadMessageByOther);
                setLastSentMessageTimetoken(lastReadMessageBySelf);
                if (
                  lastMessageAction &&
                  lastMessageAction.timetoken &&
                  parseInt(lastMessageAction.timetoken) !==
                  parseInt(lastReadMessageBySelf)
                ) {
                  sendMessageReceipt(
                    newChannel,
                    "message_read",
                    lastMessageAction.timetoken
                  );
                  setTimetokenForLastActionSent(lastMessageAction.timetoken);
                } else {
                  setTimetokenForLastActionSent(lastReadMessageBySelf);
                }
              }
            }
          );
          setMessages(historyMessages);
          setMessageCount(historyMessages.length);
          adjustScrollHeight(historyMessages);
          handleScrollInView();
        }
      );

      let isUserFound = false;
      const userArray = [...activeUsers];
      for (let index = 0; index < userArray.length; index++) {
        const element = userArray[index];

        if (element.uuid === activeUser) {
          element.newMessageCount = 0;
          isUserFound = true;
          break;
        }
      }
      if (!isUserFound) {
        const userObject = {
          uuid: activeUser,
          newMessageCount: 0,
          ...userData,
        };
        userArray.push(userObject);
      } else {
        // setNewMessageCount();
      }
      setActiveUsers(userArray);
      setActiveChannel(channel + "." + activeUser);
    }
  };

  const handleSendFile = useCallback(
    async (e) => {
      if (e.target.files) {
        const selectedFiles =
          e.target && e.target.files ? e.target.files[0] : null;
        console.log(selectedFiles);
        try {
          await pubnub.sendFile(
            {
              channel: activeChannel,
              file: selectedFiles,
              message: {
                id: "",
                author: selfName,
                content: "File",
                timetoken: +moment(),
                name: selfName,
                email: "",
              },
              storeInHistory: true,
            },
            (status, response) => {
              console.log(response);
            }
          );
          // e.currentTarget.value = null;
        } catch (exception) {
          console.log(exception);
        }
      }
    },
    [pubnub, setInput, activeChannel]
  );

  const initiateConversation = (newValue) => {
    setSearchedUser(newValue);
    setSearchVal("");
    handleChangeChannel(newValue.email, newValue);
  };

  const handleLoadMessagesFromHistory = () => {
    console.log(firstMessageTimestamp);
    const historyMessages = [];
    pubnub.history(
      {
        channel: activeChannel,
        count: historyMax, // Limit of 100 messages.
        start: firstMessageTimestamp,
      },
      (status, response) => {
        if (response && response.messages && response.messages.length > 0) {
          for (let i = 0; i <= response.messages.length; i++) {
            const messageObject = response.messages[i];
            if (messageObject) {
              const messageWithLink = createLink(
                DOMPurify.sanitize(messageObject.entry.content)
              );
              messageObject.entry.content = messageWithLink;
              historyMessages.push(messageObject.entry);
            }
          }
          setFirstMessageTimetamp(response.messages[0].timetoken);
        } else {
          setFirstMessageTimetamp("");
        }

        const messageArray = [...historyMessages, ...messages];
        setMessages(messageArray);
        setMessageCount(historyMessages.length);
      }
    );
  };

  const handleDisplayTemplate = () => {
    setSelectedTemplateIndex(-1);
    setOpenDialog(true);
  };
  const dialogTitle = (
    <>
      <div className="dialog-title">Templates</div>
      <div className="dialog-subTitle">
        Select a template to send to the user
      </div>
    </>
  );
  const getDialogContent = () => {
    return (
      <div className="template-container">
        {templateHtmlArray.map((text, index) => {
          return (
            <div
              className={`message-template ${index === selectedTemplateIndex ? "selected" : ""
                }`}
              onClick={() => setSelectedTemplateIndex(index)}
            >
              {text}
            </div>
          );
        })}
      </div>
    );
  };

  if (!props.isInitialised) {
    return null;
  }

  return (
    props.isInitialised && (
      <div className="content-wrapper">
        <Notification
          show={showNotification}
          handleClose={handleHideNotification}
          additionalClass="notification-wrapper"
          variant={notificationVariant}
          message={notificationMessage}
        />
        <Dialog
          additionalClass="chat-dialog"
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          dialogTitle={dialogTitle}
          dialogContent={getDialogContent()}
          firstActionText="Done"
          firstActionHandler={(e) => {
            setInput(templateArray[selectedTemplateIndex]);
            setOpenDialog(false);
          }}
          closeIcon={true}
        />
        <div className="admin-dashboard">
          <div className="admin-container">
            {isLoading && (
              <div className="backdrop-loader custom-backdrop">
                <CircularProgress color="inherit" />
              </div>
            )}
            <Typography
              variant="h6"
              id="tableTitle"
              component="div"
              className="page-title"
            >
              Chat with users
            </Typography>
            <Grid container spacing={3} className="chat-container">
              <Grid item xs={3}>
                <div className="user-search">
                  <Autocomplete
                    freeSolo
                    id="user_search"
                    name="user_identifier"
                    disableClearable
                    className="user-search-input"
                    options={memberList}
                    getOptionLabel={(option) => {
                      const emailVal = option.email
                        ? " (" + option.email + ")"
                        : "";
                      return option ? option.name + emailVal : "";
                    }}
                    onChange={(event, newValue) => {
                      initiateConversation(newValue);
                    }}
                    value={searchedUser}
                    inputValue={searchVal}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder="Search"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            setSearchVal(e.target.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
                <div className="user-list-container">
                  {activeUsers.length > 0 &&
                    activeUsers.map((userName, index) => {
                      return (
                        <div
                          key={userName.uuid + index}
                          className={`user-name-container ${activeChannel ===
                            globalChannel + "." + userName.uuid
                            ? "selected"
                            : ""
                            }`}
                          onClick={() =>
                            handleChangeChannel(userName.email || userName.uuid)
                          }
                        >
                          <div className="user-data-wrapper">
                            <Avatar alt="" className="userAvatar">
                              <AccountCircleIcon />
                            </Avatar>
                            <div className="user-name">
                              <div className="user-name" title={userName.name}>
                                {userName.name || userName.uuid}
                              </div>
                              <div
                                className="subtitle user-name"
                                title={userName.email}
                              >
                                {userName.email || ""}
                              </div>
                            </div>
                          </div>
                          {userName.newMessageCount > 0 && (
                            <div className="newMessage">
                              {userName.newMessageCount}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </Grid>
              <Grid item xs={9}>
                {activeChannel &&
                  firstMessageTimestamp &&
                  messageCount >= historyMax ? (
                  <div
                    className="load-history-chats-link"
                    onClick={handleLoadMessagesFromHistory}
                  >
                    Load messages from history
                  </div>
                ) : null}
                <div
                  className="chat-content-container"
                  onScroll={handleScroll}
                  id="chat-wrapper"
                >
                  {messages.map((messageObj, messageIndex) => {
                    const message = messageObj.file
                      ? {
                        file: messageObj.file,
                        ...messageObj.message,
                      }
                      : { ...messageObj };

                    return message.content ? (
                      <div
                        key={`message-${messageIndex}`}
                        className={`chat-message ${selfName !== message.name ? "received" : ""
                          }`}
                      >
                        <div className="details">
                          <div className="mr10">
                            {selfName === message.name ? "You" : message.name}
                          </div>
                          <div>
                            {message.timetoken
                              ? moment(message.timetoken).format(
                                "MMM DD HH:mm"
                              ) || ""
                              : ""}
                          </div>
                        </div>
                        <div
                          className={`message-container ${selfName !== message.name ? "received" : ""
                            } ${selfName !== message.name
                              ? parseInt(message.timetoken) >
                                parseInt(lastSentMessageTimetoken)
                                ? "unread"
                                : ""
                              : ""
                            }`}
                        >
                          {selfName === message.name ? (
                            <>
                              <div className="message received">
                                {message.file ? (
                                  <div className="file-wrapper">
                                    <span>File {message.file.name || ""}</span>
                                    <div className="file-container">
                                      <InsertDriveFileIcon
                                        onClick={() => {
                                          const result = pubnub.getFileUrl({
                                            channel: activeChannel,
                                            id: message.file.id,
                                            name: message.file.name,
                                          });
                                          window.open(result, "_blank");
                                        }}
                                        titleAccess="Open file"
                                      />
                                      <CloudDownloadIcon
                                        titleAccess="Download file"
                                        onClick={async () => {
                                          const fileObj = await pubnub.downloadFile(
                                            {
                                              channel: activeChannel,
                                              id: message.file.id,
                                              name: message.file.name,
                                            }
                                          );
                                          const fileURL = URL.createObjectURL(
                                            fileObj.data
                                          );
                                          const link = document.createElement(
                                            "a"
                                          );
                                          link.href = fileURL;
                                          link.setAttribute(
                                            "download",
                                            message.file.name
                                          );
                                          document.body.appendChild(link);
                                          link.click();
                                          link.remove();
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  parse(message.content) || ""
                                )}
                              </div>
                              <Avatar alt="" className="userAvatar">
                                <AccountCircleIcon />
                              </Avatar>
                            </>
                          ) : (
                            <>
                              <Avatar alt="" className="userAvatar otherUser">
                                {message.name[0] || <AccountCircleIcon />}
                              </Avatar>
                              <div className="message sent">
                                {message.file ? (
                                  <div className="file-wrapper">
                                    <span>File {message.file.name || ""}</span>
                                    <div className="file-container">
                                      <InsertDriveFileIcon
                                        titleAccess="Open file"
                                        onClick={() => {
                                          const result = pubnub.getFileUrl({
                                            channel: activeChannel,
                                            id: message.file.id,
                                            name: message.file.name,
                                          });
                                          window.open(result, "_blank");
                                        }}
                                      />
                                      <CloudDownloadIcon
                                        titleAccess="Download file"
                                        onClick={async () => {
                                          const fileObj = await pubnub.downloadFile(
                                            {
                                              channel: activeChannel,
                                              id: message.file.id,
                                              name: message.file.name,
                                            }
                                          );
                                          const fileURL = URL.createObjectURL(
                                            fileObj.data
                                          );
                                          const link = document.createElement(
                                            "a"
                                          );
                                          link.href = fileURL;
                                          link.setAttribute(
                                            "download",
                                            message.file.name
                                          );
                                          document.body.appendChild(link);
                                          link.click();
                                          link.remove();
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  parse(message.content) || ""
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        {selfName === message.name && (
                          <div className="delivery-details">
                            <div>
                              {lastReceivedMessageTimetoken &&
                                message.timetoken <=
                                lastReceivedMessageTimetoken ? (
                                <DoneAllIcon />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null;
                  })}
                  <div
                    ref={chatContainerRef}
                    style={{
                      height: scrollRefHeight > 0 ? scrollRefHeight : 0,
                    }}
                  />
                </div>
                <div className="chat-bottom-div">
                  <form>
                    <Textbox
                      name="chat-area"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      additionalClass="chat-input"
                      placeholder="Enter text here..."
                    />
                    <div className="chat-attach-input-label">
                      <CreateIcon
                        className="mr10"
                        onClick={handleDisplayTemplate}
                      />
                      <label
                        htmlFor="chat-attach-input"
                        className="display-flex"
                      >
                        <input
                          type="file"
                          onChange={handleSendFile}
                          id="chat-attach-input"
                          className="chat-attach-input"
                        />
                        <AttachFileIcon />
                      </label>
                    </div>
                    <Button
                      type="submit"
                      text="Send"
                      name="send-chat-message"
                      additionalClass="chat-send"
                      onClick={(e) => {
                        e.preventDefault();
                        sendMessage(input);
                      }}
                    />
                  </form>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  );
};

export default SupportChat;
