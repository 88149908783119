import axiosInstance from "../PMCommon/PMApiHandler";
import apiEndPoints from "../PMCommon/PMApiEndPoints";
import actions from "./PMActions";

const initialState = {
  userList: null,
  userListError: null,
  reportListError: null,
  isUserListSuccess: false,
  isUserStatusUpdated: false,
  isUserStatusError: false,
  isUserListLoading: false,
  isAddAdminSuccess: false,
  addAdminError: null,
  isAddAdminLoading: false,
  isRemoveAdminSuccess: false,
  removeAdminError: null,
  isRemoveAdminLoading: false,
  isReportListSuccess: false,
  reportList: null,
  isReportStatusUpdated: false,
  isReportStatusError: false,
  resultDownloadError: null,
  resultAcceptError: null,
  resultDownloadFile: null,
  isResultDownloadLoading: false,
  isResultDownloaded: false,
  isMarketingInfoListSuccess: false,
  marketingInfoList: null,
  marketingInfoListError: null,
  isAddPhysicianSuccess: false,
  isAddPhysicianLoading: false,
  addPhysicianError: null,
  isAddReferralCodeLoading: false,
  isAddReferralCodeSuccess: false,
  addReferralCodeError: null,
  isReferralCodeListSuccess: false,
  referralCodeListError: null,
  isReferralCodeLoading: false,
  referralCodeList: null,
  existingCodeList: null,

};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.RESET_DASHBOARD:
      return {
        ...state,
        userList: null,
        userListError: null,
        isUserListSuccess: false,
        isUserStatusUpdated: false,
        isUserStatusError: false,
        isUserListLoading: false,
        isAddAdminSuccess: false,
        isAddReferralCodeSuccess: false,
        addAdminError: null,
        addReferralCodeError: null,
        isAddAdminLoading: false,
        isAddReferralCodeLoading: false,
        isRemoveAdminSuccess: false,
        removeAdminError: null,
        isRemoveAdminLoading: false,
        physicianListData: null,
        physicianListError: null,
        isReportListSuccess: false,
        reportList: null,
        reportListError: null,
        isReportStatusUpdated: false,
        isReportStatusError: false,
        resultDownloadError: null,
        resultAcceptError: null,
        resultDownloadFile: null,
        isResultDownloadLoading: false,
        isResultDownloaded: false,
        isMarketingInfoListSuccess: false,
        marketingInfoList: null,
        marketingInfoListError: null,
        isAddPhysicianSuccess: false,
        isAddPhysicianLoading: false,
        addPhysicianError: null,
        isReferralCodeListSuccess: false,
        referralCodeListError: null,
        isReferralCodeLoading: false,
        referralCodeList: null,
        existingCodeList: null,
      };
    case actions.RESET_USER_STATUS_FLAG:
      return {
        ...state,
        isUserStatusUpdated: false,
        isUserStatusError: false,
      };
    case actions.USER_LIST_SUCESS:
      return {
        ...state,
        isUserListSuccess: true,
        userList:
          action.data && action.data.data ? action.data.data.results : null,
      };
    case actions.USER_LIST_ERROR:
      return {
        ...state,
        isUserListSuccess: false,
        userListError:
          action.data && action.data.data ? action.data.data : null,
      };

    case actions.REPORT_LIST_SUCCESS:
      return {
        ...state,
        isReportListSuccess: true,
        reportList:
          action.data && action.data.data ? action.data.data.results : null,
      };
    case actions.REPORT_LIST_ERROR:
      return {
        ...state,
        isReportListSuccess: false,
        reportListError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.MARKETING_INFO_LIST_SUCCESS:
      return {
        ...state,
        isMarketingInfoListSuccess: true,
        marketingInfoList:
          action.data && action.data.data ? action.data.data.results : null,
      };
    case actions.MARKETING_INFO_LIST_ERROR:
      return {
        ...state,
        isMarketingInfoListSuccess: false,
        marketingInfoListError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.MARKETING_INFO_LIST_LOADING:
      return {
        ...state,
        isReferralCodeLoading: action.data,
      };
    case actions.REFERRAL_CODE_LIST_SUCCESS:
      return {
        ...state,
        isReferralCodeListSuccess: true,
        referralCodeList:
          action.data && action.data.data ? action.data.data.results : null,
      };
    case actions.REFERRAL_CODE_LIST_ERROR:
      return {
        ...state,
        isReferralCodeListSuccess: false,
        referralCodeListError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.UPDATE_USER_STATUS_SUCCESS:
      return {
        ...state,
        isUserStatusUpdated: true,
        isUserStatusError: false,
      };
    case actions.UPDATE_USER_STATUS_ERROR:
      return {
        ...state,
        isUserStatusUpdated: false,
        isUserStatusError: true,
      };

    case actions.UPDATE_REPORT_STATUS_SUCCESS:
      return {
        ...state,
        isReportStatusUpdated: true,
        isReportStatusError: false,
      };
    case actions.UPDATE_REPORT_STATUS_ERROR:
      return {
        ...state,
        isReportStatusUpdated: false,
        isReportStatusError: true,
        resultAcceptError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.RESET_REPORT_STATUS:
      return {
        ...state,
        isReportStatusUpdated: action.data,
        isReportStatusError: false,
      };
    case actions.USER_LIST_LOADING:
      return {
        ...state,
        isUserListLoading: action.data,
      };
    case actions.ADD_ADMIN_SUCCESS:
      return {
        ...state,
        isAddAdminSuccess: true,
        addAdminError: null,
      };
    case actions.ADD_ADMIN_ERROR:
      return {
        ...state,
        isAddAdminSuccess: false,
        addAdminError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.ADD_ADMIN_LOADING:
      return {
        ...state,
        isAddAdminLoading: action.data,
      };
    case actions.ADD_ADMIN_RESET:
      return {
        ...state,
        isAddAdminSuccess: false,
        addAdminError: null,
        isAddAdminLoading: false,
      };
    case actions.ADD_REFERRAL_CODE_LOADING:
      return {
        ...state,
        isAddReferralCodeLoading: action.data,
      };
    case actions.ADD_REFERRAL_CODE_SUCCESS:
      return {
        ...state,
        isAddReferralCodeSuccess: true,
        existingCodeList:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.ADD_REFERRAL_CODE_ERROR:
      return {
        ...state,
        isAddReferralCodeSuccess: false,
        addReferralCodeError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.ADD_REFERRAL_CODE_RESET:
      return {
        ...state,
        isAddReferralCodeSuccess: false,
        addReferralCodeError: null,
        isAddReferralCodeLoading: false,
        existingCodeList: null,
      };
    case actions.ADD_PHYSICIAN_SUCCESS:
      return {
        ...state,
        isAddPhysicianSuccess: true,
        addPhysicianError: null,
      };
    case actions.ADD_PHYSICIAN_ERROR:
      return {
        ...state,
        isAddPhysicianSuccess: false,
        addPhysicianError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.ADD_PHYSICIAN_LOADING:
      return {
        ...state,
        isAddPhysicianLoading: action.data,
      };
    case actions.ADD_PHYSICIAN_RESET:
      return {
        ...state,
        isAddPhysicianSuccess: false,
        addPhysicianError: null,
        isAddPhysicianLoading: false,
      };
    case actions.ROLE_CHANGE_SUCCESS:
      return {
        ...state,
        isRoleChangeSuccess: true,
        roleChangeError: null,
      };
    case actions.ROLE_CHANGE_ERROR:
      return {
        ...state,
        isRoleChangeSuccess: false,
        roleChangeError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.ROLE_CHANGE_LOADING:
      return {
        ...state,
        isRoleChangeLoading: action.data,
      };
    case actions.ROLE_CHANGE_RESET:
      return {
        ...state,
        isRoleChangeSuccess: false,
        roleChangeError: null,
        isRoleChangeLoading: false,
      };
    case actions.REMOVE_ADMIN_SUCCESS:
      return {
        ...state,
        isRemoveAdminSuccess: true,
        removeAdminError: null,
      };
    case actions.REMOVE_ADMIN_ERROR:
      return {
        ...state,
        isRemoveAdminSuccess: false,
        removeAdminError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.REMOVE_ADMIN_LOADING:
      return {
        ...state,
        isRemoveAdminLoading: action.data,
      };
    case actions.REMOVE_ADMIN_RESET:
      return {
        ...state,
        isRemoveAdminSuccess: false,
        removeAdminError: null,
        isRemoveAdminLoading: false,
      };
    case actions.PHYSICIAN_LIST:
      return {
        ...state,
        physicianListData:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.PHYSICIAN_LIST_ERROR:
      return {
        ...state,
        physicianListError:
          action.data && action.data.data ? action.data.data.errors : null,
      };
    case actions.CHANGE_PHYSICIAN_LOADING:
      return {
        ...state,
        isChangePhysicianLoading: action.data,
      };
    case actions.CHANGE_PHYSICIAN_ERROR:
      return {
        ...state,
        isChangePhysicianSuccess: false,
        changePhysicianError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.CHANGE_PHYSICIAN_SUCCESS:
      return {
        ...state,
        isChangePhysicianSuccess: true,
        changePhysicianError: null,
      };
    case actions.CHANGE_PHYSICIAN_RESET:
      return {
        ...state,
        isChangePhysicianLoading: false,
        isChangePhysicianSuccess: false,
        changePhysicianError: null,
      };
    case actions.RESULT_DOWNLOAD_LOADING:
      return {
        ...state,
        isResultDownloadLoading: action.data,
      };
    case actions.RESULT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        isResultDownloaded: true,
        resultDownloadError: null,
        resultDownloadFile: action.data ? action.data.data : null,
      };
    case actions.RESULT_DOWNLOAD_ERROR:
      return {
        ...state,
        isResultDownloaded: false,
        resultDownloadError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.RESULT_DOWNLOAD_RESET:
      return {
        ...state,
        isResultDownloaded: false,
        resultDownloadError: null,
        resultDownloadFile: null,
      };
    default:
      return state;
  }
};

const getUserList = (dataObj, queryParams = "") => {
  return (dispatch) => {
    dispatch({
      type: actions.USER_LIST_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.GET_USER_LIST + "?" + queryParams, dataObj)
      .then((data) => {
        dispatch({
          type: actions.USER_LIST_LOADING,
          data: false,
        });
        dispatch({
          type: actions.USER_LIST_SUCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.USER_LIST_LOADING,
          data: false,
        });
        dispatch({
          type: actions.USER_LIST_ERROR,
          data: error,
        });
      });
  };
};

const getAdminReportsList = (dataObj, queryParams = "") => {
  return (dispatch) => {
    dispatch({
      type: actions.ADMIN_REPORT_LIST_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.GET_ADMIN_REPORTS_LIST + "?" + queryParams, dataObj)
      .then((data) => {
        dispatch({
          type: actions.ADMIN_REPORT_LIST_LOADING,
          data: false,
        });
        dispatch({
          type: actions.REPORT_LIST_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.ADMIN_REPORT_LIST_LOADING,
          data: false,
        });
        dispatch({
          type: actions.REPORT_LIST_ERROR,
          data: error,
        });
      });
  };
};

const getDebugReportsList = (dataObj, queryParams = "") => {
  return (dispatch) => {
    dispatch({
      type: actions.DEBUG_REPORT_LIST_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.GET_DEBUG_REPORTS_LIST + "?" + queryParams, dataObj)
      .then((data) => {
        dispatch({
          type: actions.DEBUG_REPORT_LIST_LOADING,
          data: false,
        });
        dispatch({
          type: actions.REPORT_LIST_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.DEBUG_REPORT_LIST_LOADING,
          data: false,
        });
        dispatch({
          type: actions.REPORT_LIST_ERROR,
          data: error,
        });
      });
  };
};

const getMarketingInfoList = (dataObj, queryParams = "") => {
  return (dispatch) => {
    dispatch({
      type: actions.MARKETING_INFO_LIST_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.GET_MARKETING_INFO_LIST + "?" + queryParams, dataObj)
      .then((data) => {
        console.log("PMDashboardActionReducer -> getMarketingInfoList Response : ", data)
        dispatch({
          type: actions.MARKETING_INFO_LIST_LOADING,
          data: false,
        });
        dispatch({
          type: actions.MARKETING_INFO_LIST_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.MARKETING_INFO_LIST_LOADING,
          data: false,
        });
        dispatch({
          type: actions.MARKETING_INFO_LIST_ERROR,
          data: error,
        });
      });
  };
};

const getReferralCodeList = (dataObj, queryParams = "") => {
  return (dispatch) => {
    dispatch({
      type: actions.REFERRAL_CODE_LIST_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.GET_REFERRAL_CODE_LIST + "?" + queryParams, dataObj)
      .then((data) => {
        dispatch({
          type: actions.REFERRAL_CODE_LIST_LOADING,
          data: false,
        });
        dispatch({
          type: actions.REFERRAL_CODE_LIST_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.REFERRAL_CODE_LIST_LOADING,
          data: false,
        });
        dispatch({
          type: actions.REFERRAL_CODE_LIST_ERROR,
          data: error,
        });
      });
  };
};

const updateUserStatus = (dataObj) => {
  return (dispatch) => {
    axiosInstance
      .post(apiEndPoints.UPDATE_USER_STATUS, dataObj)
      .then((data) => {
        dispatch({
          type: actions.UPDATE_USER_STATUS_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.UPDATE_USER_STATUS_ERROR,
          data: error,
        });
      });
  };
};


const resetUpdateReportStatus = (reportStatus) => {
  return (dispatch) => {
    dispatch({
      type: actions.RESET_REPORT_STATUS,
      data: reportStatus,
    });
  };
};

const updateReportStatus = (dataObj) => {
  return (dispatch) => {
    axiosInstance
      .post(apiEndPoints.UPDATE_REPORT_STATUS, dataObj)
      .then((data) => {
        dispatch({
          type: actions.UPDATE_REPORT_STATUS_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.UPDATE_REPORT_STATUS_ERROR,
          data: error,
        });
      });
  };
};

const resetUserStatusFlag = () => {
  return (dispatch) => {
    dispatch({
      type: actions.RESET_USER_STATUS_FLAG,
    });
  };
};

const resetDashboard = () => {
  return (dispatch) => {
    dispatch({
      type: actions.RESET_DASHBOARD,
    });
  };
};

const resetRemoveAdmin = () => {
  return (dispatch) => {
    dispatch({
      type: actions.REMOVE_ADMIN_RESET,
    });
  };
};

const addNewAdmin = (dataObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.ADD_ADMIN_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.ADD_USER, dataObj)
      .then((data) => {
        dispatch({
          type: actions.ADD_ADMIN_LOADING,
          data: false,
        });
        dispatch({
          type: actions.ADD_ADMIN_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.ADD_ADMIN_LOADING,
          data: false,
        });
        dispatch({
          type: actions.ADD_ADMIN_ERROR,
          data: error,
        });
      });
  };
};

const resetAddAdmin = () => {
  return (dispatch) => {
    dispatch({
      type: actions.ADD_ADMIN_RESET,
    });
  };
};

const resetAddReferralCode = () => {
  return (dispatch) => {
    dispatch({
      type: actions.ADD_REFERRAL_CODE_RESET,
    });
  };
};

const addNewPhysician = (dataObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.ADD_PHYSICIAN_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.ADD_USER, dataObj)
      .then((data) => {
        dispatch({
          type: actions.ADD_PHYSICIAN_LOADING,
          data: false,
        });
        dispatch({
          type: actions.ADD_PHYSICIAN_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.ADD_PHYSICIAN_LOADING,
          data: false,
        });
        dispatch({
          type: actions.ADD_PHYSICIAN_ERROR,
          data: error,
        });
      });
  };
};

const addReferralCodes = (dataObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.ADD_REFERRAL_CODE_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.ADD_REFERRAL_CODES, dataObj)
      .then((data) => {
        dispatch({
          type: actions.ADD_REFERRAL_CODE_LOADING,
          data: false,
        });
        dispatch({
          type: actions.ADD_REFERRAL_CODE_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.ADD_REFERRAL_CODE_LOADING,
          data: false,
        });
        dispatch({
          type: actions.ADD_REFERRAL_CODE_ERROR,
          data: error,
        });
      });
  };
};

const resetAddPhysician = () => {
  return (dispatch) => {
    dispatch({
      type: actions.ADD_PHYSICIAN_RESET,
    });
  };
};

const resetChangeRole = () => {
  return (dispatch) => {
    dispatch({
      type: actions.ROLE_CHANGE_RESET,
    });
  };
};

const changeUserRole = (dataObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.ROLE_CHANGE_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.CHANGE_ROLE, dataObj)
      .then((data) => {
        dispatch({
          type: actions.ROLE_CHANGE_LOADING,
          data: false,
        });
        dispatch({
          type: actions.ROLE_CHANGE_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.ROLE_CHANGE_LOADING,
          data: false,
        });
        dispatch({
          type: actions.ROLE_CHANGE_ERROR,
          data: error,
        });
      });
  };
};

const deleteAdmin = (identifier) => {
  return (dispatch) => {
    dispatch({
      type: actions.REMOVE_ADMIN_LOADING,
      data: true,
    });
    axiosInstance
      .delete(apiEndPoints.REMOVE_ADMIN + "/" + identifier)
      .then((data) => {
        dispatch({
          type: actions.REMOVE_ADMIN_LOADING,
          data: false,
        });
        dispatch({
          type: actions.REMOVE_ADMIN_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.REMOVE_ADMIN_LOADING,
          data: false,
        });
        dispatch({
          type: actions.REMOVE_ADMIN_ERROR,
          data: error,
        });
      });
  };
};

const getPhysicianList = (searchTerm) => {
  return (dispatch) => {
    axiosInstance
      .get(`${apiEndPoints.PHYSICIAN_LIST}?name=${searchTerm}`)
      .then((data) => {
        dispatch({
          type: actions.PHYSICIAN_LIST,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.PHYSICIAN_LIST_ERROR,
          data: error,
        });
      });
  };
};

const updateChildPhysician = (dataObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.CHANGE_PHYSICIAN_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.CHANGE_PHYSICIAN, dataObj)
      .then((data) => {
        dispatch({
          type: actions.CHANGE_PHYSICIAN_LOADING,
          data: false,
        });
        dispatch({
          type: actions.CHANGE_PHYSICIAN_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.CHANGE_PHYSICIAN_LOADING,
          data: false,
        });
        dispatch({
          type: actions.CHANGE_PHYSICIAN_ERROR,
          data: error,
        });
      });
  };
};

const resetChangePhysician = () => {
  return (dispatch) => {
    dispatch({
      type: actions.CHANGE_PHYSICIAN_RESET,
    });
  };
};

const downloadResult = (reportIdentifier) => {
  return (dispatch) => {
    dispatch({
      type: actions.RESULT_DOWNLOAD_LOADING,
      data: true,
    });
    let downloadUrl = apiEndPoints.DOWNLOAD_RESULT;
    downloadUrl = downloadUrl.replace(/_id_/g, reportIdentifier);
    axiosInstance
      .get(downloadUrl, { responseType: "blob" })
      .then((data) => {
        dispatch({
          type: actions.RESULT_DOWNLOAD_LOADING,
          data: false,
        });
        dispatch({
          type: actions.RESULT_DOWNLOAD_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.RESULT_DOWNLOAD_LOADING,
          data: false,
        });
        dispatch({
          type: actions.RESULT_DOWNLOAD_ERROR,
          data: error,
        });
      });
  };
};

const downloadDebugResult = (reportIdentifier) => {
  return (dispatch) => {
    dispatch({
      type: actions.RESULT_DOWNLOAD_LOADING,
      data: true,
    });
    let downloadUrl = apiEndPoints.DOWNLOAD_DEBUG_RESULT;
    downloadUrl = downloadUrl.replace(/_id_/g, reportIdentifier);
    axiosInstance
      .get(downloadUrl, { responseType: "blob" })
      .then((data) => {
        dispatch({
          type: actions.RESULT_DOWNLOAD_LOADING,
          data: false,
        });
        dispatch({
          type: actions.RESULT_DOWNLOAD_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.RESULT_DOWNLOAD_LOADING,
          data: false,
        });
        dispatch({
          type: actions.RESULT_DOWNLOAD_ERROR,
          data: error,
        });
      });
  };
};

const downloadParentResult = (reportIdentifier) => {
  return (dispatch) => {
    dispatch({
      type: actions.RESULT_DOWNLOAD_LOADING,
      data: true,
    });
    let downloadUrl = apiEndPoints.DOWNLOAD_PARENT_RESULT;
    downloadUrl = downloadUrl.replace(/_id_/g, reportIdentifier);
    axiosInstance
      .get(downloadUrl, { responseType: "blob" })
      .then((data) => {
        dispatch({
          type: actions.RESULT_DOWNLOAD_LOADING,
          data: false,
        });
        dispatch({
          type: actions.RESULT_DOWNLOAD_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.RESULT_DOWNLOAD_LOADING,
          data: false,
        });
        dispatch({
          type: actions.RESULT_DOWNLOAD_ERROR,
          data: error,
        });
      });
  };
};

const resetResultDownloads = () => {
  return (dispatch) => {
    dispatch({
      type: actions.RESULT_DOWNLOAD_RESET,
    });
  };
};

export const dashboardActions = {
  getUserList,
  resetDashboard,
  updateUserStatus,
  resetUserStatusFlag,
  addNewAdmin,
  resetAddAdmin,
  resetChangeRole,
  changeUserRole,
  deleteAdmin,
  resetRemoveAdmin,
  getPhysicianList,
  updateChildPhysician,
  resetChangePhysician,
  getAdminReportsList,
  updateReportStatus,
  resetUpdateReportStatus,
  downloadResult,
  resetResultDownloads,
  getMarketingInfoList,
  getDebugReportsList,
  downloadDebugResult,
  downloadParentResult,
  addNewPhysician,
  resetAddPhysician,
  addReferralCodes,
  getReferralCodeList,
  resetAddReferralCode
};
