import axiosInstance from "../PMCommon/PMApiHandler";
import apiEndPoints from "../PMCommon/PMApiEndPoints";
import actions from "./PMActions";

const initialState = {
  securityCodeVerificationError: null,
  isSecurityCodeVerificationSuccess: false,
  isSecurityCodeVerificationLoading: false,
  isResendVerificationCodeSuccess: false,
  resendVerificationCodeError: null,
  isResendVerificationCodeLoading: false,
  resendSecurityCodeSuccessMessage: null,
  securityCodeSuccessMessage: null,
};

export const securityCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.VERIFY_SECURITY_CODE_RESET:
      return {
        ...state,
        securityCodeVerificationError: null,
        isSecurityCodeVerificationSuccess: false,
        isSecurityCodeVerificationLoading: false,
        isResendVerificationCodeSuccess: false,
        resendVerificationCodeError: null,
        isResendVerificationCodeLoading: false,
        securityCodeSuccessMessage: null,
        resendSecurityCodeSuccessMessage: null,
      };
    case actions.VERIFY_SECURITY_CODE_SUCCESS:
      return {
        ...state,
        securityCodeSuccessMessage:
          action.data && action.data.data ? action.data.data : null,
        securityCodeVerificationError: null,
        isSecurityCodeVerificationSuccess: true,
      };
    case actions.VERIFY_SECURITY_CODE_ERROR:
      return {
        ...state,
        securityCodeSuccessMessage: null,
        isSecurityCodeVerificationSuccess: false,
        securityCodeVerificationError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.VERIFY_SECURITY_CODE_LOADING:
      return {
        ...state,
        isSecurityCodeVerificationLoading: action.data,
      };
    case actions.RESEND_SECURITY_CODE_LOADING:
      return {
        ...state,
        isResendVerificationCodeLoading: action.data,
      };
    case actions.RESEND_SECURITY_CODE_SUCCESS:
      return {
        ...state,
        resendSecurityCodeSuccessMessage:
          action.data && action.data.data ? action.data.data : null,
        isResendVerificationCodeSuccess: true,
      };
    case actions.RESEND_SECURITY_CODE_ERROR:
      return {
        ...state,
        resendSecurityCodeSuccessMessage: null,
        isResendVerificationCodeSuccess: false,
        resendVerificationCodeError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.RESEND_SECURITY_CODE_RESET:
      return {
        ...state,
        resendSecurityCodeSuccessMessage: null,
        isResendVerificationCodeSuccess: false,
        resendVerificationCodeError: null,
        isResendVerificationCodeLoading: false,
      };
    default:
      return state;
  }
};

const verifySecurityCode = (dataObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.VERIFY_SECURITY_CODE_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.VERIFY_SECURITY_CODE, dataObj)
      .then((data) => {
        dispatch({
          type: actions.VERIFY_SECURITY_CODE_LOADING,
          data: false,
        });
        dispatch({
          type: actions.VERIFY_SECURITY_CODE_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.VERIFY_SECURITY_CODE_LOADING,
          data: false,
        });
        dispatch({
          type: actions.VERIFY_SECURITY_CODE_ERROR,
          data: error,
        });
      });
  };
};

const resetVerifySecurityCode = () => {
  return (dispatch) => {
    dispatch({
      type: actions.VERIFY_SECURITY_CODE_RESET,
    });
  };
};

const resendSecurityCode = (dataObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.RESEND_SECURITY_CODE_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.RESEND_SECURITY_CODE, dataObj)
      .then((data) => {
        dispatch({
          type: actions.RESEND_SECURITY_CODE_LOADING,
          data: false,
        });
        dispatch({
          type: actions.RESEND_SECURITY_CODE_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.RESEND_SECURITY_CODE_LOADING,
          data: false,
        });
        dispatch({
          type: actions.RESEND_SECURITY_CODE_ERROR,
          data: error,
        });
      });
  };
};

const resetResendSecurityCode = () => {
  return (dispatch) => {
    dispatch({
      type: actions.RESEND_SECURITY_CODE_RESET,
    });
  };
};

export const securityCodeActions = {
  verifySecurityCode,
  resetVerifySecurityCode,
  resendSecurityCode,
  resetResendSecurityCode,
};
