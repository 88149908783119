import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Dialog from "../../PMComponents/PMDialog/PMDialog";

import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  NO_PHYSICIAN_EMAIL,
} from "../../PMConstants/PMConstants";
import PersonIcon from "@material-ui/icons/Person";

import getLanguageModule from "../../PMLocalisation/index";
import { dashboardActions } from "../../PMShared/PMRedux/PMDashboardActionReducer";
import { getUserStatusText } from "../../PMShared/PMCommon/PMUtility";
import "./PMDashboard.scss";
import moment from "moment";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";
import Textbox from "../../PMComponents/PMTextbox/PMTextbox";
import { InputAdornment, Collapse } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  checkPermissions, validateAllFields,
} from "../../PMShared/PMCommon/PMUtility";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { usePubNub } from "pubnub-react";
import Button from "../../PMComponents/PMButton/PMButton";
import Dropdown from "../../PMComponents/PMDropdown/PMDropdown";

const globalChannel = ["pediametrix-support"];

const headCells = [
  {
    id: "email",
    align: "left",
    disablePadding: true,
    label: "Email",
  },
  {
    id: "firstName",
    align: "center",
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lastName",
    align: "center",
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "phone",
    align: "center",
    disablePadding: false,
    label: "Phone",
  },
  // {
  //   id: "userRole",
  //   align: "center",
  //   disablePadding: false,
  //   label: "User Role",
  // },
  {
    id: "regData",
    align: "center",
    disablePadding: false,
    label: "Sign-up Date",
  },
  {
    id: "lastActivity",
    align: "center",
    disablePadding: false,
    label: "Last Activity",
  },
  {
    id: "approvalStatus",
    align: "center",
    disablePadding: false,
    label: "User Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} padding="none">
            <Typography color="inherit" variant="subtitle1" component="div">
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
        <TableCell padding="default" align="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            Actions
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    setSearchVal,
    searchVal,
    isLoading,
    setPage,
    fetchUserList,
    rowsPerPage,
    setOpenDialog
  } = props;

  return (
    <>
      <Toolbar className="toolbar">
        <Typography variant="h6" id="tableTitle" component="div">
          Physician List
        </Typography>
        <Textbox
          name="search"
          placeholder="Search"
          value={searchVal}
          onChange={(e) => {
            if (e && e.target) {
              const value = e.target.value ? e.target.value : "";

              setSearchVal(value);
              if (!value || (value && value.trim().length > 2 && !isLoading)) {
                fetchUserList(0, value, rowsPerPage);
                setPage(0);
              }
            }
          }}
          additionalClass="searchResult"
          extendedInputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          text="Add"
          additionalClass="add-admin"
          onClick={() => {
            setOpenDialog(true);
          }}
        />
      </Toolbar>
    </>
  );
};

const PhysicianDashboard = (props) => {
  const languageData = getLanguageModule("dashboard");

  const dispatch = useDispatch();
  const pubnub = usePubNub();

  const isUserListLoading = useSelector((state) => {
    return state.dashboardReducer.isUserListLoading;
  });
  const isLoading = useSelector((state) => {
    return state.appReducer.isLoading && isUserListLoading;
  });
  const isUserStatusUpdated = useSelector((state) => {
    return state.dashboardReducer.isUserStatusUpdated;
  });
  const isUserStatusError = useSelector((state) => {
    return state.dashboardReducer.isUserStatusError;
  });
  const isAddPhysicianSuccess = useSelector((state) => {
    return state.dashboardReducer.isAddPhysicianSuccess || null;
  });
  const isRemoveAdminSuccess = useSelector((state) => {
    return state.dashboardReducer.isRemoveAdminSuccess || null;
  });
  const removeAdminError = useSelector((state) => {
    return state.dashboardReducer.removeAdminError || null;
  });
  const isRemoveAdminLoading = useSelector((state) => {
    return state.dashboardReducer.isRemoveAdminLoading || false;
  });

  const addPhysicianError = useSelector((state) => {
    return state.dashboardReducer.addPhysicianError || null;
  });

  const permissionsArray = sessionStorage.getItem("accessPermissions");
  if (!permissionsArray) {
    sessionStorage.setItem("accessPermissions", accessPermissions);
  }
  const allowedFeatures = checkPermissions(
    permissionsArray || accessPermissions
  );
  const accessPermissions = useSelector((state) => {
    return state.loginReducer.accessPermissions || [];
  });

  const isAddPhysicianLoading = useSelector((state) => {
    return state.dashboardReducer.isAddPhysicianLoading || false;
  });
  const rows = useSelector((state) => {
    return state.dashboardReducer.userList
      ? state.dashboardReducer.userList.data || []
      : [];
  });
  const userData = useSelector((state) => {
    return state.dashboardReducer.userList || null;
  });

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const [user, setUser] = useState({
    practice_name: "",
    practice_poc_name: "",
    phone: "",
    practice_type: "",
    base_url: "",
    email: "",

  });
  const [errors, setErrors] = useState({
    practice_name: "",
    practice_poc_name: "",
    practice_type: "",
    base_url: "",
    phone: "",
    email: "",

  });

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("childName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [approveAutomatically, setApproveAutomatically] = React.useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [openRows, setOpenRows] = React.useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    return array;
  }

  const practiceTypeArr = [
    { text: "OP", value: "OP" },
    { text: "PCC", value: "PCC" },
    { text: "EPIC", value: "EPIC" },
    { text: "Other", value: "Other" },
  ];

  const getDialogContent = () => {
    return (
      <div className="add-admin-dialog-container">
        {isAddPhysicianLoading && (
          <div className="backdrop-loader custom-backdrop">
            <CircularProgress color="inherit" />
          </div>
        )}
        <div>
          <Textbox
            name="practice_name"
            placeholder="Practice name"
            error={errors.practice_name}
            value={user.practice_name}
            onChange={(e) => onHandleChange(e)}
            additionalClass="physician-dialog-input"
          />
        </div>
        <div className="mt10">
          <Textbox
            name="practice_poc_name"
            placeholder="Practice POC name"
            error={errors.practice_poc_name}
            value={user.practice_poc_name}
            onChange={(e) => onHandleChange(e)}
            additionalClass="physician-dialog-input"
          />
        </div>
        <div className="mt10">
          <Dropdown
            name="practice_type"
            error={errors.practice_type}
            optionsArray={practiceTypeArr}
            additionalClass="physician-dialog-input"
            handleChange={onHandleChange}
            selectedValue={user.practice_type}
          />
        </div>
        <div className="mt10">
          <Textbox
            name="base_url"
            placeholder="Practice URL"
            error={errors.base_url}
            value={user.base_url}
            onChange={(e) => onHandleChange(e)}
            additionalClass="physician-dialog-input"
          />
        </div>
        <div className="mt10">
          <Textbox
            name="phone"
            placeholder="Phone No"
            error={errors.phone}
            value={user.phone}
            onChange={(e) => onHandleChange(e)}
            additionalClass="physician-dialog-input"
          />
        </div>
        <div className="mt10">
          <Textbox
            name="email"
            placeholder="Email"
            error={errors.email}
            value={user.email}
            onChange={(e) => onHandleChange(e)}
            additionalClass="physician-dialog-input"
          />
        </div>
      </div>
    );
  };
  const handleDeletePhysician = (event, identifier, userEmail) => {
    event.stopPropagation();
    const requestData = {
      identifier,
    };
    const userUDID = userEmail ? userEmail.replace(/\./g, "_") : "";
    if (userEmail) {
      pubnub.setUUID(userUDID);
    }
    pubnub.objects.getUUIDMetadata(
      {
        uuid: userUDID,
      },
      (status, response) => {
        console.log('response from getUUIDMetadata');
        console.log(response);
        if (status && status.statusCode === 200) {
          const userChannel = globalChannel + "." + userUDID;
          console.log('userChannel:');
          console.log(userChannel);
          pubnub.unsubscribe({
            channels: [userChannel],
          });
          pubnub.objects.removeUUIDMetadata();
          /*pubnub.history(
            {
          channel: userChannel,
        count: 100, // Limit of 100 messages.
            },
            (status, response) => {
          console.log('response from history');
        console.log(response);
        if (response) {
                const start = moment().add(2, 'y');
        console.log('Invoking deleteMessages');
        pubnub.deleteMessages({
          channel: userChannel,
        start: start,
        end: +moment(),
                }).then((result) => {
          console.log('response from deleteMessages');
        console.log(result);
        pubnub.objects.removeUUIDMetadata();
                });
              }
            });*/
        }
      }
    );
    dispatch(dashboardActions.resetRemoveAdmin());
    dispatch(dashboardActions.deleteAdmin(identifier));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const updateUserStatus = (event, identifier, status) => {
    event.stopPropagation();
    const requestData = {
      identifier,
      status,
    };
    dispatch(dashboardActions.resetUserStatusFlag());
    dispatch(dashboardActions.updateUserStatus(requestData));
  };

  const handleClick = (event, email) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const fetchUserList = (pageNo, searchTerm, perPage) => {
    const requestObject = {
      per_page: perPage,
      search: searchTerm ? searchTerm.trim() : "",
      type: "physician",
    };
    const queryParam = `page=${pageNo + 1}`;
    dispatch(dashboardActions.getUserList(requestObject, queryParam));
  };

  useEffect(() => {
    if (isAddPhysicianSuccess) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "success",
          "Physician added successfully"
        )
      );
      setOpenDialog(false);
      fetchUserList(page, searchVal, rowsPerPage);
      dispatch(dashboardActions.resetAddPhysician());
    }
    if (addPhysicianError) {
      dispatch(
        appActions.setDisplayNotification(true, "error", addPhysicianError.message)
      );
      dispatch(dashboardActions.resetAddPhysician());
    }
  }, [addPhysicianError, isAddPhysicianSuccess]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchUserList(newPage, searchVal, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = parseInt(event.target.value, 10);
    setRowsPerPage(perPage);
    setPage(0);
    fetchUserList(0, searchVal, perPage);
  };

  const isSelected = (email) => selected.indexOf(email) !== -1;

  const emptyRows = rowsPerPage - rows.length;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  useEffect(() => {
    fetchUserList(page, searchVal, rowsPerPage);
  }, []);

  useEffect(() => {
    if (isRemoveAdminSuccess) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "success",
          "Physician deleted successfully"
        )
      );
      fetchUserList(page, searchVal, rowsPerPage);
      dispatch(dashboardActions.resetRemoveAdmin());
    }
    if (removeAdminError) {
      dispatch(
        appActions.setDisplayNotification(true, "error", "Some error occured")
      );
      dispatch(dashboardActions.resetRemoveAdmin());
    }
  }, [isRemoveAdminSuccess, removeAdminError]);


  useEffect(() => {
    if (isUserStatusUpdated) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "success",
          "User status changed successfully"
        )
      );
      fetchUserList(page, searchVal, rowsPerPage);
      dispatch(dashboardActions.resetUserStatusFlag());
    }
    if (isUserStatusError) {
      dispatch(
        appActions.setDisplayNotification(true, "error", "Some error occured")
      );
      dispatch(dashboardActions.resetUserStatusFlag());
    }
  }, [isUserStatusUpdated, isUserStatusError]);

  const handleAddPhysician = () => {
    if (!isAddPhysicianLoading) {
      let requestObject = {
        first_name: user.practice_name.trim(),
        last_name: 'Practice',
        practice_poc_name: user.practice_poc_name.trim(),
        practice_type: user.practice_type.trim(),
        base_url: user.base_url.trim(),
        phone: user.phone.trim(),
        email: user.email.trim(),
        type: 'physician'
      };

      let requestObjectForValidation = {
        practice_name: user.practice_name.trim(),
        practice_poc_name: user.practice_poc_name.trim(),
        practice_type: user.practice_type.trim(),
        base_url: user.base_url.trim(),
        phone: user.phone.trim(),
        email: user.email.trim(),
        type: 'physician'
      };

      const errorObj = validateAllFields(requestObjectForValidation);
      console.log(errorObj);
      if (!errorObj.practice_name && !errorObj.practice_type && !errorObj.phone && !errorObj.email) {
        dispatch(dashboardActions.addNewPhysician(requestObject));
      } else {
        setErrors(errorObj);
      }
    }
  };

  const onHandleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log('name : ' + name)
    console.log('value : ' + value)
    let userData = { ...user };
    userData[name] = value;
    setUser(userData);
  };


  return (
    <div className="content-wrapper">
      <div className="admin-dashboard">
        {isLoading && (
          <div className="backdrop-loader custom-backdrop">
            <CircularProgress color="inherit" />
          </div>
        )}
        <Dialog
          additionalClass="add-physician-dialog"
          open={openDialog}
          handleClose={() => {
            setOpenDialog(false);
            setErrors({ email: "", practice_name: "", practice_poc_name: "" });
          }}
          dialogTitle="Add Physician"
          dialogContent={getDialogContent()}
          firstActionText="ADD"
          firstActionHandler={handleAddPhysician}
          closeIcon={true}
        />
        <div className="admin-container">
          <EnhancedTableToolbar
            numSelected={selected.length}
            setSearchVal={setSearchVal}
            searchVal={searchVal}
            isLoading={isLoading}
            setPage={setPage}
            fetchUserList={fetchUserList}
            rowsPerPage={rowsPerPage}
            setOpenDialog={setOpenDialog}

          />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                // orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows &&
                  rows.length > 0 &&
                  stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.email);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <>
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.email)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.email}
                            selected={isItemSelected}
                          >
                            <TableCell>
                              {row.patients && row.patients.length > 0 && (
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => {
                                    const updatedValue =
                                      openRows === row.email + index
                                        ? ""
                                        : row.email + index;
                                    setOpenRows(updatedValue);
                                  }}
                                >
                                  {openRows === row.email + index ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.email}
                            </TableCell>
                            <TableCell align="center">
                              {row.first_name || ""}
                            </TableCell>
                            <TableCell align="center">
                              {row.last_name || ""}
                            </TableCell>
                            <TableCell align="center">
                              {row.phone || ""}
                            </TableCell>
                            {/* <TableCell align="center">
                          {row.roles && row.roles.length > 0
                            ? row.roles.join(",")
                            : ""}
                        </TableCell> */}
                            <TableCell align="center">
                              {row.reg_date
                                ? moment(row.reg_date).format("MMM DD YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              {row.last_activity
                                ? moment(row.last_activity).format(
                                  "MMM DD YYYY"
                                )
                                : ""}
                            </TableCell>
                            <TableCell align="center" width="10%">
                              {getUserStatusText(row.status) || ""}
                            </TableCell>
                            <TableCell align="center">
                              {row.status &&
                                row.status.toLowerCase() === "active" && (
                                  <>
                                    <IconButton
                                      title="Disable user"
                                      aria-label="delete"
                                      onClick={(e) =>
                                        updateUserStatus(e, row.identifier, 0)
                                      }
                                    >
                                      <BlockIcon />
                                    </IconButton>
                                    {allowedFeatures.isManageAdmin && row.email !== NO_PHYSICIAN_EMAIL && (<IconButton
                                      aria-label="delete"
                                      title="Delete Physician"
                                      onClick={(e) => confirmAlert({
                                        title: 'Delete Physician',
                                        message: 'Are you sure you want to delete this physician : ' + row.first_name + ' ' + row.last_name + '?',
                                        buttons: [
                                          {
                                            label: 'Yes',
                                            className: 'pm-button',
                                            onClick: () => {
                                              handleDeletePhysician(e, row.identifier, row.email)
                                            }
                                          },
                                          {
                                            label: 'No',
                                            onClick: () => { }
                                          }
                                        ]
                                      })
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>)}</>
                                )}
                              {row.status &&
                                row.status.toLowerCase() !== "active" && (
                                  <>
                                    {row.status !== "EmailVerificationRequired" && (

                                      <IconButton
                                        aria-label="activate"
                                        title="Activate User"
                                        onClick={(e) =>
                                          updateUserStatus(e, row.identifier, 1)
                                        }
                                      >
                                        <DoneIcon />
                                      </IconButton>
                                    )}
                                    {allowedFeatures.isManageAdmin && row.email !== NO_PHYSICIAN_EMAIL && (<IconButton
                                      aria-label="delete"
                                      title="Delete Physician"
                                      onClick={(e) => confirmAlert({
                                        title: 'Delete Physician',
                                        message: 'Are you sure you want to delete this physician : ' + row.first_name + ' ' + row.last_name + '?',
                                        buttons: [
                                          {
                                            label: 'Yes',
                                            className: 'pm-button',
                                            onClick: () => {
                                              handleDeletePhysician(e, row.identifier, row.email)
                                            }
                                          },
                                          {
                                            label: 'No',
                                            onClick: () => { }
                                          }
                                        ]
                                      })
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>)}
                                  </>
                                )}
                            </TableCell>
                          </TableRow>
                          {row.patients && row.patients.length > 0 && (
                            <TableRow>
                              <TableCell
                                className={`${openRows === row.email + index
                                  ? "table-expanded"
                                  : "table-collapsed"
                                  }`}
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={9}
                              >
                                <Collapse
                                  in={openRows === row.email + index}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <div className="inner-table">
                                    <Table size="small">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Baby Name</TableCell>
                                          <TableCell>Gender</TableCell>
                                          <TableCell>Date of Birth</TableCell>
                                          <TableCell align="center">
                                            Ethnicity
                                          </TableCell>
                                          <TableCell>Relationship</TableCell>
                                          <TableCell>Parent</TableCell>
                                          <TableCell align="center">
                                            Previous Condition
                                          </TableCell>
                                          <TableCell>Referral Code</TableCell>

                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {row.patients.map((babyObject) => {
                                          return (
                                            <>
                                              <TableRow>
                                                <TableCell>
                                                  {babyObject.name || ""}
                                                </TableCell>
                                                <TableCell>
                                                  {babyObject.gender || ""}
                                                </TableCell>
                                                <TableCell>
                                                  {moment(
                                                    babyObject.date_of_birth
                                                  ).format("MMM DD YYYY")}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {babyObject.ethnicity || ""}
                                                </TableCell>
                                                <TableCell>
                                                  {babyObject.relation || ""}
                                                </TableCell>
                                                <TableCell>
                                                  {babyObject.parent || ""}
                                                </TableCell>
                                                <TableCell
                                                  align="center"
                                                  width="20%"
                                                >
                                                  {babyObject.previous_condition ||
                                                    ""}
                                                </TableCell>
                                                <TableCell>
                                                  {babyObject.referral_code || ""}
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          );
                                        })}
                                      </TableBody>
                                    </Table>
                                  </div>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      );
                    }
                  )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={10} className="empty-row">
                      {rows && rows.length < 1 && <span>No Result Found</span>}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={userData ? userData.total : rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PhysicianDashboard;
