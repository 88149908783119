import axiosInstance from "../PMCommon/PMApiHandler";
import apiEndPoints from "../PMCommon/PMApiEndPoints";
import actions from "./PMActions";

const initialState = {
  userActivityList: null,
  isUserActivityLoading: false,
  isUserActivitySuccess: false,
  userActivityError: null,
  logsList: null,
  isLogsLoading: false,
  isLogsSuccess: false,
  logsError: null,
  apiLogsList: null,
  isApiLogsLoading: false,
  isApiLogsSuccess: false,
  apiLogsError: null,
};

export const userActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.USER_ACTIVITY_RESET:
      return {
        ...state,
        userActivityList: null,
        isUserActivityLoading: false,
        isUserActivitySuccess: false,
        userActivityError: null,
      };
    case actions.USER_ACTIVITY_SUCCESS:
      return {
        ...state,
        isUserActivitySuccess: true,
        userActivityList:
          action.data && action.data.data ? action.data.data.results : null,
      };
    case actions.USER_ACTIVITY_ERROR:
      return {
        ...state,
        isUserActivitySuccess: false,
        userActivityError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.USER_ACTIVITY_LOADING:
      return {
        ...state,
        isUserActivityLoading: action.data,
      };
    case actions.LOGS_LOADING:
      return {
        ...state,
        isLogsLoading: action.data,
      };
    case actions.LOGS_SUCCESS:
      return {
        ...state,
        isLogsSuccess: true,
        logsList:
          action.data && action.data.data ? action.data.data.results : null,
      };
    case actions.LOGS_ERROR:
      return {
        ...state,
        isLogsSuccess: false,
        logsError: action.data && action.data.data ? action.data.data : null,
      };
    case actions.LOGS_RESET:
      return {
        ...state,
        logsList: null,
        isLogsLoading: false,
        isLogsSuccess: false,
        logsError: null,
      };
    case actions.API_LOGS_LOADING:
      return {
        ...state,
        isApiLogsLoading: action.data,
      };
    case actions.API_LOGS_SUCCESS:
      return {
        ...state,
        isApiLogsSuccess: true,
        apiLogsList:
          action.data && action.data.data ? action.data.data.results : null,
      };
    case actions.API_LOGS_ERROR:
      return {
        ...state,
        isApiLogsSuccess: false,
        apiLogsError: action.data && action.data.data ? action.data.data : null,
      };
    case actions.API_LOGS_RESET:
      return {
        ...state,
        apiLogsList: null,
        isApiLogsLoading: false,
        isApiLogsSuccess: false,
        apiLogsError: null,
      };
    default:
      return state;
  }
};

const getUserLoginActivity = (dataObj, queryParams = "") => {
  return (dispatch) => {
    dispatch({
      type: actions.USER_ACTIVITY_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.GET_USER_ACTIVITY + "?" + queryParams, dataObj)
      .then((data) => {
        dispatch({
          type: actions.USER_ACTIVITY_LOADING,
          data: false,
        });
        dispatch({
          type: actions.USER_ACTIVITY_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.USER_ACTIVITY_LOADING,
          data: false,
        });
        dispatch({
          type: actions.USER_ACTIVITY_ERROR,
          data: error,
        });
      });
  };
};

const resetUserActivity = () => {
  return (dispatch) => {
    dispatch({
      type: actions.USER_ACTIVITY_RESET,
    });
  };
};

const getLogs = (dataObj, queryParams = "") => {
  return (dispatch) => {
    dispatch({
      type: actions.LOGS_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.USER_LOGS + "?" + queryParams, dataObj)
      .then((data) => {
        dispatch({
          type: actions.LOGS_LOADING,
          data: false,
        });
        dispatch({
          type: actions.LOGS_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.LOGS_LOADING,
          data: false,
        });
        dispatch({
          type: actions.LOGS_ERROR,
          data: error,
        });
      });
  };
};

const resetLogs = () => {
  return (dispatch) => {
    dispatch({
      type: actions.LOGS_RESET,
    });
  };
};

const getApiLogs = (dataObj, queryParams = "") => {
  return (dispatch) => {
    dispatch({
      type: actions.API_LOGS_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.API_LOGS + "?" + queryParams, dataObj)
      .then((data) => {
        dispatch({
          type: actions.API_LOGS_LOADING,
          data: false,
        });
        dispatch({
          type: actions.API_LOGS_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.API_LOGS_LOADING,
          data: false,
        });
        dispatch({
          type: actions.API_LOGS_ERROR,
          data: error,
        });
      });
  };
};

const resetApiLogs = () => {
  return (dispatch) => {
    dispatch({
      type: actions.API_LOGS_RESET,
    });
  };
};

export const userActivityActions = {
  getUserLoginActivity,
  resetUserActivity,
  getLogs,
  resetLogs,
  getApiLogs,
  resetApiLogs
};
