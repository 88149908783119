import axiosInstance from "../PMCommon/PMApiHandler";
import apiEndPoints from "../PMCommon/PMApiEndPoints";
import actions from "./PMActions";

const initialState = {
  configurationList: null,
  configurationListError: null,
  isConfigurationListSuccess: false,
  isConfigurationListLoading: false,
  isConfigurationUpdateSuccess: false,
  isConfigurationUpdateError: false,
  configurationUpdateError: null,
};

export const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.RESET_CONFIGURATION:
      return {
        ...state,
        configurationList: null,
        configurationListError: null,
        isConfigurationListSuccess: false,
        isConfigurationListLoading: false,
        isConfigurationUpdateSuccess: false,
        isConfigurationUpdateError: false,
        configurationUpdateError: null,
      };
    case actions.RESET_CONFIGURATION_UPDATE:
      return {
        ...state,
        isConfigurationUpdateSuccess: false,
        isConfigurationUpdateError: false,
        configurationUpdateError: null,
      };
    case actions.CONFIGURATION_LIST_SUCCESS:
      return {
        ...state,
        isConfigurationListSuccess: true,
        configurationList:
          action.data && action.data.data ? action.data.data.results : null,
      };
    case actions.CONFIGURATION_LIST_ERROR:
      return {
        ...state,
        isConfigurationListSuccess: false,
        configurationListError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.CONFIGURATION_SAVE_SUCCESS:
      return {
        ...state,
        isConfigurationUpdateSuccess: true,
        isConfigurationUpdateError: false,
      };
    case actions.CONFIGURATION_SAVE_ERROR:
      return {
        ...state,
        isConfigurationUpdateSuccess: false,
        isConfigurationUpdateError: true,
        configurationUpdateError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.CONFIGURATION_LOADING:
      return {
        ...state,
        isConfigurationListLoading: action.data,
      };
    default:
      return state;
  }
};

const getConfigurationList = (dataObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.CONFIGURATION_LOADING,
      data: true,
    });
    axiosInstance
      .get(apiEndPoints.LIST_CONFIGURATION, dataObj)
      .then((data) => {
        dispatch({
          type: actions.CONFIGURATION_LOADING,
          data: false,
        });
        dispatch({
          type: actions.CONFIGURATION_LIST_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.CONFIGURATION_LOADING,
          data: false,
        });
        dispatch({
          type: actions.CONFIGURATION_LIST_ERROR,
          data: error,
        });
      });
  };
};

const saveConfiguration = (dataObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.CONFIGURATION_LOADING,
      data: true,
    });
    axiosInstance
      .post(apiEndPoints.UPDATE_CONFIGURATION, dataObj)
      .then((data) => {
        dispatch({
          type: actions.CONFIGURATION_LOADING,
          data: false,
        });
        dispatch({
          type: actions.CONFIGURATION_SAVE_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.CONFIGURATION_LOADING,
          data: false,
        });
        dispatch({
          type: actions.CONFIGURATION_SAVE_ERROR,
          data: error,
        });
      });
  };
};

const resetConfiguration = () => {
  return (dispatch) => {
    dispatch({
      type: actions.RESET_CONFIGURATION,
    });
  };
};

const resetConfigurationUpdate = () => {
  return (dispatch) => {
    dispatch({
      type: actions.RESET_CONFIGURATION_UPDATE,
    });
  };
};

export const configurationActions = {
  saveConfiguration,
  resetConfiguration,
  getConfigurationList,
  resetConfigurationUpdate,
};
