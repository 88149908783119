import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { checkPermissions } from "../../PMShared/PMCommon/PMUtility";

import getLanguageModule from "../../PMLocalisation/index";
import { dashboardActions } from "../../PMShared/PMRedux/PMDashboardActionReducer";
import { getUserStatusText } from "../../PMShared/PMCommon/PMUtility";
import "./PMDashboard.scss";
import moment from "moment";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";
import Textbox from "../../PMComponents/PMTextbox/PMTextbox";
import { InputAdornment, TextField } from "@material-ui/core";
import Dialog from "../../PMComponents/PMDialog/PMDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Button from "../../PMComponents/PMButton/PMButton";
import { usePubNub } from "pubnub-react";
const globalChannel = ["pediametrix-support"];


const headCells = [
  {
    id: "email",
    align: "left",
    disablePadding: true,
    label: "Email",
  },
  {
    id: "firstName",
    align: "center",
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lastName",
    align: "center",
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "phone",
    align: "center",
    disablePadding: false,
    label: "Phone",
  },
  // {
  //   id: "userRole",
  //   align: "center",
  //   disablePadding: false,
  //   label: "User Role",
  // },
  {
    id: "regData",
    align: "center",
    disablePadding: false,
    label: "Sign-up Date",
  },
  {
    id: "lastActivity",
    align: "center",
    disablePadding: false,
    label: "Last Activity",
  },
  {
    id: "approvalStatus",
    align: "center",
    disablePadding: false,
    label: "User Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} padding="none">
            <Typography color="inherit" variant="subtitle1" component="div">
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
        <TableCell padding="default" align="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            Actions
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    setSearchVal,
    searchVal,
    isLoading,
    setPage,
    fetchUserList,
    rowsPerPage,
  } = props;

  return (
    <>
      <Toolbar className="toolbar">
        <Typography variant="h6" id="tableTitle" component="div">
          Parent List
        </Typography>
        <Textbox
          name="search"
          placeholder="Search"
          value={searchVal}
          onChange={(e) => {
            if (e && e.target) {
              const value = e.target.value ? e.target.value : "";

              setSearchVal(value);
              if (!value || (value && value.trim().length > 2 && !isLoading)) {
                fetchUserList(0, value, rowsPerPage);
                setPage(0);
              }
            }
          }}
          additionalClass="searchResult"
          extendedInputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
    </>
  );
};

const ParentDashboard = (props) => {
  const languageData = getLanguageModule("dashboard");

  const dispatch = useDispatch();

  const pubnub = usePubNub();

  let pubNubListener = null;

  const isUserStatusUpdated = useSelector((state) => {
    return state.dashboardReducer.isUserStatusUpdated;
  });
  const isUserListLoading = useSelector((state) => {
    return state.dashboardReducer.isUserListLoading;
  });
  const isUserStatusError = useSelector((state) => {
    return state.dashboardReducer.isUserStatusError;
  });
  const isRemoveAdminSuccess = useSelector((state) => {
    return state.dashboardReducer.isRemoveAdminSuccess || null;
  });
  const removeAdminError = useSelector((state) => {
    return state.dashboardReducer.removeAdminError || null;
  });
  const isRemoveAdminLoading = useSelector((state) => {
    return state.dashboardReducer.isRemoveAdminLoading || false;
  });
  const permissionsArray = sessionStorage.getItem("accessPermissions");
  if (!permissionsArray) {
    sessionStorage.setItem("accessPermissions", accessPermissions);
  }
  const allowedFeatures = checkPermissions(
    permissionsArray || accessPermissions
  );
  const accessPermissions = useSelector((state) => {
    return state.loginReducer.accessPermissions || [];
  });


  const isLoading = useSelector((state) => {
    return state.appReducer.isLoading && isUserListLoading;
  });
  const rows = useSelector((state) => {
    return state.dashboardReducer.userList
      ? state.dashboardReducer.userList.data || []
      : [];
  });
  const userData = useSelector((state) => {
    return state.dashboardReducer.userList || null;
  });
  const physicianList = useSelector((state) => {
    return state.dashboardReducer.physicianListData
      ? state.dashboardReducer.physicianListData.results || []
      : [];
  });
  const isChangePhysicianLoading = useSelector((state) => {
    return state.dashboardReducer.isChangePhysicianLoading;
  });
  const isChangePhysicianSuccess = useSelector((state) => {
    return state.dashboardReducer.isChangePhysicianSuccess;
  });
  const changePhysicianError = useSelector((state) => {
    return state.dashboardReducer.changePhysicianError;
  });

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("childName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [approveAutomatically, setApproveAutomatically] = React.useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [openRows, setOpenRows] = React.useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBaby, setSelectedBaby] = useState(null);
  const [physicianName, setPhysicianName] = useState("");
  const [physicianData, setPhysician] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    return array;
    // const stabilizedThis = array.map((el, index) => [el, index]);
    // stabilizedThis.sort((a, b) => {
    //   const orderVal = comparator(a[0], b[0]);
    //   if (orderVal !== 0) return orderVal;
    //   return a[1] - b[1];
    // });
    // return stabilizedThis.map((el) => el[0]);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const updateUserStatus = (event, identifier, status) => {
    event.stopPropagation();
    const requestData = {
      identifier,
      status,
    };
    dispatch(dashboardActions.resetUserStatusFlag());
    dispatch(dashboardActions.updateUserStatus(requestData));
  };

  const handleClick = (event, email) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const fetchUserList = (pageNo, searchTerm, perPage) => {
    const requestObject = {
      per_page: perPage,
      search: searchTerm ? searchTerm.trim() : "",
      type: "parent",
    };
    const queryParam = `page=${pageNo + 1}`;
    dispatch(dashboardActions.getUserList(requestObject, queryParam));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchUserList(newPage, searchVal, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = parseInt(event.target.value, 10);
    setRowsPerPage(perPage);
    setPage(0);
    fetchUserList(0, searchVal, perPage);
  };

  const isSelected = (email) => selected.indexOf(email) !== -1;

  const emptyRows = rowsPerPage - rows.length;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevRowsPerPage = usePrevious(rowsPerPage);
  const prevCurrPage = usePrevious(page);
  const prevSearch = usePrevious(searchVal.trim());

  useEffect(() => {
    fetchUserList(page, searchVal, rowsPerPage);
  }, []);

  useEffect(() => {
    if (isRemoveAdminSuccess) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "success",
          "Parent deleted successfully"
        )
      );
      fetchUserList(page, searchVal, rowsPerPage);
      dispatch(dashboardActions.resetRemoveAdmin());
    }
    if (removeAdminError) {
      dispatch(
        appActions.setDisplayNotification(true, "error", "Some error occured")
      );
      dispatch(dashboardActions.resetRemoveAdmin());
    }
  }, [isRemoveAdminSuccess, removeAdminError]);

  useEffect(() => {
    if (isUserStatusUpdated) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "success",
          "User status changed successfully"
        )
      );
      fetchUserList(page, searchVal, rowsPerPage);
      dispatch(dashboardActions.resetUserStatusFlag());
    }
    if (isUserStatusError) {
      dispatch(
        appActions.setDisplayNotification(true, "error", "Some error occured")
      );
      dispatch(dashboardActions.resetUserStatusFlag());
    }
  }, [isUserStatusUpdated, isUserStatusError]);

  useEffect(() => {
    if (physicianName && physicianName.trim().length > 2) {
      dispatch(dashboardActions.getPhysicianList(physicianName.trim()));
    }
  }, [physicianName]);

  useEffect(() => {
    if (isChangePhysicianSuccess) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "success",
          "Physician changed successfully"
        )
      );
      setOpenDialog(false);
      setPhysicianName("");
      setPhysician(null);
      dispatch(dashboardActions.resetChangePhysician());
      fetchUserList(page, searchVal, rowsPerPage);
    }
  }, [isChangePhysicianSuccess]);

  const dialogTitle = (
    <>
      <div className="dialog-title">Change Child Physician</div>
    </>
  );
  const handleDeleteParent = (event, identifier, userEmail) => {
    event.stopPropagation();
    const requestData = {
      identifier,
    };
    const userUDID = userEmail ? userEmail.replace(/\./g, "_") : "";
    if (userEmail) {
      pubnub.setUUID(userUDID);
    }
    pubnub.objects.getUUIDMetadata(
      {
        uuid: userUDID,
      },
      (status, response) => {
        if (status && status.statusCode === 200) {
          const userChannel = globalChannel + "." + userUDID;
          pubnub.unsubscribe({
            channels: [userChannel],
          });
          pubnub.objects.removeUUIDMetadata();
          /* below ode is for explicitly deleting the message history from pubnub
          pubnub.history(
            {
              channel: userChannel,
              count: 100, // Limit of 100 messages.
            },
            (status, response) => {
              console.log('response from history');
              console.log(response);
              const msgs = response.messages.sort(function (a, b) {
                return parseInt(a.timetoken) - parseInt(b.timetoken);
              });
              const start = msgs[0].timetoken;
              const end = msgs[msgs.length - 1].timetoken;
              console.log('start Time : ', start);
              console.log('end Time : ', end);

              if (response) {
                const start = +moment().add(-2, 'y');
                //pubnub.time().then((timetoken) => {
                console.log('Invoking deleteMessages');
                //console.log('PubNub Current Time Token : ', timetoken);
                pubnub.deleteMessages({
                  channel: userChannel,
                  start: start,
                  end: end
                }).then((result) => {
                  console.log('response from deleteMessages')
                  console.log(result);
                  if (result) {
                    pubnub.unsubscribe({
                      channels: [userChannel],
                    });
                    pubnub.objects.removeUUIDMetadata();
                  }
                });
                //});
              }
            });*/
        }
      }
    );
    dispatch(dashboardActions.resetRemoveAdmin());
    dispatch(dashboardActions.deleteAdmin(identifier));
  };

  const getDialogContent = () => {
    return (
      <div className="change-physician-container">
        {isChangePhysicianLoading && (
          <div className="backdrop-loader custom-backdrop">
            <CircularProgress color="inherit" />
          </div>
        )}
        <div className="">
          Selected child:{" "}
          <span className="babyName">
            {selectedBaby ? selectedBaby.name || "" : ""}
          </span>
        </div>
        <Autocomplete
          freeSolo
          id="physician_identifier_search"
          name="physician_identifier"
          disableClearable
          className="physicianName"
          options={physicianList}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            setPhysician(newValue);
          }}
          value={physicianData}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                placeholder="Physician's Name"
                className="text-field"
                margin="normal"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                onChange={(e) => {
                  setPhysicianName(e.target.value);
                  if (!e.target.value) {
                    setPhysician(null);
                  }
                }}
              />
            );
          }}
        />
        {changePhysicianError && (
          <div className="error">{changePhysicianError.message || ""}</div>
        )}
      </div>
    );
  };

  const changeChildPhysician = () => {
    const requestObject = {
      identifier: selectedBaby.identifier,
      name: selectedBaby.name,
      date_of_birth: selectedBaby.date_of_birth,
      physician_identifier: physicianData.identifier,
    };
    dispatch(dashboardActions.updateChildPhysician(requestObject));
  };

  return (
    <div className="content-wrapper">
      <Dialog
        additionalClass="chat-dialog"
        open={openDialog}
        handleClose={() => {
          setPhysicianName("");
          setPhysician(null);
          dispatch(dashboardActions.resetChangePhysician());
          setOpenDialog(false);
        }}
        dialogTitle={dialogTitle}
        dialogContent={getDialogContent()}
        firstActionText="Change"
        firstActionHandler={(e) => {
          if (!isChangePhysicianLoading) {
            changeChildPhysician();
          }
        }}
        closeIcon={true}
      />
      <div className="admin-dashboard">
        {isLoading && (
          <div className="backdrop-loader custom-backdrop">
            <CircularProgress color="inherit" />
          </div>
        )}
        <div className="admin-container">
          <EnhancedTableToolbar
            numSelected={selected.length}
            setSearchVal={setSearchVal}
            searchVal={searchVal}
            isLoading={isLoading}
            setPage={setPage}
            fetchUserList={fetchUserList}
            rowsPerPage={rowsPerPage}
          />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                // orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows &&
                  rows.length > 0 &&
                  stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.email);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <>
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.email)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.email}
                          >
                            {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            className="themed-checkbox"
                          />
                        </TableCell> */}
                            <TableCell>
                              {row.babies && row.babies.length > 0 && (
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => {
                                    const updatedValue =
                                      openRows === row.email + index
                                        ? ""
                                        : row.email + index;
                                    setOpenRows(updatedValue);
                                  }}
                                >
                                  {openRows === row.email + index ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.email}
                            </TableCell>
                            <TableCell align="center">
                              {row.first_name || ""}
                            </TableCell>
                            <TableCell align="center">
                              {row.last_name || ""}
                            </TableCell>
                            <TableCell align="center">
                              {row.phone || ""}
                            </TableCell>
                            {/* <TableCell align="center">
                          {row.roles && row.roles.length > 0
                            ? row.roles.join(",")
                            : ""}
                        </TableCell> */}
                            <TableCell align="center">
                              {row.reg_date
                                ? moment(row.reg_date).format("MMM DD YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              {row.last_activity
                                ? moment(row.last_activity).format(
                                  "MMM DD YYYY"
                                )
                                : ""}
                            </TableCell>
                            <TableCell align="center" width="10%">
                              {getUserStatusText(row.status) || ""}
                            </TableCell>
                            <TableCell align="center">
                              {row.status &&
                                row.status.toLowerCase() === "active" && (
                                  <>
                                    <IconButton
                                      title="Disable user"
                                      aria-label="delete"
                                      onClick={(e) =>
                                        updateUserStatus(e, row.identifier, 0)
                                      }
                                    >
                                      <BlockIcon />
                                    </IconButton>
                                    {allowedFeatures.isManageAdmin && (<IconButton
                                      aria-label="delete"
                                      title="Delete Parent"
                                      onClick={(e) => confirmAlert({
                                        title: 'Delete Parent',
                                        message: 'Are you sure you want to delete this parent : ' + row.first_name + '?',
                                        buttons: [
                                          {
                                            label: 'Yes',
                                            className: 'pm-button',
                                            onClick: () => {
                                              handleDeleteParent(e, row.identifier, row.email)
                                            }
                                          },
                                          {
                                            label: 'No',
                                            onClick: () => { }
                                          }
                                        ]
                                      })
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>)}</>
                                )}
                              {row.status &&
                                row.status.toLowerCase() !== "active" && (
                                  <>
                                    {row.status !== "EmailVerificationRequired" && (

                                      <IconButton
                                        aria-label="activate"
                                        title="Activate User"
                                        onClick={(e) =>
                                          updateUserStatus(e, row.identifier, 1)
                                        }
                                      >
                                        <DoneIcon />
                                      </IconButton>
                                    )}
                                    {allowedFeatures.isManageAdmin && (
                                      <IconButton
                                        aria-label="delete"
                                        title="Delete Parent"
                                        onClick={(e) => confirmAlert({
                                          title: 'Delete Parent',
                                          message: 'Are you sure you want to delete this parent : ' + row.first_name + '?',
                                          buttons: [
                                            {
                                              label: 'Yes',
                                              className: 'pm-button',
                                              onClick: () => {
                                                handleDeleteParent(e, row.identifier, row.email)
                                              }
                                            },
                                            {
                                              label: 'No',
                                              onClick: () => { }
                                            }
                                          ]
                                        })
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>)}</>
                                )}
                            </TableCell>
                          </TableRow>
                          {row.babies && row.babies.length > 0 && (
                            <TableRow>
                              <TableCell
                                className={`${openRows === row.email + index
                                  ? "table-expanded"
                                  : "table-collapsed"
                                  }`}
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={9}
                              >
                                <Collapse
                                  in={openRows === row.email + index}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <div className="inner-table">
                                    <Table size="small">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Baby Name</TableCell>
                                          <TableCell>Gender</TableCell>
                                          <TableCell>Date of Birth</TableCell>
                                          <TableCell align="center">
                                            Ethnicity
                                          </TableCell>
                                          <TableCell>Relationship</TableCell>
                                          <TableCell>Physician</TableCell>
                                          <TableCell align="center">
                                            Previous Condition
                                          </TableCell>
                                          <TableCell>Referral Code</TableCell>
                                          <TableCell align="center">
                                            Action
                                          </TableCell>

                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {row.babies.map((babyObject) => {
                                          return (
                                            <>
                                              <TableRow>
                                                <TableCell>
                                                  {babyObject.name || ""}
                                                </TableCell>
                                                <TableCell>
                                                  {babyObject.gender || ""}
                                                </TableCell>
                                                <TableCell>
                                                  {moment(
                                                    babyObject.date_of_birth
                                                  ).format("MMM DD YYYY")}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {babyObject.ethnicity || ""}
                                                </TableCell>
                                                <TableCell>
                                                  {babyObject.relation || ""}
                                                </TableCell>
                                                <TableCell>
                                                  {babyObject.physician || ""}
                                                </TableCell>
                                                <TableCell
                                                  align="center"
                                                  width="20%"
                                                >
                                                  {babyObject.previous_condition ||
                                                    ""}
                                                </TableCell>
                                                <TableCell>
                                                  {babyObject.referral_code || ""}
                                                </TableCell>
                                                <TableCell>
                                                  <IconButton
                                                    aria-label="activate"
                                                    title="Change Physician"
                                                    onClick={(e) => {
                                                      setSelectedBaby(
                                                        babyObject
                                                      );
                                                      setOpenDialog(true);
                                                    }}
                                                  >
                                                    <AutorenewIcon />
                                                  </IconButton>
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          );
                                        })}
                                      </TableBody>
                                    </Table>
                                  </div>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      );
                    }
                  )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={10} className="empty-row">
                      {rows && rows.length < 1 && <span>No Result Found</span>}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={userData ? userData.total : rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ParentDashboard;
