import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";
import FilterListIcon from "@material-ui/icons/FilterList";
import Backdrop from "@material-ui/core/Backdrop";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";

import getLanguageModule from "../../PMLocalisation/index";
import {
  IMG_PMLOGIN,
  IMG_PMUSER,
  IMG_PMLOGO,
} from "../../PMConstants/PMImageConstants";
import Link from "../../PMComponents/PMLink/PMLink";
import { dashboardActions } from "../../PMShared/PMRedux/PMDashboardActionReducer";
import {
  getUserAccountStatus,
  getUserStatusText,
} from "../../PMShared/PMCommon/PMUtility";
import "./PMDashboard.scss";
import moment from "moment";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";
import Textbox from "../../PMComponents/PMTextbox/PMTextbox";
import { InputAdornment } from "@material-ui/core";

const headCells = [
  {
    id: "email",
    align: "left",
    disablePadding: true,
    label: "Email",
  },
  {
    id: "firstName",
    align: "center",
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lastName",
    align: "center",
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "phone",
    align: "center",
    disablePadding: false,
    label: "Phone",
  },
  // {
  //   id: "userRole",
  //   align: "center",
  //   disablePadding: false,
  //   label: "User Role",
  // },
  {
    id: "regData",
    align: "center",
    disablePadding: false,
    label: "Sign-up Date",
  },
  {
    id: "lastActivity",
    align: "center",
    disablePadding: false,
    label: "Last Activity",
  },
  {
    id: "approvalStatus",
    align: "center",
    disablePadding: false,
    label: "User Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} padding="none">
            <Typography color="inherit" variant="subtitle1" component="div">
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
        {/* <TableCell padding="default" align="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            Change Status
          </Typography>
        </TableCell> */}
        <TableCell padding="default" align="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            Change Role
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    setSearchVal,
    searchVal,
    isLoading,
    setPage,
    fetchUserList,
    rowsPerPage,
  } = props;

  return (
    <>
      <Toolbar className="toolbar">
        <Typography variant="h6" id="tableTitle" component="div">
          Other User List
        </Typography>
        <Textbox
          name="search"
          placeholder="Search"
          value={searchVal}
          onChange={(e) => {
            if (e && e.target) {
              const value = e.target.value ? e.target.value : "";

              setSearchVal(value);
              if (!value || (value && value.trim().length > 2 && !isLoading)) {
                fetchUserList(0, value, rowsPerPage);
                setPage(0);
              }
            }
          }}
          additionalClass="searchResult"
          extendedInputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
    </>
  );
};

const OtherDashboard = (props) => {
  const languageData = getLanguageModule("dashboard");
  const userRoles = [
    { text: "Parent", value: "parent" },
    { text: "Physician", value: "physician" },
    { text: "Staff", value: "physician_staff" },
  ];

  const dispatch = useDispatch();
  const isUserListLoading = useSelector((state) => {
    return state.dashboardReducer.isUserListLoading;
  });
  const isRoleChangeLoading = useSelector((state) => {
    return state.dashboardReducer.isRoleChangeLoading || false;
  });
  const isLoading = useSelector((state) => {
    return (
      state.appReducer.isLoading && (isUserListLoading || isRoleChangeLoading)
    );
  });
  const isUserStatusUpdated = useSelector((state) => {
    return state.dashboardReducer.isUserStatusUpdated;
  });
  const isUserStatusError = useSelector((state) => {
    return state.dashboardReducer.isUserStatusError;
  });
  const rows = useSelector((state) => {
    return state.dashboardReducer.userList
      ? state.dashboardReducer.userList.data || []
      : [];
  });
  const userData = useSelector((state) => {
    return state.dashboardReducer.userList || null;
  });
  const isRoleChangeSuccess = useSelector((state) => {
    return state.dashboardReducer.isRoleChangeSuccess || null;
  });
  const roleChangeError = useSelector((state) => {
    return state.dashboardReducer.roleChangeError || null;
  });

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("childName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [approveAutomatically, setApproveAutomatically] = React.useState(false);
  const [searchVal, setSearchVal] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    return array;
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const updateUserStatus = (event, identifier, status) => {
    event.stopPropagation();
    const requestData = {
      identifier,
      status,
    };
    dispatch(dashboardActions.resetUserStatusFlag());
    dispatch(dashboardActions.updateUserStatus(requestData));
  };

  const handleClick = (event, email) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const fetchUserList = (pageNo, searchTerm, perPage) => {
    const requestObject = {
      per_page: perPage,
      search: searchTerm ? searchTerm.trim() : "",
      type: "other",
    };
    const queryParam = `page=${pageNo + 1}`;
    dispatch(dashboardActions.getUserList(requestObject, queryParam));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchUserList(newPage, searchVal, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = parseInt(event.target.value, 10);
    setRowsPerPage(perPage);
    setPage(0);
    fetchUserList(0, searchVal, perPage);
  };

  const handleChangeRole = (e, identifier) => {
    const userType = e.target.value;
    if (!isRoleChangeLoading) {
      const requestObject = {
        identifier,
        role: userType,
      };
      dispatch(dashboardActions.changeUserRole(requestObject));
    }
  };

  const isSelected = (email) => selected.indexOf(email) !== -1;

  const emptyRows = rowsPerPage - rows.length;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  useEffect(() => {
    fetchUserList(page, searchVal, rowsPerPage);
  }, []);

  useEffect(() => {
    if (isUserStatusUpdated) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "success",
          "User status changed successfully"
        )
      );
      fetchUserList(page, searchVal, rowsPerPage);
      dispatch(dashboardActions.resetUserStatusFlag());
    }
    if (isUserStatusError) {
      dispatch(
        appActions.setDisplayNotification(true, "error", "Some error occured")
      );
      dispatch(dashboardActions.resetUserStatusFlag());
    }
  }, [isUserStatusUpdated, isUserStatusError]);

  useEffect(() => {
    if (isRoleChangeSuccess) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "success",
          "Role changed successfully"
        )
      );
      fetchUserList(page, searchVal, rowsPerPage);
      dispatch(dashboardActions.resetChangeRole());
    }
    if (roleChangeError) {
      dispatch(
        appActions.setDisplayNotification(true, "error", "Some error occured")
      );
      dispatch(dashboardActions.resetChangeRole());
    }
  }, [isRoleChangeSuccess, roleChangeError]);

  return (
    <div className="content-wrapper">
      <div className="admin-dashboard">
        {isLoading && (
          <div className="backdrop-loader custom-backdrop">
            <CircularProgress color="inherit" />
          </div>
        )}
        <div className="admin-container">
          <EnhancedTableToolbar
            numSelected={selected.length}
            setSearchVal={setSearchVal}
            searchVal={searchVal}
            isLoading={isLoading}
            setPage={setPage}
            fetchUserList={fetchUserList}
            rowsPerPage={rowsPerPage}
          />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                // orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows &&
                  rows.length > 0 &&
                  stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.email);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.email)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.email}
                          selected={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.email}
                          </TableCell>
                          <TableCell align="center">
                            {row.first_name || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row.last_name || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row.phone || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row.reg_date
                              ? moment(row.reg_date).format("MMM DD YYYY")
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row.last_activity
                              ? moment(row.last_activity).format("MMM DD YYYY")
                              : ""}
                          </TableCell>
                          <TableCell align="center" width="10%">
                            {getUserStatusText(row.status) || ""}
                          </TableCell>
                          {/* <TableCell align="center">
                            {row.status &&
                              row.status.toLowerCase() === "active" && (
                                <IconButton
                                  title="Disable user"
                                  aria-label="delete"
                                  onClick={(e) =>
                                    updateUserStatus(e, row.identifier, 0)
                                  }
                                >
                                  <BlockIcon />
                                </IconButton>
                              )}
                            {row.status &&
                              row.status.toLowerCase() !== "active" && (
                                <IconButton
                                  aria-label="activate"
                                  title="Activate User"
                                  onClick={(e) =>
                                    updateUserStatus(e, row.identifier, 1)
                                  }
                                >
                                  <DoneIcon />
                                </IconButton>
                              )}
                          </TableCell> */}
                          <TableCell align="center">
                            <Select
                              native
                              value=""
                              onChange={(e) =>
                                handleChangeRole(e, row.identifier)
                              }
                              label="Role"
                              inputProps={{
                                name: "age",
                                id: "outlined-age-native-simple",
                              }}
                              className="role-change-select"
                            >
                              <option aria-label="None" value="">
                                Other
                              </option>
                              {userRoles.map((obj) => {
                                return (
                                  <option value={obj.value}>{obj.text}</option>
                                );
                              })}
                            </Select>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={10} className="empty-row">
                      {rows && rows.length < 1 && <span>No Result Found</span>}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={userData ? userData.total : rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OtherDashboard;
