const IMG_PMLOGIN = require("../PMAssets/PMImages/pm-img-login.svg");
const IMG_PMLOCK = require("../PMAssets/PMImages/pm-lock.svg");
const IMG_PMUSER = require("../PMAssets/PMImages/pm-user.svg");
const IMG_PMLOGO = require("../PMAssets/PMImages/pm_softspot_logo.svg");
const IMG_PMFB = require("../PMAssets/PMImages/pm_facebook.svg");
const IMG_PMGOOGLE = require("../PMAssets/PMImages/pm_google.svg");
const IMG_BACK = require("../PMAssets/PMImages/pm_back.svg");
const IMG_PM_DOWN_ARROW_GREY = require("../PMAssets/PMImages/pm_down_arrow_grey.svg");
const IMG_PM_BELL = require("../PMAssets/PMImages/pm_bell.svg");
const IMG_PM_CLOSE = require("../PMAssets/PMImages/pm_close.svg");

export {
  IMG_PMLOGIN,
  IMG_PMLOCK,
  IMG_PMUSER,
  IMG_PMLOGO,
  IMG_PMFB,
  IMG_PMGOOGLE,
  IMG_PM_BELL,
  IMG_PM_DOWN_ARROW_GREY,
  IMG_BACK,
  IMG_PM_CLOSE,
};
