import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Textbox from "../../PMComponents/PMTextbox/PMTextbox";
import Button from "../../PMComponents/PMButton/PMButton";
import {
  validateField,
  validateAllFields,
  getPasswordHash,
} from "../../PMShared/PMCommon/PMUtility";
import {
  IMG_PMLOGIN,
  IMG_PMUSER,
  IMG_PMLOGO,
} from "../../PMConstants/PMImageConstants";
import Link from "../../PMComponents/PMLink/PMLink";
import getLanguageModule from "../../PMLocalisation/index";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";
import Notification from "../../PMComponents/PMNotification/PMNotification";
import Password from "../../PMComponents/PMPassword/PMPassword";

export const ResetPassword = (props) => {
  const languageData = getLanguageModule("resetPassword");
  const dispatch = useDispatch();

  const [user, setUser] = useState({ password: "", confirm_password: "" });
  const [errors, setErrors] = useState({ password: "", confirm_password: "" });
  const [showNotification, setShowNotification] = useState(false);

  const isLoading = useSelector((state) => {
    return state.appReducer.isLoading || false;
  });
  const isResetPasswordSuccess = useSelector((state) => {
    return state.appReducer.isResetPasswordSuccess;
  });
  const resetPasswordError = useSelector((state) => {
    return state.appReducer.resetPasswordError
      ? state.appReducer.resetPasswordError.message || ""
      : "";
  });
  const resetPasswordData = useSelector((state) => {
    return state.appReducer.resetPasswordData
      ? state.appReducer.resetPasswordData
      : null;
  });
  const handleHideNotification = () => {
    setShowNotification(false);
    dispatch(appActions.resetResetPassword());
    props.history.push("/");
  };

  const onHandleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let userData = { ...user };
    userData[name] = value;
    setUser(userData);
    // setErrors(validateField(name, errors, userData));
  };

  useEffect(() => {
    if (isResetPasswordSuccess) {
      setShowNotification(true);
    }
  }, [isResetPasswordSuccess]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (!isLoading) {
      const queryString = props.location.search || "";
      const queryStringArr = queryString.split("?");
      const queryParams = queryStringArr[1] || "";
      const tokenVal = queryParams.split("=");

      const errorObj = validateAllFields({ ...user });
      setErrors(errorObj);

      if (!errorObj.password && !errorObj.confirm_password) {
        const requestObject = {
          token: tokenVal[1] || "",
          password: getPasswordHash(user.password.trim()),
        };
        dispatch(appActions.handleResetPassword(requestObject));
      }
    }
  };

  return (
    <div className="page-container">
      <Notification
        show={showNotification}
        handleClose={handleHideNotification}
        additionalClass="notification-wrapper"
        variant="success"
        message={
          resetPasswordData
            ? resetPasswordData.message
            : "Password changed successfully"
        }
      />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div>
            <img className="header-logo" src={IMG_PMLOGO} title="" alt="" />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="text-center">
            <div className="login_img">
              <img src={IMG_PMLOGIN} title="" alt="" />
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="login-container">
            <div className="">
              <Grid item xs className="login_form">
                <div className="welcome_text">{languageData.WELCOME}</div>
                <div className="logo_text">SoftSpot</div>
              </Grid>
              <Grid item xs>
                <Backdrop open={isLoading} className="backdrop-loader">
                  <CircularProgress color="inherit" />
                </Backdrop>
                <form onSubmit={onSubmitHandler}>
                  <div className="text-center">
                    <div className="">
                      <Password
                        name="password"
                        error={errors.password}
                        value={user.password}
                        onChange={(e) => onHandleChange(e)}
                        additionalClass="login"
                        label="Password"
                      />
                    </div>
                    <div className="mt20">
                      <Password
                        name="confirm_password"
                        error={errors.confirm_password}
                        value={user.confirm_password}
                        onChange={(e) => onHandleChange(e)}
                        additionalClass="login"
                        label="Confirm Password"
                      />
                    </div>
                  </div>
                  <div className="login-button-container">
                    <Button
                      type="submit"
                      text="Reset Password"
                      name="login_submit"
                      onClick={onSubmitHandler}
                      additionalClass="signin"
                    />
                  </div>
                  <div className="error-container">
                    <div className="mb20">
                      {resetPasswordError && (
                        <span className="error">{resetPasswordError}</span>
                      )}
                    </div>
                    <div>
                      <span>{languageData.MOVE_TO_LOGIN} </span>
                      <Link label="Sign In" href="/" />
                    </div>
                  </div>
                </form>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPassword;
