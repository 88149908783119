import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import "./PMPassword.css";

const Password = ({
  name = "",
  placeholder = "",
  onChange = () => {},
  value = "",
  onBlur = () => {},
  additionalClass = "",
  error = "",
  icon = null,
  label = "Password",
}) => {
  return (
    <>
      <TextField
        name={name}
        type="password"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        className={additionalClass}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          startAdornment: icon ? (
            <InputAdornment position="start">
              <img src={icon} alt="" className="password_icon" />
            </InputAdornment>
          ) : null,
        }}
        variant="outlined"
        label={label}
        error={!!error}
        helperText={error}
      />
    </>
  );
};

export default Password;

Password.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  additionalClass: PropTypes.string,
};
