import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import "./PMFooter.scss";

function Footer() {
  return (
    <>
      <Grid item xs={12}>
        <div className="footer-wrapper">
          <div className="footer-nav">
            <Link to="/privacy-policy">Privacy Policies</Link>
            <Link to="/help" className="ml15 mr15">
              Get Help
            </Link>
            <Link to="/product-updates">Product Updates</Link>
          </div>
          <div>
            <span className="copyright-text">
              &#169; 2020 Pediametrix. All Rights Reserved
            </span>
          </div>
        </div>
      </Grid>
    </>
  );
}

export default Footer;
