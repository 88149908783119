import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Textbox from "../../PMComponents/PMTextbox/PMTextbox";
import Button from "../../PMComponents/PMButton/PMButton";
import {
  validateField,
  validateAllFields,
} from "../../PMShared/PMCommon/PMUtility";
import {
  IMG_PMLOGIN,
  IMG_PMUSER,
  IMG_PMLOGO,
} from "../../PMConstants/PMImageConstants";
import Link from "../../PMComponents/PMLink/PMLink";
import getLanguageModule from "../../PMLocalisation/index";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";
import Notification from "../../PMComponents/PMNotification/PMNotification";

export const ForgotPassword = (props) => {
  const languageData = getLanguageModule("forgotPassword");
  const dispatch = useDispatch();

  const [user, setUser] = useState({ email: "" });
  const [errors, setErrors] = useState({ email: "" });
  const [showNotification, setShowNotification] = useState(false);

  const isLoading = useSelector((state) => {
    return state.appReducer.isLoading || false;
  });
  const isForgotPasswordSuccess = useSelector((state) => {
    return state.appReducer.isForgotPasswordSuccess;
  });
  const forgotErrorMessage = useSelector((state) => {
    return state.appReducer.forgotPasswordError
      ? state.appReducer.forgotPasswordError.message || ""
      : "";
  });
  const handleHideNotification = () => {
    setShowNotification(false);
    dispatch(appActions.resetForgotPassword());
  };

  const onHandleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let userData = { ...user };
    userData[name] = value;
    setUser(userData);
    // setErrors(validateField(name, errors, userData));
  };

  useEffect(() => {
    if (isForgotPasswordSuccess) {
      setShowNotification(true);
    }
  }, [isForgotPasswordSuccess]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (!isLoading) {
      const requestObject = {
        email: user.email.trim(),
      };

      const errorObj = validateAllFields(requestObject);
      setErrors(errorObj);
      if (!errorObj.email) {
        dispatch(appActions.handleForgotPassword(requestObject));
      }
    }
  };

  return (
    <div className="page-container">
      <Notification
        show={showNotification}
        handleClose={handleHideNotification}
        additionalClass="notification-wrapper"
        variant="success"
        message="Reset password link successfully sent to your email address"
      />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div>
            <img className="header-logo" src={IMG_PMLOGO} title="" alt="" />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="text-center">
            <div className="login_img">
              <img src={IMG_PMLOGIN} title="" alt="" />
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="login-container">
            <div className="">
              <Grid item xs className="login_form">
                <div className="welcome_text">{languageData.WELCOME}</div>
                <div className="logo_text">SoftSpot</div>
              </Grid>
              <Grid item xs>
                <Backdrop open={isLoading} className="backdrop-loader">
                  <CircularProgress color="inherit" />
                </Backdrop>
                <form onSubmit={onSubmitHandler}>
                  <div className="text-center">
                    <div className="">
                      <Textbox
                        name="email"
                        label={languageData.EMAIL}
                        error={errors.email}
                        value={user.email}
                        onChange={(e) => onHandleChange(e)}
                        additionalClass="login"
                        icon={IMG_PMUSER}
                      />
                    </div>
                  </div>
                  <div className="login-button-container">
                    <Button
                      type="submit"
                      text="Get Reset Link"
                      name="login_submit"
                      onClick={onSubmitHandler}
                      additionalClass="signin"
                    />
                  </div>
                  <div className="error-container">
                    <div className="mb20">
                      {forgotErrorMessage && (
                        <span className="error">{forgotErrorMessage}</span>
                      )}
                    </div>
                    <div>
                      <span>{languageData.MOVE_TO_LOGIN} </span>
                      <Link label="Sign In" href="/" />
                    </div>
                  </div>
                </form>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
