import React, { useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { configurationActions } from "../../PMShared/PMRedux/PMConfigurationActionReducer";
import { useSelector, useDispatch } from "react-redux";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";
import { CircularProgress } from "@material-ui/core";

const Configuration = () => {
  const dispatch = useDispatch();

  const isConfigLoading = useSelector((state) => {
    return state.configurationReducer.isConfigurationListLoading;
  });
  const isLoading = useSelector((state) => {
    return state.appReducer.isLoading && isConfigLoading;
  });
  const configurationList = useSelector((state) => {
    return state.configurationReducer.configurationList;
  });
  const isConfigurationUpdateSuccess = useSelector((state) => {
    return state.configurationReducer.isConfigurationUpdateSuccess;
  });
  const isConfigurationUpdateError = useSelector((state) => {
    return state.configurationReducer.isConfigurationUpdateError;
  });
  const configurationUpdateError = useSelector((state) => {
    return state.configurationReducer.configurationUpdateError;
  });

  const saveConfiguration = (automatedApprovalData) => {
    const requestObject = {
      configuration: [automatedApprovalData],
    };
    dispatch(configurationActions.saveConfiguration(requestObject));
  };

  useEffect(() => {
    dispatch(configurationActions.resetConfiguration());
    dispatch(configurationActions.getConfigurationList());
  }, []);

  useEffect(() => {
    if (isConfigurationUpdateSuccess) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "success",
          "Configuration updated successfully"
        )
      );
      dispatch(configurationActions.resetConfigurationUpdate());
      dispatch(configurationActions.getConfigurationList());
    }
    if (isConfigurationUpdateError) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "error",
          configurationUpdateError.message ||
            "There was some error in updating the Configuration"
        )
      );
      dispatch(configurationActions.resetConfigurationUpdate());
    }
  }, [isConfigurationUpdateSuccess, isConfigurationUpdateError]);

  const getConfigurationLabel = (labelName) => {
    const labelVal = labelName ? labelName.toLowerCase() : "";
    return labelVal.replace(/_/g, " ") || "";
  };

  return (
    <div className="content-wrapper">
      <div className="admin-dashboard">
        {isLoading && (
          <div className="backdrop-loader custom-backdrop">
            <CircularProgress color="inherit" />
          </div>
        )}
        <div className="admin-container">
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            className="page-title"
          >
            Configurations
          </Typography>
          {configurationList &&
            configurationList.length > 0 &&
            configurationList.map((obj, index) => {
              return (
                <div>
                  <Typography
                    color="inherit"
                    variant="body1"
                    component="div"
                    className="pt20"
                  >
                    {obj.name}
                  </Typography>
                  {obj.configurations &&
                    obj.configurations.length > 0 &&
                    obj.configurations.map((config) => {
                      return (
                        <div className="config-section">
                          {
                            <>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      config.config_value === 1 ||
                                      config.config_value === "1"
                                    }
                                    onChange={() => {
                                      const automatedApprovalData = {
                                        name: "AUTOMATED_APPROVAL",
                                        value:
                                          config.config_value === 1 ||
                                          config.config_value === "1"
                                            ? "0"
                                            : "1",
                                      };
                                      saveConfiguration(automatedApprovalData);
                                    }}
                                  />
                                }
                                className="config-label"
                                label={getConfigurationLabel(
                                  config.config_name
                                )}
                              />
                              <Typography
                                color="inherit"
                                variant="body2"
                                component="div"
                              >
                                {config.config_description}
                              </Typography>
                            </>
                          }
                        </div>
                      );
                    })}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Configuration;
