import React from "react";
import Button from "@material-ui/core/Button";
import MatDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { IMG_PM_CLOSE } from "../../PMConstants/PMImageConstants";
import "./PMDialog.scss";

const Dialog = ({
  open = false,
  handleClose = () => {},
  dialogTitle = "",
  dialogContent = "",
  firstActionText = "",
  secondActionText = "",
  firstActionHandler = () => {},
  secondActionHandler = () => {},
  additionalClass = "",
  manualAction = null,
  firstActionIcon = null,
  preventDialogClose = false,
  closeIcon = null,
}) => {
  return (
    <div>
      <MatDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`dialog-wrapper ${additionalClass}`}
        disableBackdropClick={preventDialogClose}
        disableEscapeKeyDown={preventDialogClose}
      >
        <MuiDialogTitle disableTypography>
          <Typography variant="h6">{dialogTitle}</Typography>
          {closeIcon ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className="close-icon"
            >
              <img src={IMG_PM_CLOSE} alt="" />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        {/* <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle> */}
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          {manualAction}
          {secondActionText && (
            <Button
              onClick={secondActionHandler}
              color="primary"
              className="dialog-second-action"
            >
              {secondActionText}
            </Button>
          )}
          {firstActionText && (
            <div>
              <Button
                onClick={firstActionHandler}
                color="primary"
                className="dialog-first-action"
              >
                {firstActionText}
              </Button>
              {firstActionIcon}
            </div>
          )}
        </DialogActions>
      </MatDialog>
    </div>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  additionalClass: PropTypes.string,
  dialogTitle: PropTypes.string,
  dialogContent: PropTypes.string,
  firstActionText: PropTypes.string,
  secondActionText: PropTypes.string,
  firstActionHandler: PropTypes.func,
  secondActionHandler: PropTypes.func,
  manualAction: PropTypes.object,
  firstActionIcon: PropTypes.object,
  preventDialogClose: PropTypes.bool,
  closeIcon: PropTypes.object,
};

export default Dialog;
