import React, { useState } from "react";
import { Link } from "react-router-dom";

import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import "./PMSidebar.scss";
import { IconButton, Badge } from "@material-ui/core";
import TimelineIcon from "@material-ui/icons/Timeline";
import BarChartIcon from "@material-ui/icons/BarChart";
import ChartIcon from "@material-ui/icons/Contacts";
import IntegrationIcon from "@material-ui/icons/CloudUploadOutlined";

import ChatIcon from "@material-ui/icons/Chat";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { useSelector } from "react-redux";
import { checkPermissions } from "../../PMShared/PMCommon/PMUtility";
import CodeIcon from "@material-ui/icons/SubtitlesRounded";

function Sidebar(props) {
  const pathValue = props.location ? props.location.pathname || "" : "";
  const pathArr = pathValue.split("/");
  const pathName = pathArr.length > 0 ? pathArr[1] : "";
  const reviewAdmins = ['rezaseifabadi@gmail.com', 'can@pediametrix.com', 'swapna.asmi@gmail.com'];

  const [isToggle, setIsToggle] = useState(true);
  const [isToggleUsers, setIsToggleUsers] = useState(false);
  const [isToggleReports, setIsToggleReports] = useState(false);

  const newMessageCount = useSelector((state) => {
    return state.chatReducer.newMessageCount;
  });
  const accessPermissions = useSelector((state) => {
    return state.loginReducer.accessPermissions || [];
  });
  const permissionsArray = sessionStorage.getItem("accessPermissions");
  if (!permissionsArray) {
    sessionStorage.setItem("accessPermissions", accessPermissions);
  }
  const allowedFeatures = checkPermissions(
    permissionsArray || accessPermissions
  );
  const adminEmail = sessionStorage.getItem("userEmail");
  return (
    <nav id="sidebar" className={isToggle ? "active" : ""}>
      <div id="dismiss">
        <IconButton
          aria-label="toggle-menu"
          onClick={() => {
            setIsToggleUsers(false);
            setIsToggle(!isToggle);
          }}
          className="close-icon"
        >
          {isToggle ? <MenuIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <ul className="list-unstyled components">
        <li
          className={`${pathName === "users-parents" || pathName === "users-physicians"
            ? "active"
            : ""
            }`}
        >
          <Link>
            <PersonIcon />
            <label
              onClick={() => {
                setIsToggleUsers(!isToggleUsers);
              }}
            >
              <span>User Management</span>
              <ExpandMoreIcon className="expand-icon" />
            </label>
          </Link>
          <ul className={`sub-list ${isToggleUsers ? "active" : ""}`}>
            {allowedFeatures.isManageUsers && (
              <li className="">
                <Link
                  to="/users-parents"
                  className={`dropdown-toggle ${pathName === "users-parents" ? "active" : ""
                    }`}
                >
                  <label>Parents</label>
                </Link>
              </li>
            )}
            {allowedFeatures.isManageUsers && (
              <li className="">
                <Link
                  to="/users-physicians"
                  className={`dropdown-toggle ${pathName === "users-physicians" ? "active" : ""
                    }`}
                >
                  <label>Physician</label>
                </Link>
              </li>
            )}
            {allowedFeatures.isManageUsers && (
              <li className="">
                <Link
                  to="/users-others"
                  className={`dropdown-toggle ${pathName === "users-others" ? "active" : ""
                    }`}
                >
                  <label>Other users</label>
                </Link>
              </li>
            )}
            {allowedFeatures.isManageUsers && (
              <li className="">
                <Link
                  to="/users-staff"
                  className={`dropdown-toggle ${pathName === "users-staff" ? "active" : ""
                    }`}
                >
                  <label>Staff users</label>
                </Link>
              </li>
            )}
            {allowedFeatures.isManageAdmin && (
              <li className="">
                <Link
                  to="/admin-management"
                  className={`dropdown-toggle ${pathName === "admin-management" ? "active" : ""
                    }`}
                >
                  <label>Admin Management</label>
                </Link>
              </li>
            )}
          </ul>
        </li>
        {allowedFeatures.isManageConfigSettings && (
          <li>
            <Link
              className="dropdown-toggle"
              to="/configuration"
              className={`dropdown-toggle ${pathName === "configuration" ? "active" : ""
                }`}
            >
              <SettingsIcon />
              <label>Configurations</label>
            </Link>
          </li>
        )}
        {allowedFeatures.isManageUnsuccessfulLogin && (
          <li>
            <Link
              className={`dropdown-toggle ${pathName === "signing-attempts" ? "active" : ""
                }`}
              to="/signing-attempts"
            >
              <ReportProblemIcon />
              <label>Unsuccessful Signing Attempts</label>
            </Link>
          </li>
        )}
        {/*{allowedFeatures.isManageChat && (
          <li>
            <Link
              className={`dropdown-toggle ${pathName === "support-chat" ? "active" : ""
                }`}
              to="/support-chat"
            >
              <Badge
                badgeContent={
                  newMessageCount ? newMessageCount.totalMessageCount : 0
                }
                color="primary"
                className="chatCount"
              >
                <ChatIcon />
              </Badge>
              <label>Support Chat</label>
            </Link>
          </li>
              )}*/}
        {allowedFeatures.isManageActivityLogs && (
          <li>
            <Link
              className={`dropdown-toggle ${pathName === "user-activity" ? "active" : ""
                }`}
              to="/user-activity"
            >
              <TimelineIcon />
              <label>Transaction Logs</label>
            </Link>
          </li>
        )}
        {allowedFeatures.isManageActivityLogs && (
          <li>
            <Link
              className={`dropdown-toggle ${pathName === "api-logs" ? "active" : ""
                }`}
              to="/api-logs"
            >
              <IntegrationIcon />
              <label>API Logs</label>
            </Link>
          </li>
        )}
        <li
          className={`${pathName === "users-reports" || pathName === "debug-reports"
            ? "active"
            : ""
            }`}
        >
          <Link>
            <BarChartIcon />
            <label
              onClick={() => {
                setIsToggleReports(!isToggleReports);
              }}
            >
              <span>Review Reports</span>
              <ExpandMoreIcon className="expand-icon" />
            </label>
          </Link>
          <ul className={`sub-list ${isToggleReports ? "active" : ""}`}>

            {(allowedFeatures.isManageActivityLogs || reviewAdmins.includes(adminEmail)) && (
              <li>
                <Link
                  className={`dropdown-toggle ${pathName === "users-reports" ? "active" : ""
                    }`}
                  to="/users-reports"
                >
                  <label>Approve Reports</label>
                </Link>
              </li>
            )}
            {(allowedFeatures.isManageActivityLogs || reviewAdmins.includes(adminEmail)) && (
              <li>
                <Link
                  className={`dropdown-toggle ${pathName === "debug-reports" ? "active" : ""
                    }`}
                  to="/debug-reports"
                >
                  <label>Debug Reports</label>
                </Link>
              </li>
            )}
          </ul>
        </li>
        {(allowedFeatures.isManageUsers) && (
          <li>
            <Link
              className={`dropdown-toggle ${pathName === "marketing-info-reports" ? "active" : ""
                }`}
              to="/marketing-info-reports"
            >
              <ChartIcon />
              <label>Marketing Info</label>
            </Link>
          </li>

        )}
        {(allowedFeatures.isManageUsers) && (
          <li>
            <Link
              className={`dropdown-toggle ${pathName === "referal-code-dashboard" ? "active" : ""
                }`}
              to="/referal-code-dashboard"
            >
              <CodeIcon />
              <label>Referal Codes</label>
            </Link>
          </li>

        )}
      </ul>
    </nav>
  );
}

export default Sidebar;
