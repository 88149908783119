import React from "react";
import { Route, Redirect } from "react-router-dom";

const isLogin = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  return accessToken && true;
};

// restricted route = can't be visited if already logged in
const PublicRoute = ({ component: Component, restricted = false, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() && restricted ? (
          <Redirect to="/users-parents" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
