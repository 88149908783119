const actions = {
  GET_AUTH_TOKEN: "getAuthToken",
  AUTH_ERROR: "authError",
  USER_CREATED: "user_created",
  USER_CREATE_ERROR: "user_create_error",
  REDIRECT_USER: "redirect_user",
  SHOW_LOADER: "show_loader",
  HIDE_LOADER: "hide_loader",
  SIGNUP_USER: "signup",
  SIGNUP_ERROR: "signup_error",
  REGISTER_BABY: "register_baby",
  REGISTER_BABY_ERROR: "register_baby_error",
  LOGIN_SUCCESS: "login_success",
  LOGIN_ERROR: "login_error",
  RESET_LOGIN: "reset_login",
  PHYSICIAN_LIST: "get_physician_list",
  PHYSICIAN_LIST_ERROR: "physician_list_error",
  SET_DISCLAIMER: "set_disclaimer",
  SET_CHILD_INFO: "set_child_info",
  SET_CHILD_INFO_ERROR: "set_child_info_error",
  RESET_SIGNUP: "reset_signup",
  EMAIL_VERIFICATION_SUCCESS: "email_verification_success",
  EMAIL_VERIFICATION_ERROR: "email_verification_error",
  PHOTO_UPLOAD_SUCCESS: "photo_upload_success",
  PHOTO_UPLOAD_ERROR: "photo_upload_error",
  PREPARE_REPORT_SUCCESS: "prepare_report_success",
  DISPLAY_UPLOAD_DIALOG: "display_upload_dialog",
  FINALISE_REPORT_SUCCESS: "finalise_report_success",
  RESET_PHOTO_UPLOAD: "reset_pphoto_upload",
  USER_DETAILS_SUCCESS: "user_details_success",
  USER_DETAILS_ERROR: "user_details_error",
  DISPLAY_NOTIFICATION: "display_notification",
  REPORT_LIST_SUCCESS: "report_list_success",
  REPORT_LIST_ERROR: "report_list_error",
  RESET_DASHBOARD: "reset_dashboard",

  SIGNOUT_SUCCESS: "signout_success",
  SIGNOUT_ERROR: "signout_error",
  USER_LIST_SUCESS: "user_list_success",
  USER_LIST_ERROR: "user_list_error",
  UPDATE_USER_STATUS_SUCCESS: "update_user_status_success",
  UPDATE_USER_STATUS_ERROR: "update_user_status_error",
  RESET_REPORT_STATUS: "reset_report_status",
  UPDATE_REPORT_STATUS_SUCCESS: "update_report_status_success",
  UPDATE_REPORT_STATUS_ERROR: "update_report_status_error",
  RESET_USER_STATUS_FLAG: "reset_user_status",
  USER_LIST_LOADING: "user_list_loading",
  ADMIN_REPORT_LIST_LOADING: "admin_report_list_loading",
  DEBUG_REPORT_LIST_LOADING: "debug_report_list_loading",
  CONFIGURATION_LOADING: "configuration_loading",
  CONFIGURATION_SAVE_SUCCESS: "configuration_save_success",
  CONFIGURATION_SAVE_ERROR: "configuration_save_error",
  CONFIGURATION_LIST_SUCCESS: "configuration_list_success",
  CONFIGURATION_LIST_ERROR: "configuration_list_error",
  RESET_CONFIGURATION: "reset_configuration",
  RESET_CONFIGURATION_UPDATE: "reset_configuration_update",
  FORGOT_PASSWORD_SUCCESS: "forgot_password_success",
  FORGOT_PASSWORD_ERROR: "forgot_password_error",
  FORGOT_PASSWORD_RESET: "forgot_password_reset",
  RESET_PASSWORD_SUCCESS: "reset_password_success",
  RESET_PASSWORD_ERROR: "reset_password_error",
  RESET_PASSWORD_RESET: "reset_password_reset",
  USER_ACTIVITY_SUCCESS: "user_activity_success",
  USER_ACTIVITY_ERROR: "user_activity_error",
  USER_ACTIVITY_RESET: "user_activity_reset",
  USER_ACTIVITY_LOADING: "user_activity_loading",
  SET_NEW_MESSAGE_COUNT: "new_message_count",
  LOGS_SUCCESS: "logs_success",
  LOGS_ERROR: "logs_error",
  LOGS_RESET: "logs_reset",
  LOGS_LOADING: "logs_loading",
  API_LOGS_SUCCESS: "api_logs_success",
  API_LOGS_ERROR: "api_logs_error",
  API_LOGS_RESET: "api_logs_reset",
  API_LOGS_LOADING: "api_logs_loading",
  ADD_ADMIN_SUCCESS: "add_admin_success",
  ADD_ADMIN_ERROR: "add_admin_error",
  ADD_ADMIN_RESET: "add_admin_reset",
  ADD_ADMIN_LOADING: "add_admin_loading",
  ROLE_CHANGE_SUCCESS: "role_change_success",
  ROLE_CHANGE_ERROR: "role_change_error",
  ROLE_CHANGE_RESET: "role_change_reset",
  ROLE_CHANGE_LOADING: "role_change_loading",
  REMOVE_ADMIN_SUCCESS: "remove_admin_success",
  REMOVE_ADMIN_ERROR: "remove_admin_error",
  REMOVE_ADMIN_RESET: "remove_admin_reset",
  REMOVE_ADMIN_LOADING: "remove_admin_loading",
  VERIFY_SECURITY_CODE_SUCCESS: "verify_security_code_success",
  VERIFY_SECURITY_CODE_ERROR: "verify_security_code_error",
  VERIFY_SECURITY_CODE_RESET: "verify_security_code_reset",
  VERIFY_SECURITY_CODE_LOADING: "verify_security_code_loading",
  RESEND_SECURITY_CODE_SUCCESS: "resend_security_code_success",
  RESEND_SECURITY_CODE_ERROR: "resend_security_code_error",
  RESEND_SECURITY_CODE_RESET: "resend_security_code_reset",
  RESEND_SECURITY_CODE_LOADING: "resend_security_code_loading",
  CHANGE_PHYSICIAN_LOADING: "change_physician_loading",
  CHANGE_PHYSICIAN_ERROR: "change_physician_error",
  CHANGE_PHYSICIAN_SUCCESS: "change_physician_success",
  CHANGE_PHYSICIAN_RESET: "change_physician_reset",
  EMAIL_CHAT_MESSAGES_SUCCESS: "email_chat_messages_success",
  EMAIL_CHAT_MESSAGES_ERROR: "email_chat_messages_error",
  RESULT_DOWNLOAD_SUCCESS: "result_download_success",
  RESULT_DOWNLOAD_ERROR: "result_download_error",
  RESULT_DOWNLOAD_RESET: "result_download_reset",
  RESULT_DOWNLOAD_LOADING: "result_download_loading",
  MARKETING_INFO_LIST_LOADING: "marketing_info_list_loading",
  MARKETING_INFO_LIST_SUCCESS: "marketing_info_list_success",
  MARKETING_INFO_LIST_ERROR: "marketing_info_list_error",

  ADD_PHYSICIAN_SUCCESS: "add_physician_success",
  ADD_PHYSICIAN_ERROR: "add_physician_error",
  ADD_PHYSICIAN_RESET: "add_aphysician_reset",
  ADD_PHYSICIAN_LOADING: "add_physician_loading",
  ADD_REFERRAL_CODE_SUCCESS: "add_referral_code_success",
  ADD_REFERRAL_CODE_ERROR: "add_referral_code_error",
  ADD_REFERRAL_CODE_RESET: "add_referral_code_reset",
  ADD_REFERRAL_CODE_LOADING: "add_referral_code_loading",
  REFERRAL_CODE_LIST_LOADING: "referral_code_list_loading",
  REFERRAL_CODE_LIST_SUCCESS: "referral_code_list_success",
  REFERRAL_CODE_LIST_ERROR: "referral_code_list_error",

};

export default actions;
