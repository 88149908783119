import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";
import DownloadIcon from "@material-ui/icons/CloudDownload";

import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { checkPermissions } from "../../PMShared/PMCommon/PMUtility";

import getLanguageModule from "../../PMLocalisation/index";
import { dashboardActions } from "../../PMShared/PMRedux/PMDashboardActionReducer";
import { getUserStatusText } from "../../PMShared/PMCommon/PMUtility";
import "./PMDashboard.scss";
import moment from "moment";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";
import Textbox from "../../PMComponents/PMTextbox/PMTextbox";
import { InputAdornment, TextField } from "@material-ui/core";
import Dialog from "../../PMComponents/PMDialog/PMDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Button from "../../PMComponents/PMButton/PMButton";
import { usePubNub } from "pubnub-react";
import * as XLSX from "xlsx"
const globalChannel = ["pediametrix-support"];


const headCells = [
    {
        id: "referral_code",
        align: "left",
        disablePadding: true,
        label: "Referral Code",
    },
    {
        id: "practice_name",
        align: "center",
        disablePadding: false,
        label: "Practice Name",
    },
    {
        id: "status",
        align: "center",
        disablePadding: false,
        label: "Status",
    },
    {
        id: "date_shipped",
        align: "center",
        disablePadding: false,
        label: "Date Shipped",
    },
    {
        id: "date_used",
        align: "center",
        disablePadding: false,
        label: "Date Used",
    }
];



function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox"></TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align} padding="none">
                        <Typography color="inherit" variant="subtitle1" component="div">
                            {headCell.label}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const {
        numSelected,
        setSearchVal,
        searchVal,
        isLoading,
        setPage,
        fetchReportList,
        rowsPerPage,
    } = props;
    const hiddenFileInput = React.useRef(null);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const isAddReferralCodeSuccess = useSelector((state) => {
        return state.dashboardReducer.isAddReferralCodeSuccess || null;
    });
    const addReferralCodeError = useSelector((state) => {
        return state.dashboardReducer.addReferralCodeError || null;
    });
    const isAddReferralCodeLoading = useSelector((state) => {
        return state.dashboardReducer.isAddReferralCodeLoading || false;
    });

    const existingCodeList = useSelector((state) => {
        return state.dashboardReducer.existingCodeList || null;
    });

    useEffect(() => {
        if (isAddReferralCodeSuccess) {
            if (existingCodeList.message != 'The referral_codes added successfully.') {
                dispatch(
                    appActions.setDisplayNotification(
                        true,
                        "error",
                        "Records not added as the following codes already exists : " + existingCodeList.message
                    )
                );
            } else {
                dispatch(
                    appActions.setDisplayNotification(
                        true,
                        "success",
                        "Referal Codes added successfully"
                    )
                );
            }
            //setOpenDialog(false);
            dispatch(dashboardActions.resetAddReferralCode());
            fetchReportList(0, searchVal, rowsPerPage);
            setPage(0);
        }
        if (addReferralCodeError && addReferralCodeError != 'The referral_codes added successfully.') {
            dispatch(
                appActions.setDisplayNotification(true, "error", addReferralCodeError.message)
            );
            dispatch(dashboardActions.resetAddReferralCode());
        }
    }, [addReferralCodeError, isAddReferralCodeSuccess]);
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChange = event => {
        const reader = new FileReader();
        reader.readAsBinaryString(event.target.files[0]);
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet);
            setData(parsedData);
            if (!isAddReferralCodeLoading) {
                dispatch(dashboardActions.addReferralCodes(parsedData));
            }
        };
    }
    return (
        <>
            {isAddReferralCodeLoading && (
                <div className="backdrop-loader custom-backdrop">
                    <CircularProgress color="inherit" />
                </div>
            )}
            <Toolbar className="toolbar">
                <Typography variant="h6" id="tableTitle" component="div">
                    Referal Codes       </Typography>
                <Textbox
                    name="search"
                    placeholder="Search"
                    value={searchVal}
                    onChange={(e) => {
                        if (e && e.target) {
                            const value = e.target.value ? e.target.value : "";

                            setSearchVal(value);
                            if (!value || (value && value.trim().length > 2 && !isLoading)) {
                                fetchReportList(0, value, rowsPerPage);
                                setPage(0);
                            }
                        }
                    }}
                    additionalClass="searchResult"
                    extendedInputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button text="Add"
                    additionalClass="add-admin" onClick={handleClick}>
                    Upload a file
                </Button>
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }} />
            </Toolbar>
        </>
    );
};

const ReferalCodeDashboard = (props) => {
    const languageData = getLanguageModule("dashboard");

    const dispatch = useDispatch();

    const pubnub = usePubNub();

    let pubNubListener = null;

    const isReportStatusUpdated = useSelector((state) => {
        return state.dashboardReducer.isReportStatusUpdated;
    });
    const isUserListLoading = useSelector((state) => {
        return state.dashboardReducer.isUserListLoading;
    });
    const isReportStatusError = useSelector((state) => {
        return state.dashboardReducer.isReportStatusError;
    });
    const isRemoveAdminSuccess = useSelector((state) => {
        return state.dashboardReducer.isRemoveAdminSuccess || null;
    });
    const removeAdminError = useSelector((state) => {
        return state.dashboardReducer.removeAdminError || null;
    });
    const isRemoveAdminLoading = useSelector((state) => {
        return state.dashboardReducer.isRemoveAdminLoading || false;
    });
    const permissionsArray = sessionStorage.getItem("accessPermissions");
    if (!permissionsArray) {
        sessionStorage.setItem("accessPermissions", accessPermissions);
    }
    const allowedFeatures = checkPermissions(
        permissionsArray || accessPermissions
    );
    const accessPermissions = useSelector((state) => {
        return state.loginReducer.accessPermissions || [];
    });

    const isResultDownloadLoading = useSelector((state) => {
        return state.dashboardReducer.isResultDownloadLoading;
    });
    const resultDownloadError = useSelector((state) => {
        return state.dashboardReducer.resultDownloadError;
    });
    const isResultDownloaded = useSelector((state) => {
        return state.dashboardReducer.isResultDownloaded;
    });
    const resultDownloadFile = useSelector((state) => {
        return state.dashboardReducer.resultDownloadFile;
    });


    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const prevResultDownloadError = usePrevious(resultDownloadError);
    useEffect(() => {
        if (
            resultDownloadError &&
            resultDownloadError !== prevResultDownloadError
        ) {
            dispatch(
                appActions.setDisplayNotification(
                    true,
                    "error",
                    resultDownloadError.message
                )
            );
            dispatch(dashboardActions.resetResultDownloads());
        }
        if (isResultDownloaded) {
            const file = new Blob([resultDownloadFile], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);

            const link = document.createElement("a");
            link.href = fileURL;
            link.setAttribute("download", "Result.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();

            dispatch(
                appActions.setDisplayNotification(
                    true,
                    "success",
                    "Report downloaded successfully"
                )
            );
            setTimeout(() => {
                window.open(fileURL);
            }, 1000);
            dispatch(dashboardActions.resetResultDownloads());
        }
    }, [resultDownloadError, isResultDownloaded]);
    const downloadResultPDF = (reportIdentifier) => {
        dispatch(dashboardActions.downloadResult(reportIdentifier));
    };
    const isLoading = useSelector((state) => {
        return state.appReducer.isLoading && isUserListLoading;
    });
    const rows = useSelector((state) => {
        return state.dashboardReducer.referralCodeList
            ? state.dashboardReducer.referralCodeList.data || []
            : [];
    });
    const userData = useSelector((state) => {
        return state.dashboardReducer.referralCodeList || null;
    });
    const physicianList = useSelector((state) => {
        return state.dashboardReducer.physicianListData
            ? state.dashboardReducer.physicianListData.results || []
            : [];
    });
    const isChangePhysicianLoading = useSelector((state) => {
        return state.dashboardReducer.isChangePhysicianLoading;
    });
    const isChangePhysicianSuccess = useSelector((state) => {
        return state.dashboardReducer.isChangePhysicianSuccess;
    });
    const changePhysicianError = useSelector((state) => {
        return state.dashboardReducer.changePhysicianError;
    });

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("childName");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [approveAutomatically, setApproveAutomatically] = React.useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [openRows, setOpenRows] = React.useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedBaby, setSelectedBaby] = useState(null);
    const [physicianName, setPhysicianName] = useState("");
    const [physicianData, setPhysician] = useState(null);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };



    function stableSort(array, comparator) {
        return array;
        // const stabilizedThis = array.map((el, index) => [el, index]);
        // stabilizedThis.sort((a, b) => {
        //   const orderVal = comparator(a[0], b[0]);
        //   if (orderVal !== 0) return orderVal;
        //   return a[1] - b[1];
        // });
        // return stabilizedThis.map((el) => el[0]);
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.email);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const updateReportStatus = (event, identifier, status) => {
        //event.stopPropagation();
        const requestData = {
            identifier,
            status,
        };
        dispatch(dashboardActions.updateReportStatus(requestData));
    };

    const handleClick = (event, email) => {
        const selectedIndex = selected.indexOf(email);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, email);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const fetchReportList = (pageNo, searchTerm, perPage) => {
        const requestObject = {
            per_page: perPage,
            search: searchTerm ? searchTerm.trim() : "",
            type: "parent",
        };
        const queryParam = `page=${pageNo + 1}`;
        dispatch(dashboardActions.getReferralCodeList(requestObject, queryParam));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchReportList(newPage, searchVal, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const perPage = parseInt(event.target.value, 10);
        setRowsPerPage(perPage);
        setPage(0);
        fetchReportList(0, searchVal, perPage);
    };

    const isSelected = (email) => selected.indexOf(email) !== -1;

    const emptyRows = rowsPerPage - rows.length;

    const prevRowsPerPage = usePrevious(rowsPerPage);
    const prevCurrPage = usePrevious(page);
    const prevSearch = usePrevious(searchVal.trim());

    useEffect(() => {
        fetchReportList(page, searchVal, rowsPerPage);
    }, []);

    useEffect(() => {
        if (isRemoveAdminSuccess) {
            dispatch(
                appActions.setDisplayNotification(
                    true,
                    "success",
                    "Parent deleted successfully"
                )
            );
            fetchReportList(page, searchVal, rowsPerPage);
            dispatch(dashboardActions.resetRemoveAdmin());
        }
        if (removeAdminError) {
            dispatch(
                appActions.setDisplayNotification(true, "error", "Some error occured")
            );
            dispatch(dashboardActions.resetRemoveAdmin());
        }
    }, [isRemoveAdminSuccess, removeAdminError]);

    useEffect(() => {
        if (isReportStatusUpdated) {
            dispatch(
                appActions.setDisplayNotification(
                    true,
                    "success",
                    "Report status changed successfully"
                )
            );
            dispatch(dashboardActions.resetUpdateReportStatus(false));
            fetchReportList(page, searchVal, rowsPerPage);
        }
        if (isReportStatusError) {
            dispatch(
                appActions.setDisplayNotification(true, "error", "Some error occured")
            );
        }
    }, [isReportStatusUpdated, isReportStatusError]);

    useEffect(() => {
        if (physicianName && physicianName.trim().length > 2) {
            dispatch(dashboardActions.getPhysicianList(physicianName.trim()));
        }
    }, [physicianName]);

    useEffect(() => {
        if (isChangePhysicianSuccess) {
            dispatch(
                appActions.setDisplayNotification(
                    true,
                    "success",
                    "Physician changed successfully"
                )
            );
            setOpenDialog(false);
            setPhysicianName("");
            setPhysician(null);
            dispatch(dashboardActions.resetChangePhysician());
            fetchReportList(page, searchVal, rowsPerPage);
        }
    }, [isChangePhysicianSuccess]);

    const dialogTitle = (
        <>
            <div className="dialog-title">Change Child Physician</div>
        </>
    );
    const getDialogContent = () => {
        return (
            <div className="change-physician-container">
                {isChangePhysicianLoading && (
                    <div className="backdrop-loader custom-backdrop">
                        <CircularProgress color="inherit" />
                    </div>
                )}
                <div className="">
                    Selected child:{" "}
                    <span className="babyName">
                        {selectedBaby ? selectedBaby.name || "" : ""}
                    </span>
                </div>
                <Autocomplete
                    freeSolo
                    id="physician_identifier_search"
                    name="physician_identifier"
                    disableClearable
                    className="physicianName"
                    options={physicianList}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                        setPhysician(newValue);
                    }}
                    value={physicianData}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                placeholder="Physician's Name"
                                className="text-field"
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    type: "search",
                                }}
                                onChange={(e) => {
                                    setPhysicianName(e.target.value);
                                    if (!e.target.value) {
                                        setPhysician(null);
                                    }
                                }}
                            />
                        );
                    }}
                />
                {changePhysicianError && (
                    <div className="error">{changePhysicianError.message || ""}</div>
                )}
            </div>
        );
    };

    const changeChildPhysician = () => {
        const requestObject = {
            identifier: selectedBaby.identifier,
            name: selectedBaby.name,
            date_of_birth: selectedBaby.date_of_birth,
            physician_identifier: physicianData.identifier,
        };
        dispatch(dashboardActions.updateChildPhysician(requestObject));
    };

    return (
        <div className="content-wrapper">
            <Dialog
                additionalClass="chat-dialog"
                open={openDialog}
                handleClose={() => {
                    setPhysicianName("");
                    setPhysician(null);
                    dispatch(dashboardActions.resetChangePhysician());
                    setOpenDialog(false);
                }}
                dialogTitle={dialogTitle}
                dialogContent={getDialogContent()}
                firstActionText="Change"
                firstActionHandler={(e) => {
                    if (!isChangePhysicianLoading) {
                        changeChildPhysician();
                    }
                }}
                closeIcon={true}
            />
            <div className="admin-dashboard">
                {isLoading && (
                    <div className="backdrop-loader custom-backdrop">
                        <CircularProgress color="inherit" />
                    </div>
                )}
                <div className="admin-container">
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        setSearchVal={setSearchVal}
                        searchVal={searchVal}
                        isLoading={isLoading}
                        setPage={setPage}
                        fetchReportList={fetchReportList}
                        rowsPerPage={rowsPerPage}
                    />
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size="medium"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                // orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {rows &&
                                    rows.length > 0 &&
                                    stableSort(rows, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            const isItemSelected = isSelected(row.email);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <>
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => handleClick(event, row.email)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.email}
                                                    >
                                                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            className="themed-checkbox"
                          />
                        </TableCell> */}
                                                        <TableCell>
                                                            {row.babies && row.babies.length > 0 && (
                                                                <IconButton
                                                                    aria-label="expand row"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        const updatedValue =
                                                                            openRows === row.email + index
                                                                                ? ""
                                                                                : row.email + index;
                                                                        setOpenRows(updatedValue);
                                                                    }}
                                                                >
                                                                    {openRows === row.email + index ? (
                                                                        <KeyboardArrowUpIcon />
                                                                    ) : (
                                                                        <KeyboardArrowDownIcon />
                                                                    )}
                                                                </IconButton>
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {row.referral_code || ""}

                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.practice_name || ""}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.status || ""}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.date_shipped || ""}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.date_used || ""}
                                                        </TableCell>
                                                        {/*<TableCell align="center">
                              {row.status &&
                                row.status.toLowerCase() === "processed" && (
                                  <>

                                    <IconButton
                                      title="Approve Analysis"
                                      aria-label="delete"
                                      onClick={(e) =>
                                        updateReportStatus(e, row.identifier, 'Approved')
                                      }
                                    >
                                      <DoneIcon />
                                    </IconButton>
                                    <IconButton
                                      aria-label="delete"
                                      title="Reject Analysis"
                                      onClick={(e) => confirmAlert({
                                        title: 'Reject Analysis',
                                        message: 'Are you sure you want to reject this report analysis for the baby : ' + row.name + '?',
                                        buttons: [
                                          {
                                            label: 'Yes',
                                            className: 'pm-button',
                                            onClick: () => {
                                              updateReportStatus(e, row.identifier, 'Waiting for Process')
                                            }
                                          },
                                          {
                                            label: 'No',
                                            onClick: () => { }
                                          }
                                        ]
                                      })
                                      }
                                    >
                                      <BlockIcon />
                                    </IconButton>
                                    <IconButton
                                      title="Download Report"
                                      aria-label="download"
                                      onClick={(e) =>
                                        downloadResultPDF(row.identifier)
                                      }
                                    >
                                      <DownloadIcon />
                                    </IconButton></>
                                )}
                            </TableCell>*/}
                                                    </TableRow>
                                                    {row.babies && row.babies.length > 0 && (
                                                        <TableRow>
                                                            <TableCell
                                                                className={`${openRows === row.email + index
                                                                    ? "table-expanded"
                                                                    : "table-collapsed"
                                                                    }`}
                                                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                                                colSpan={9}
                                                            >
                                                                <Collapse
                                                                    in={openRows === row.email + index}
                                                                    timeout="auto"
                                                                    unmountOnExit
                                                                >
                                                                    <div className="inner-table">
                                                                        <Table size="small">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell>Baby Name</TableCell>
                                                                                    <TableCell>Gender</TableCell>
                                                                                    <TableCell>Date of Birth</TableCell>
                                                                                    <TableCell align="center">
                                                                                        Ethnicity
                                                                                    </TableCell>
                                                                                    <TableCell>Relationship</TableCell>
                                                                                    <TableCell>Physician</TableCell>
                                                                                    <TableCell align="center">
                                                                                        Previous Condition
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        Action
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {row.babies.map((babyObject) => {
                                                                                    return (
                                                                                        <>
                                                                                            <TableRow>
                                                                                                <TableCell>
                                                                                                    {babyObject.name || ""}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {babyObject.gender || ""}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {moment(
                                                                                                        babyObject.date_of_birth
                                                                                                    ).format("MMM DD YYYY")}
                                                                                                </TableCell>
                                                                                                <TableCell align="center">
                                                                                                    {babyObject.ethnicity || ""}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {babyObject.relation || ""}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {babyObject.physician || ""}
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    align="center"
                                                                                                    width="20%"
                                                                                                >
                                                                                                    {babyObject.previous_condition ||
                                                                                                        ""}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    <IconButton
                                                                                                        aria-label="activate"
                                                                                                        title="Change Physician"
                                                                                                        onClick={(e) => {
                                                                                                            setSelectedBaby(
                                                                                                                babyObject
                                                                                                            );
                                                                                                            setOpenDialog(true);
                                                                                                        }}
                                                                                                    >
                                                                                                        <AutorenewIcon />
                                                                                                    </IconButton>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </div>
                                                                </Collapse>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </>
                                            );
                                        }
                                    )}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={10} className="empty-row">
                                            {rows && rows.length < 1 && <span>No Result Found</span>}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {rows.length > 0 && (
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={userData ? userData.total : rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReferalCodeDashboard;
