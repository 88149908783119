import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { checkPermissions } from "../../PMShared/PMCommon/PMUtility";

import { dashboardActions } from "../../PMShared/PMRedux/PMDashboardActionReducer";
import {
  getUserStatusText,
  validateAllFields,
} from "../../PMShared/PMCommon/PMUtility";
import "./PMDashboard.scss";
import moment from "moment";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";
import Textbox from "../../PMComponents/PMTextbox/PMTextbox";
import { InputAdornment } from "@material-ui/core";
import Button from "../../PMComponents/PMButton/PMButton";
import Dialog from "../../PMComponents/PMDialog/PMDialog";

const headCells = [
  {
    id: "email",
    align: "left",
    disablePadding: true,
    label: "Email",
  },
  {
    id: "firstName",
    align: "center",
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lastName",
    align: "center",
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "regData",
    align: "center",
    disablePadding: false,
    label: "Registration Date",
  },
  {
    id: "approvalStatus",
    align: "center",
    disablePadding: false,
    label: "User Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            className="themed-checkbox"
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding="none"
          // sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
            <Typography color="inherit" variant="subtitle1" component="div">
              {headCell.label}
            </Typography>
            {/* {orderBy === headCell.id ? (
                <span className="hide">
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null} */}
            {/* </TableCell></TableSortLabel> */}
          </TableCell>
        ))}
        <TableCell padding="default" align="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            Actions
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    setSearchVal,
    searchVal,
    isLoading,
    setPage,
    fetchUserList,
    rowsPerPage,
    setOpenDialog,
  } = props;

  return (
    <>
      <Toolbar className="toolbar">
        <Typography variant="h6" id="tableTitle" component="div">
          Admin List
        </Typography>
        <div className="display-flex-standard">
          <Textbox
            name="search"
            placeholder="Search"
            value={searchVal}
            onChange={(e) => {
              if (e && e.target) {
                const value = e.target.value ? e.target.value : "";

                setSearchVal(value);
                if (
                  !value ||
                  (value && value.trim().length > 2 && !isLoading)
                ) {
                  fetchUserList(0, value, rowsPerPage);
                  setPage(0);
                }
              }
            }}
            additionalClass="searchResult"
            extendedInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            text="Add"
            additionalClass="add-admin"
            onClick={() => {
              setOpenDialog(true);
            }}
          />
        </div>
      </Toolbar>
    </>
  );
};

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const isUserListLoading = useSelector((state) => {
    return state.dashboardReducer.isUserListLoading;
  });
  const isLoading = useSelector((state) => {
    return state.appReducer.isLoading && isUserListLoading;
  });
  const rows = useSelector((state) => {
    return state.dashboardReducer.userList
      ? state.dashboardReducer.userList.data || []
      : [];
  });
  const userData = useSelector((state) => {
    return state.dashboardReducer.userList || null;
  });
  const isAddAdminSuccess = useSelector((state) => {
    return state.dashboardReducer.isAddAdminSuccess || null;
  });
  const addAdminError = useSelector((state) => {
    return state.dashboardReducer.addAdminError || null;
  });
  const isAddAdminLoading = useSelector((state) => {
    return state.dashboardReducer.isAddAdminLoading || false;
  });
  const isUserStatusUpdated = useSelector((state) => {
    return state.dashboardReducer.isUserStatusUpdated;
  });
  const isUserStatusError = useSelector((state) => {
    return state.dashboardReducer.isUserStatusError;
  });
  const isRemoveAdminSuccess = useSelector((state) => {
    return state.dashboardReducer.isRemoveAdminSuccess || null;
  });
  const removeAdminError = useSelector((state) => {
    return state.dashboardReducer.removeAdminError || null;
  });
  const isRemoveAdminLoading = useSelector((state) => {
    return state.dashboardReducer.isRemoveAdminLoading || false;
  });
  const permissionsArray = sessionStorage.getItem("accessPermissions");
  if (!permissionsArray) {
    sessionStorage.setItem("accessPermissions", accessPermissions);
  }
  const allowedFeatures = checkPermissions(
    permissionsArray || accessPermissions
  );
  const accessPermissions = useSelector((state) => {
    return state.loginReducer.accessPermissions || [];
  });

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("childName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [approveAutomatically, setApproveAutomatically] = React.useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    return array;
    // const stabilizedThis = array.map((el, index) => [el, index]);
    // stabilizedThis.sort((a, b) => {
    //   const orderVal = comparator(a[0], b[0]);
    //   if (orderVal !== 0) return orderVal;
    //   return a[1] - b[1];
    // });
    // return stabilizedThis.map((el) => el[0]);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const updateUserStatus = (event, identifier, status) => {
    event.stopPropagation();
    const requestData = {
      identifier,
      status,
    };
    dispatch(dashboardActions.resetUserStatusFlag());
    dispatch(dashboardActions.updateUserStatus(requestData));
  };

  const onHandleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let userData = { ...user };
    userData[name] = value;
    setUser(userData);
  };

  const handleDeleteAdmin = (event, identifier) => {
    event.stopPropagation();
    const requestData = {
      identifier,
    };
    dispatch(dashboardActions.resetRemoveAdmin());
    dispatch(dashboardActions.deleteAdmin(identifier));
  };

  const getDialogContent = () => {
    return (
      <div className="add-admin-dialog-container">
        {isAddAdminLoading && (
          <div className="backdrop-loader custom-backdrop">
            <CircularProgress color="inherit" />
          </div>
        )}
        <div>
          <Textbox
            name="first_name"
            placeholder="First name"
            error={errors.first_name}
            value={user.first_name}
            onChange={(e) => onHandleChange(e)}
            additionalClass="admin-dialog-input"
          />
        </div>
        <div className="mt10">
          <Textbox
            name="last_name"
            placeholder="Last name"
            error={errors.last_name}
            value={user.last_name}
            onChange={(e) => onHandleChange(e)}
            additionalClass="admin-dialog-input"
          />
        </div>
        <div className="mt10">
          <Textbox
            name="email"
            placeholder="Email"
            error={errors.email}
            value={user.email}
            onChange={(e) => onHandleChange(e)}
            additionalClass="admin-dialog-input"
          />
        </div>
      </div>
    );
  };

  const handleClick = (event, email) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const fetchUserList = (pageNo, searchTerm, perPage) => {
    const requestObject = {
      per_page: perPage,
      search: searchTerm ? searchTerm.trim() : "",
      type: "admin",
    };
    const queryParam = `page=${pageNo + 1}`;
    dispatch(dashboardActions.getUserList(requestObject, queryParam));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchUserList(newPage, searchVal, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = parseInt(event.target.value, 10);
    setRowsPerPage(perPage);
    setPage(0);
    fetchUserList(0, searchVal, perPage);
  };

  const isSelected = (email) => selected.indexOf(email) !== -1;

  const emptyRows = rowsPerPage - rows.length;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  useEffect(() => {
    fetchUserList(page, searchVal, rowsPerPage);
  }, []);

  useEffect(() => {
    if (isAddAdminSuccess) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "success",
          "Admin added successfully"
        )
      );
      setOpenDialog(false);
      fetchUserList(page, searchVal, rowsPerPage);
      dispatch(dashboardActions.resetAddAdmin());
    }
    if (addAdminError) {
      dispatch(
        appActions.setDisplayNotification(true, "error", addAdminError.message)
      );
      dispatch(dashboardActions.resetAddAdmin());
    }
  }, [addAdminError, isAddAdminSuccess]);

  useEffect(() => {
    if (isRemoveAdminSuccess) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "success",
          "Admin deleted successfully"
        )
      );
      fetchUserList(page, searchVal, rowsPerPage);
      dispatch(dashboardActions.resetRemoveAdmin());
    }
    if (removeAdminError) {
      dispatch(
        appActions.setDisplayNotification(true, "error", "Some error occured")
      );
      dispatch(dashboardActions.resetRemoveAdmin());
    }
  }, [isRemoveAdminSuccess, removeAdminError]);

  useEffect(() => {
    if (isUserStatusUpdated) {
      dispatch(
        appActions.setDisplayNotification(
          true,
          "success",
          "User status changed successfully"
        )
      );
      fetchUserList(page, searchVal, rowsPerPage);
      dispatch(dashboardActions.resetUserStatusFlag());
    }
    if (isUserStatusError) {
      dispatch(
        appActions.setDisplayNotification(true, "error", "Some error occured")
      );
      dispatch(dashboardActions.resetUserStatusFlag());
    }
  }, [isUserStatusUpdated, isUserStatusError]);

  const handleAddAdmin = () => {
    if (!isAddAdminLoading) {
      let requestObject = {
        email: user.email.trim(),
        first_name: user.first_name.trim(),
        last_name: user.last_name.trim(),
      };

      const errorObj = validateAllFields(requestObject);
      if (!errorObj.email && !errorObj.first_name && !errorObj.last_name) {
        dispatch(dashboardActions.addNewAdmin(requestObject));
      } else {
        setErrors(errorObj);
      }
    }
  };

  return (
    <div className="content-wrapper">
      <div className="admin-dashboard">
        {isLoading && (
          <div className="backdrop-loader custom-backdrop">
            <CircularProgress color="inherit" />
          </div>
        )}
        <Dialog
          additionalClass="add-admin-dialog"
          open={openDialog}
          handleClose={() => {
            setOpenDialog(false);
            setErrors({ email: "", first_name: "", last_name: "" });
          }}
          dialogTitle="Add Admin"
          dialogContent={getDialogContent()}
          firstActionText="ADD"
          firstActionHandler={handleAddAdmin}
          closeIcon={true}
        />
        <div className="admin-container">
          <EnhancedTableToolbar
            numSelected={selected.length}
            setSearchVal={setSearchVal}
            searchVal={searchVal}
            isLoading={isLoading}
            setPage={setPage}
            fetchUserList={fetchUserList}
            rowsPerPage={rowsPerPage}
            setOpenDialog={setOpenDialog}
          />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                // orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows &&
                  rows.length > 0 &&
                  stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.email);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.email)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.email}
                          selected={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.email}
                          </TableCell>
                          <TableCell align="center">
                            {row.first_name || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row.last_name || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row.reg_date
                              ? moment(row.reg_date).format("MMM DD YYYY")
                              : ""}
                          </TableCell>
                          <TableCell align="center" width="10%">
                            {getUserStatusText(row.status) || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row.status &&
                              row.status.toLowerCase() === "active" && (
                                <IconButton
                                  title="Disable user"
                                  aria-label="delete"
                                  onClick={(e) =>
                                    updateUserStatus(e, row.identifier, 0)
                                  }
                                >
                                  <BlockIcon />
                                </IconButton>
                              )}
                            {row.status &&
                              row.status.toLowerCase() !== "active" &&
                              row.status !== "EmailVerificationRequired" && (
                                <IconButton
                                  aria-label="activate"
                                  title="Activate User"
                                  onClick={(e) =>
                                    updateUserStatus(e, row.identifier, 1)
                                  }
                                >
                                  <DoneIcon />
                                </IconButton>
                              )}
                            <IconButton
                              aria-label="delete"
                              title="Delete Admin User"
                              onClick={(e) =>
                                handleDeleteAdmin(e, row.identifier)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={10} className="empty-row">
                      {rows && rows.length < 1 && <span>No Result Found</span>}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={userData ? userData.total : rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
