import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Textbox from "../../PMComponents/PMTextbox/PMTextbox";
import Password from "../../PMComponents/PMPassword/PMPassword";
import Button from "../../PMComponents/PMButton/PMButton";
import {
  validateField,
  validateAllFields,
  getUserAccountStatus,
  getPasswordHash,
} from "../../PMShared/PMCommon/PMUtility";
import {
  IMG_PMLOGIN,
  IMG_PMLOCK,
  IMG_PMUSER,
  IMG_PMLOGO,
  IMG_PMFB,
  IMG_PMGOOGLE,
  IMG_CONTINUE,
} from "../../PMConstants/PMImageConstants";
import Checkbox from "../../PMComponents/PMCheckbox/PMCheckbox";
import Link from "../../PMComponents/PMLink/PMLink";
import getLanguageModule from "../../PMLocalisation/index";
import { loginActions } from "../../PMShared/PMRedux/PMLoginActionReducer";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";

export const Login = (props) => {
  const languageData = getLanguageModule("login");

  const saveUserEmail = localStorage.getItem("userEmail");

  const [user, setUser] = useState({
    email: saveUserEmail || "",
    password: "",
  });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = useState(saveUserEmail ? true : false);
  const [isUnauthorised, setIsUnauthorised] = useState(false);

  const dispatch = useDispatch();
  const isLoginSuccess = useSelector((state) => {
    return state.loginReducer.isLoginSuccess;
  });
  const loginStatusCode = useSelector((state) => {
    return state.loginReducer.statusCode;
  });
  const accessToken = useSelector((state) => {
    return state.loginReducer.token;
  });
  const userType = useSelector((state) => {
    return state.loginReducer.userType;
  });
  const isFirstLogin = useSelector((state) => {
    return state.loginReducer.isFirstLogin;
  });
  const userStatus = useSelector((state) => {
    return state.loginReducer.userStatus;
  });
  const userEmail = useSelector((state) => {
    return state.loginReducer.userEmail;
  });
  const isLoading = useSelector((state) => {
    return state.appReducer.isLoading || false;
  });
  const loginErrorMessage = useSelector((state) => {
    return state.loginReducer.loginError
      ? state.loginReducer.loginError.message || ""
      : "";
  });
  const userData = useSelector((state) => {
    return state.loginReducer.loginData
      ? state.loginReducer.loginData.results
      : null;
  });

  const onHandleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let userData = { ...user };
    userData[name] = value;
    setUser(userData);
    // setErrors(validateField(name, errors, userData, true));
  };

  useEffect(() => {
    if (isLoginSuccess) {
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("userRole", userType);
      sessionStorage.setItem("isFirstLogin", isFirstLogin);
      sessionStorage.setItem("userStatus", userStatus);
      sessionStorage.setItem("userEmail", userEmail);

      if (rememberMe) {
        localStorage.setItem("userEmail", userEmail);
      } else {
        localStorage.setItem("userEmail", "");
      }

      if (userData) {
        sessionStorage.setItem("userName", userData.first_name || "");
      }
      if (userType === "admin") {
        props.history.push("/users-parents");
      } else {
        setIsUnauthorised(true);
        dispatch(appActions.signOut());
        dispatch(loginActions.resetLogin());
        sessionStorage.clear();
      }
    }
  }, [isLoginSuccess]);

  const onLoginHandler = (e) => {
    e.preventDefault();

    if (!isLoading) {
      let requestObject = {
        email: user.email.trim(),
        password: user.password.trim(),
      };

      const errorObj = validateAllFields(requestObject, true);
      if (!errorObj.email && !errorObj.password) {
        requestObject = {
          email: user.email.trim(),
          password: getPasswordHash(user.password.trim()),
        };
        dispatch(loginActions.getLoginToken(requestObject));
      } else {
        setErrors(errorObj);
      }
    }
  };

  return (
    <div className="page-container">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div>
            <img className="header-logo" src={IMG_PMLOGO} title="" alt="" />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="login-container">
            <div className="">
              <Grid item xs className="login_form">
                <div className="welcome_text">{languageData.WELCOME}</div>
                <div className="logo_text">SoftSpot</div>
              </Grid>
              <Grid item xs>
                <Backdrop open={isLoading} className="backdrop-loader">
                  <CircularProgress color="inherit" />
                </Backdrop>
                <form onSubmit={onLoginHandler}>
                  <div className="text-center">
                    <div className="">
                      <Textbox
                        name="email"
                        label={languageData.USERNAME}
                        error={errors.email}
                        value={user.email}
                        onChange={(e) => onHandleChange(e)}
                        additionalClass="login"
                        icon={IMG_PMUSER}
                      />
                    </div>
                    <div className=" mt20">
                      <Password
                        name="password"
                        label={languageData.PASSWORD}
                        error={errors.password}
                        value={user.password}
                        onChange={(e) => onHandleChange(e)}
                        additionalClass="login"
                        icon={IMG_PMLOCK}
                      />
                    </div>
                    <div className="forgot-container">
                      <div className="remember-div">
                        <Checkbox
                          name="rememberMe"
                          onChange={() => {
                            setRememberMe(!rememberMe);
                          }}
                          value={rememberMe}
                          label={languageData.REMEMBER_ME}
                          additionalClass="rememberMe"
                        />
                      </div>
                      <div className="forgot-div">
                        <Link label="Forgot password" href="/forgot-password" />
                      </div>
                    </div>
                    {/*{loginErrorMessage && (
                    <div className="error pt20 text-center">
                      {loginErrorMessage}
                    </div>
                  )}*/}
                    {loginErrorMessage && loginErrorMessage !== languageData.RESET_PASSWORD_MESSAGE && (
                      <div className="error message-container">
                        <p>{loginErrorMessage}</p>
                      </div>
                    )}
                    {loginErrorMessage && loginErrorMessage === languageData.RESET_PASSWORD_MESSAGE && (
                      <div className="error message-container">
                        <span>{languageData.PASSWORD_EXPIRED} </span>
                        <Link
                          label="Click here"
                          href="/forgot-password"
                        />
                        <span>{languageData.RESET_PASSWORD} </span>
                      </div>
                    )}
                  </div>

                  {isUnauthorised && (
                    <div className="error pt20 text-center">
                      You are not authorised to login
                    </div>
                  )}
                  <div className="login-button-container">
                    <Button
                      type="submit"
                      text="Login"
                      name="login_submit"
                      onClick={onLoginHandler}
                      additionalClass="signin"
                    />
                  </div>
                  <div className="social-signin-container">
                    <div>Or</div>
                    <IconButton aria-label="delete" className="facebook-login">
                      <img src={IMG_PMFB} alt="" />
                    </IconButton>
                    <IconButton aria-label="delete" className="google-login">
                      <img src={IMG_PMGOOGLE} alt="" />
                    </IconButton>
                  </div>
                  {loginStatusCode === 406 && (
                    <div className="signup-option-container">
                      {/* <span>{languageData.DONT_HAVE_ACCOUNT} </span> */}
                      <Link
                        label="Verify security code"
                        href="/verify-security-code"
                      />
                    </div>
                  )}
                </form>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
