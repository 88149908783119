import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";

const Dropdown = ({
  optionsArray = [],
  handleChange = () => {},
  additionalClass = "",
  label = "",
  selectedValue = "",
  error = "",
  name = "",
}) => {
  let options = optionsArray.map((obj) => {
    return (
      <MenuItem value={obj.value} className={additionalClass + "-item"}>
        {obj.text}
      </MenuItem>
    );
  });

  return (
    <FormControl className={additionalClass} variant="outlined" error={error}>
      <InputLabel htmlFor="demo-customized-select-native">{label}</InputLabel>
      <Select
        id="demo-customized-select-native"
        value={selectedValue}
        onChange={handleChange}
        name={name}
      >
        <MenuItem
          aria-label="None"
          value=""
          className={additionalClass + "-item"}
        >
          Select
        </MenuItem>
        {options}
      </Select>
      {error && <FormHelperText error="true">{error}</FormHelperText>}
    </FormControl>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  optionsArray: PropTypes.array,
  additionalClass: PropTypes.string,
  selectedValue: PropTypes.string,
  onChangeHandler: PropTypes.func,
};
