import CryptoJS from "crypto-js";
import messages from "./PMMessages";
import fieldValue from "./PMFieldName";

const validEmail = (email) => {
  const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
  return regex.test(String(email).toLowerCase());
};

const validMobileNumber = (phoneNumber) => {
  const regex = /^[0-9]{10}$/;
  return regex.test(Number(phoneNumber));
};

const validPassword = (pass) => {
  const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  //const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Za-z]).{8,}$/;
  return regex.test(String(pass));
};

const validateDOB = (dateOfBirth) => {
  const dateTimestamp = +new Date(dateOfBirth);
  let isValid = true;

  if (isNaN(dateTimestamp)) {
    isValid = false;
  } else {
    const currentDate = +new Date();
    if (dateTimestamp > currentDate) {
      isValid = false;
    }
  }
  return isValid;
};

// check is individual fields are valid
export const validateField = (
  fieldName,
  errorState,
  usersObj,
  isSkipValidation
) => {
  const err = Object.assign(errorState);
  const userObject = { ...usersObj };

  let isFieldValid = userObject[fieldName]
    ? userObject[fieldName].trim().length > 0
    : false;

  switch (fieldName) {
    case fieldValue.FIRSTNAME:
    case fieldValue.LASTNAME:
    case fieldValue.DOCTOR_NAME:
    case fieldValue.CHILD_NAME:
    case fieldValue.RELATIONSHIP:
    case fieldValue.PHYSICIAN_ID:
      err[fieldName] = isFieldValid ? "" : messages(fieldName).required;
      break;
    case fieldValue.SECURITY_CODE:
      err[fieldName] = isFieldValid ? "" : messages(fieldName).required;
      if (!err[fieldName]) {
        err[fieldName] =
          userObject[fieldName].trim().length === 6
            ? ""
            : messages(fieldName).invalid;
      }
      break;
    case fieldValue.CHILD_DOB:
      err[fieldName] = isFieldValid ? "" : messages(fieldName).required;
      if (!err[fieldName]) {
        err[fieldName] = validateDOB(userObject[fieldName])
          ? ""
          : messages(fieldName).invalid;
      }
      break;
    case fieldValue.MOBILE_NUMBER:
      err[fieldName] = isFieldValid ? "" : messages(fieldName).required;
      if (!err[fieldName]) {
        err[fieldName] = validMobileNumber(userObject[fieldName])
          ? ""
          : messages(fieldName).invalid;
      }
      break;
    case fieldValue.PRACTICE_NAME:
      err[fieldName] = isFieldValid ? "" : messages(fieldName).required;
      break;
    case fieldValue.PRACTICE_TYPE:
      err[fieldName] = isFieldValid ? "" : messages(fieldName).required;
      break;
    case fieldValue.PHONE:
      err[fieldName] = isFieldValid ? "" : messages(fieldName).required;
      break;
    case fieldValue.EMAIL:
      err[fieldName] = isFieldValid ? "" : messages(fieldName).required;
      if (!err[fieldName]) {
        err[fieldName] = validEmail(userObject[fieldName])
          ? ""
          : messages(fieldName).invalid;
      }
      break;
    case fieldValue.PASSWORD:
      err[fieldName] = isFieldValid ? "" : messages(fieldName).required;
      if (!err[fieldName] && !isSkipValidation) {
        err[fieldName] = validPassword(userObject[fieldName])
          ? ""
          : messages(fieldName).invalid;
      }
      break;
    case fieldValue.CONFIRM_PASSWORD:
      err[fieldName] = isFieldValid ? "" : messages(fieldName).required;
      // if (!err[fieldName]) {
      //   err[fieldName] = validPassword(userObject[fieldName])
      //     ? ""
      //     : messages(fieldName).invalid;
      // }
      if (!err[fieldName]) {
        isFieldValid = userObject.confirm_password === userObject.password;
        err[fieldName] = isFieldValid ? "" : messages(fieldName).match;
      }
      break;
    default:
      break;
  }
  return err;
};

export const validateAllFields = (usersObj, isSkipValidation) => {
  let fieldArr = [];
  let errorObj = {};

  fieldArr = Object.keys(usersObj);

  fieldArr.forEach((value, index) => {
    const fieldErrorObj = validateField(
      value,
      errorObj,
      usersObj,
      isSkipValidation
    );
    errorObj = {
      ...errorObj,
      ...fieldErrorObj,
    };
  });
  return errorObj;
};

export const getUserAccountStatus = () => {
  return {
    ADD_INFO_REQUIRED: "AdditionalInformationRequired",
    WAITING_APPROVAL: "WaitingApproval",
    EMAIL_VERIFICATION_REQUIRED: "EmailVerificationRequired",
    ACTIVE: "Active",
    BLOCKED: "Blocked",
  };
};

export const getUserStatusText = (userStatus) => {
  const userStatusObject = {
    AdditionalInformationRequired: "Additional Information Required",
    WaitingApproval: "Waiting Approval",
    EmailVerificationRequired: "Email Verification Required",
    Active: "Active",
    Blocked: "Blocked",
  };
  return userStatusObject[userStatus];
};

export const getPasswordHash = (passwordVal) => {
  const hashVal = CryptoJS.MD5(passwordVal);

  return hashVal ? hashVal.toString() : "";
};

export const createLink = (textContent) => {
  let replacePattern1 = "",
    replacePattern2 = "";
  let replacedTextFirst = "",
    replacedText = "";

  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedTextFirst = textContent.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );

  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedTextFirst.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  return replacedText;
};

export const checkPermissions = (permissions) => {
  const permissionsMap = {
    "manage-admins": "isManageAdmin",
    "manage-users": "isManageUsers",
    "manage-activity-logs": "isManageActivityLogs",
    "manage-unsuccessful-login": "isManageUnsuccessfulLogin",
    "change-config-settings": "isManageConfigSettings",
    chat: "isManageChat",
  };
  const allowedFeatures = {
    isManageAdmin: false,
    isManageUsers: false,
    isManageActivityLogs: false,
    isManageUnsuccessfulLogin: false,
    isManageConfigSettings: false,
    isManageChat: false,
  };

  if (permissions && permissions.length) {
    const permissionsArray = Object.keys(permissionsMap);
    permissionsArray.forEach((permissionName) => {
      if (permissions.indexOf(permissionName) > -1) {
        const feature = permissionsMap[permissionName];
        allowedFeatures[feature] = true;
      }
    });
  }

  return allowedFeatures;
};
