import React from "react";
import PropTypes from "prop-types";
import MatLink from "@material-ui/core/Link";

const Link = ({
  onClickHandler,
  label = "",
  href = "",
  additionalClass = "",
}) => {
  return (
    <MatLink href={href} onClick={onClickHandler} className={additionalClass}>
      {label}
    </MatLink>
  );
};

export default Link;

Link.propTypes = {
  href: PropTypes.string,
  additionalClass: PropTypes.string,
  label: PropTypes.string,
  onClickHandler: PropTypes.func,
};
