import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { store } from "./PMShared/PMRedux/PMStore";

import "./base.scss";
import "./App.scss";
import Routes from "./PMRoutes/Routes";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#e7792b",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //   light: "#0066ff",
      main: "#e7792b",
      //   // dark: will be calculated from palette.secondary.main,
      //   contrastText: "#ffcc00",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  autoNetworkDetection: true,
  restore: true,
  uuid: "support_agent",
});

function App() {
  return (
    <PubNubProvider client={pubnub}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </Provider>
    </PubNubProvider>
  );
}

export default App;
