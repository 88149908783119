import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import Footer from "../PMComponents/PMFooter/PMFooter";
import Header from "../PMComponents/PMHeader/PMHeader";
import Sidebar from "../PMComponents/PMSidebar/PMSidebar";
import { useSelector } from "react-redux";
import SupportChat from "../PMScreens/PMSupportChat/PMSupportChat";

const isLogin = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  return accessToken && true;
};

const PrivateRoute = ({
  component: Component,
  isHeaderRequired = false,
  isFooterRequired = false,
  isSidebarRequired = false,
  isInitialised = false,
  ...rest
}) => {
  const accessToken = useSelector((state) => {
    return state.loginReducer.token;
  });

  useEffect(() => {
    console.log("changed");
  }, [accessToken]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLogin()) {
          return isHeaderRequired ? (
            <>
              <Header {...props} />
              {isSidebarRequired ? <Sidebar {...props} /> : null}
              <div className="body-wrapper">
                {<Component {...props} isInitialised={isInitialised} />}
                {!isInitialised && (
                  <SupportChat isInitialised={isInitialised} />
                )}
              </div>
            </>
          ) : (
            <Component {...props} />
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

export default PrivateRoute;
