import React from "react";
import PropTypes from "prop-types";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const Notification = ({
  show,
  handleClose = () => {},
  additionalClass = "",
  variant = "success",
  message = "",
}) => {
  return (
    <Snackbar
      open={show}
      autoHideDuration={6000}
      onClose={handleClose}
      className={additionalClass}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
    >
      <MuiAlert
        elevation={6}
        variant="standard"
        severity={variant}
        onClose={handleClose}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Notification;

Notification.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  additionalClass: PropTypes.string,
  variant: PropTypes.string,
  message: PropTypes.string,
};
