import React from "react";

export const templateArray = [
  "Dear Parent, your account is approved. Please login and let us know if you have any questions.",
  "Dear parent, this is a reminder for you to login to SoftSpot and upload photos/video; your baby's cranial evaluation results will be available to your physician within 3 days",
  "Dear Parent, Your child's cranial evaluation report is ready and was sent to your physician. We have notified your physician to review the results; if you do not hear from them, please contact your physician to discuss the results and next steps.",
  "Thanks for uploading pictures/video. However, we need new photos/video according to the instructions provided to you on the website/app.\n\
1. Please make sure the tip of the nose is appearing in the picture.\n\
2. Please make sure the hair is not covering the edges of the head in the picture.\n\
3. Please use a stocking cap, can be purchased here: https://www.amazon.com/dp/B06XSWD4B3/ref=cm_sw_em_r_mt_dp_U_-uOZEbK8KE274",
  "Thanks for uploading photos/video. We are processing the data and within 3 days, we will make the report available in your child's physician account.",
  "Since your physician is not on our platform, we will assign you to our online physical therapy consultant. You will need to schedule a 10-minute appointment with her to review the numbers and next steps.",
  "Thanks for signing up! May I ask what makes you interested in using SoftSpot?",
  "New patient result is available. Please login to review the results.",
  "You child's cranial evaluation results are ready in your account.\n\
Dr. Amy Braase DPT, reviewed your child's results and has made a summary report and recommendations available in your account.\n\
Please make sure you send new photos and video in one month.",
  "Thanks for uploading photos/video.\n\
  Please note that, PediaMetrix is still offering analyzing of the results, at no cost, in response to COVID-19. However, we require that you schedule an appointment with Dr. Braase so she can review the results and provide you with the summary report.",
];

export const templateHtmlArray = [
  <div>
    Dear Parent, your account is approved. Please login and let us know if you
    have any questions.
  </div>,
  <div>
    Dear parent, this is a reminder for you to login to SoftSpot and upload
    photos/video; your baby's cranial evaluation results will be available to
    your physician within 3 days
  </div>,
  <>
    <div>Dear Parent,</div>
    <div>
      Your child's cranial evaluation report is ready and was sent to your
      physician. We have notified your physician to review the results; if you
      do not hear from them, please contact your physician to discuss the
      results and next steps.
    </div>
  </>,
  <>
    <div>
      Thanks for uploading pictures/video. However, we need new photos/video
      according to the instructions provided to you on the website/app.
    </div>
    <ul className="number-ul">
      <li>Please make sure the tip of the nose is appearing in the picture.</li>
      <li>
        Please make sure the hair is not covering the edges of the head in the
        picture.
      </li>
      <li>
        Please use a stocking cap, can be purchased here:
        https://www.amazon.com/dp/B06XSWD4B3/ref=cm_sw_em_r_mt_dp_U_-uOZEbK8KE274
      </li>
    </ul>
  </>,
  <div>
    Thanks for uploading photos/video. We are processing the data and within 3
    days, we will make the report available in your child's physician account.
  </div>,
  <div>
    Since your physician is not on our platform, we will assign you to our
    online physical therapy consultant. You will need to schedule a 10-minute
    appointment with her to review the numbers and next steps.
  </div>,
  <div>
    Thanks for signing up! May I ask what makes you interested in using
    SoftSpot?
  </div>,
  <div>
    New patient result is available. Please login to review the results.
  </div>,
  <>
    <div>You child's cranial evaluation results are ready in your account.</div>
    <div>
      Dr. Amy Braase DPT, reviewed your child's results and has made a summary
      report and recommendations available in your account.
    </div>
    <div>Please make sure you send new photos and video in one month.</div>
  </>,
  <>
    <div>Thanks for uploading photos/video.</div>
    <div>
      Please note that, PediaMetrix is still offering analyzing of the results,
      at no cost, in response to COVID-19. However, we require that you schedule
      an appointment with Dr. Braase so she can review the results and provide
      you with the summary report.
    </div>
  </>,
];
