import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { Login } from "../PMScreens/PMLogin/PMLogin";
import { ForgotPassword } from "../PMScreens/PMForgotPassword/PMForgotPassword";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import ParentDashboard from "../PMScreens/PMDashboard/PMParentDashboard";
import PhysicianDashboard from "../PMScreens/PMDashboard/PMPhysicianDashboard";
import Configuration from "../PMScreens/PMConfiguration/PMConfiguration";
import UserActivity from "../PMScreens/PMUserActivity/PMUserActivity";
import ApiLogs from "../PMScreens/PMApiLogs/PMApiLogs";
import SigningAttempts from "../PMScreens/PMSigningAttempts/PMSigningAttempts";
import ResetPassword from "../PMScreens/PMResetPassword/PMResetPassword";
import SupportChat from "../PMScreens/PMSupportChat/PMSupportChat";
import AdminDashboard from "../PMScreens/PMDashboard/PMAdminDashboard";
import OtherDashboard from "../PMScreens/PMDashboard/PMOtherDashboard";
import { checkPermissions } from "../PMShared/PMCommon/PMUtility";
import { useSelector } from "react-redux";
import VerifySecurityCode from "../PMScreens/PMVerifySecurityCode/PMVerifySecurityCode";
import StaffDashboard from "../PMScreens/PMDashboard/PMStaffDashboard";
import ReportDashboard from "../PMScreens/PMDashboard/PMReportDashboard";
import DebugReportDashboard from "../PMScreens/PMDashboard/PMDebugReportDashboard";
import MarketingInfoDashboard from "../PMScreens/PMDashboard/PMMarketingInfoDashboard";
import ReferalCodeDashboard from "../PMScreens/PMDashboard/PMReferalCodeDashboard";

const Routes = () => {
  const accessPermissions = useSelector((state) => {
    return state.loginReducer.accessPermissions || [];
  });
  const permissionsArray = sessionStorage.getItem("accessPermissions");
  const allowedFeatures = checkPermissions(
    permissionsArray || accessPermissions
  );

  try {
    return (
      <Router basename="/">
        <Switch>
          {/* {allowedFeatures.isManageUsers && ( */}
          <PrivateRoute
            path="/users-parents"
            component={ParentDashboard}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          <PrivateRoute
            path="/users-reports"
            component={ReportDashboard}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          <PrivateRoute
            path="/debug-reports"
            component={DebugReportDashboard}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          <PrivateRoute
            path="/marketing-info-reports"
            component={MarketingInfoDashboard}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          <PrivateRoute
            path="/referal-code-dashboard"
            component={ReferalCodeDashboard}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          {/* {allowedFeatures.isManageUsers && ( */}
          <PrivateRoute
            path="/users-physicians"
            component={PhysicianDashboard}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          {/* {allowedFeatures.isManageUsers && ( */}
          <PrivateRoute
            path="/users-others"
            component={OtherDashboard}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          <PrivateRoute
            path="/users-staff"
            component={StaffDashboard}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          {/* {allowedFeatures.isManageConfigSettings && ( */}
          <PrivateRoute
            path="/configuration"
            component={Configuration}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          {/* {allowedFeatures.isManageActivityLogs && ( */}
          <PrivateRoute
            path="/user-activity"
            component={UserActivity}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          <PrivateRoute
            path="/api-logs"
            component={ApiLogs}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          {/* {allowedFeatures.isManageUnsuccessfulLogin && ( */}
          <PrivateRoute
            path="/signing-attempts"
            component={SigningAttempts}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          {/* {allowedFeatures.isManageAdmin && ( */}
          <PrivateRoute
            path="/admin-management"
            component={AdminDashboard}
            isInitialised={true}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          {/* {allowedFeatures.isManageChat && ( */}
          <PrivateRoute
            path="/support-chat"
            component={SupportChat}
            isInitialised={true}
            isHeaderRequired={true}
            isFooterRequired={true}
            isSidebarRequired={true}
          />
          <PublicRoute
            path="/verify-security-code"
            component={VerifySecurityCode}
          />
          <PublicRoute path="/reset-password" component={ResetPassword} />
          <PublicRoute path="/forgot-password" component={ForgotPassword} />
          <PublicRoute path="/" exact component={Login} restricted={true} />
        </Switch>
      </Router>
    );
  } catch (exception) {
    return (
      <div>
        <div>Some Error Occured</div>
        <div>{exception}</div>
      </div>
    );
  }
};

export default Routes;
