import axiosInstance from "../PMCommon/PMApiHandler";
import apiEndPoints from "../PMCommon/PMApiEndPoints";
import actions from "./PMActions";

const initialState = {
  isLoginSuccess: false,
  token: sessionStorage.getItem("accessToken") || "",
  isFirstLogin: false,
  userType: "",
  isDisclaimerAccepted: false,
  isSaveBabyInfo: false,
  userStatus: "",
  statusCode: null,
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.RESET_LOGIN:
      return {
        ...state,
        isLoginSuccess: false,
        statusCode: null,
        token: "",
        isFirstLogin: false,
        userType: "",
        isDisclaimerAccepted: false,
        isSaveBabyInfo: false,
        userStatus: "",
        loginData: null,
        loginError: null,
      };
    case actions.SET_DISCLAIMER:
      return {
        ...state,
        isDisclaimerAccepted: action.data,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        isLoginSuccess: true,
        statusCode: action.data ? action.data.status : null,
        token:
          action.data && action.data.data && action.data.data.results
            ? action.data.data.results.token || ""
            : "",
        loginData: action.data && action.data.data ? action.data.data : {},
        isFirstLogin:
          action.data && action.data.data && action.data.data.results
            ? action.data.data.results.first_time_login || false
            : false,
        userType:
          action.data && action.data.data && action.data.data.results
            ? action.data.data.results.type || ""
            : "",
        userStatus:
          action.data && action.data.data && action.data.data.results
            ? action.data.data.results.status
            : "",
        userEmail:
          action.data && action.data.data && action.data.data.results
            ? action.data.data.results.email
            : "",
        accessPermissions:
          action.data && action.data.data && action.data.data.results
            ? action.data.data.results.permissions
            : "",
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        isLoginSuccess: false,
        statusCode: action.data ? action.data.status : null,
        loginError: action.data && action.data.data ? action.data.data : {},
      };
    case actions.SET_CHILD_INFO:
      return {
        ...state,
        isSaveBabyInfo: true,
        babyRegistrationData:
          action.data && action.data.data ? action.data.data.results : {},
      };
    case actions.SET_CHILD_INFO_ERROR:
      return {
        ...state,
        isSaveBabyInfo: false,
        babyRegistrationErrorMessage:
          action.data && action.data.data ? action.data.data.message : "",
        babyRegistrationError:
          action.data && action.data.data ? action.data.data.errors : {},
      };
    case actions.REGISTER_BABY:
      return {
        ...state,
        isBabyRegistrationSuccess: true,
      };
    case actions.REGISTER_BABY_ERROR:
      return {
        ...state,
        isBabyRegistrationSuccess: false,
      };
    case actions.PHYSICIAN_LIST:
      return {
        ...state,
        physicianListData:
          action.data && action.data.data ? action.data.data : {},
      };
    case actions.PHYSICIAN_LIST_ERROR:
      return {
        ...state,
        physicianListError:
          action.data && action.data.data ? action.data.data.errors : {},
      };
    default:
      return state;
  }
};

const setDisclaimerAccepted = (isDisclaimerAccepted) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_DISCLAIMER,
      data: isDisclaimerAccepted,
    });
  };
};

const resetLogin = () => {
  return (dispatch) => {
    dispatch({
      type: actions.RESET_LOGIN,
    });
  };
};

const getLoginToken = (dataObj) => {
  return (dispatch) => {
    axiosInstance
      .post(apiEndPoints.SIGNIN, dataObj)
      .then((data) => {
        dispatch({
          type: actions.LOGIN_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.LOGIN_ERROR,
          data: error,
        });
      });
  };
};

const saveBabyInfo = (dataObj) => {
  return (dispatch) => {
    axiosInstance
      .post(apiEndPoints.UPDATE_BABY, dataObj)
      .then((data) => {
        dispatch({
          type: actions.SET_CHILD_INFO,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.SET_CHILD_INFO_ERROR,
          data: error,
        });
      });
  };
};

const registerBaby = (dataObj) => {
  return (dispatch) => {
    axiosInstance
      .post(apiEndPoints.REGISTER_BABY, dataObj)
      .then((data) => {
        dispatch({
          type: actions.GET_AUTH_TOKEN,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.REGISTER_BABY_ERROR,
          data: error,
        });
      });
  };
};

const getPhysicianList = (searchTerm) => {
  return (dispatch) => {
    axiosInstance
      .get(`${apiEndPoints.PHYSICIAN_LIST}?name=${searchTerm}`)
      .then((data) => {
        dispatch({
          type: actions.PHYSICIAN_LIST,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.PHYSICIAN_LIST_ERROR,
          data: error,
        });
      });
  };
};

export const loginActions = {
  getLoginToken,
  setDisclaimerAccepted,
  saveBabyInfo,
  registerBaby,
  getPhysicianList,
  resetLogin,
};
