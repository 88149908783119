const apiEndPoints = {
  SIGNUP: "/user/sign-up",
  REGISTER_BABY: "/user/register-baby",
  SIGNIN: "/user/sign-in",
  PHYSICIAN_LIST: "/physicians",
  UPDATE_BABY: "/user/update-baby",
  VERIFY_EMAIL: "/email/verify",
  UPLOAD_PHOTO: "/report/upload",
  PREPARE_REPORT: "/report/prepare",
  FINALIZE_REPORT: "/report/finalize",
  GET_USER_DETAILS: "/user/profile",
  GET_REPORT_LIST: "/report/list",

  SIGN_OUT: "/user/sign-out",
  GET_USER_LIST: "/user/list",
  UPDATE_USER_STATUS: "/user/update-status",
  UPDATE_REPORT_STATUS: "/user/update-report-status",
  UPDATE_CONFIGURATION: "/configuration/update",
  LIST_CONFIGURATION: "/configuration",
  GET_USER_ACTIVITY: "/user/unsuccessful-login",
  FORGOT_PASSWORD: "/user/password/recover",
  RESET_PASSWORD: "/user/password/reset",
  USER_LOGS: "/user/logs",
  API_LOGS: "/user/api-logs",
  ADD_USER: "/user/add",
  CHANGE_ROLE: "/user/change-role",
  REMOVE_ADMIN: "/user/delete",
  VERIFY_SECURITY_CODE: "/user/authorize-code",
  RESEND_SECURITY_CODE: "/user/send-code",
  CHANGE_PHYSICIAN: "/user/update-child",
  EMAIL_CHAT_MESSAGES: "/chat/messages",
  GET_ADMIN_REPORTS_LIST: "/user/admin-reports",
  GET_DEBUG_REPORTS_LIST: "/user/debug-reports",
  DOWNLOAD_RESULT: "/report/_id_/download",
  DOWNLOAD_DEBUG_RESULT: "/report/_id_/debug-download",
  DOWNLOAD_PARENT_RESULT: "/report/_id_/parent-download",
  GET_MARKETING_INFO_LIST: "/user/marketing-info-reports",
  GET_REFERRAL_CODE_LIST: "/user/referral-code-reports",
  ADD_REFERRAL_CODES: "/user/add-referral-codes",


};

export default apiEndPoints;
